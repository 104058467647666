import React, { Component } from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import Register from "../src/components/pages/registration";
import Login from "../src/components/pages/login";
import Alert from "./components/common/Alert";
import Home from "../src/components/pages/home";
import Clients from "../src/components/pages/clients";
import Report from "../src/components/pages/reports";
import Projects from "./components/pages/Projects";
import Logout from "./components/common/Logout";
import Tag from "./components/pages/Tag";
import Team from "./components/pages/team";
import ProjectTeam from "./components/Projects/projectteam";
import InvitePage from "./components/pages/InvitePage";
import ConfirmationPage from "./components/pages/confirmpage";
import ComingSoon from "../src/components/common/Comingsoon";
import ProfilePage from "../src/components/pages/profilepage";
import Workspace from "../src/components/pages/workspace";
import Settings from "../src/components/pages/settings";
import ForgotPassword from "../src/components/pages/forgot_password";
import UpdatePassword from "../src/components/pages/update_password";

// Redux
import { Provider } from "react-redux";
import configStore from "./store";
import setAuthToken from "./utils/setAuthToken";
import { PersistGate } from "redux-persist/integration/react";
import Reports from "../src/components/pages/reports";
import moment from "moment-timezone";
import InviteReject from "./components/pages/InviteReject";
import ReportCalendar from "./components/Reports/calendar";



const { store, persistor } = configStore();

export default class App extends Component {
  render() {
    //moment.tz.setDefault("Europe/Berlin");
    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router>
            <>
              <Route exact path='/' component={Register} />
              <Route path='/login' component={Login} />
              <Route path='/dashboard' component={Home} />
              <Route path='/report' component={Report} />
              <Route path='/clients' component={Clients} />
              <Route path='/projects' component={Projects} />
              <Route path='/tag' component={Tag} />
              <Route path='/team' component={Team} />
              <Route path='/projectteam' component={ProjectTeam} />
              <Route path='/invite' component={InvitePage} />
              <Route path="/reject" component={InviteReject} />
              <Route path='/confirm' component={ConfirmationPage} />
              <Route path='/logout' component={Logout} />
              <Route path='/profile' component={ProfilePage} />
              <Route path='/workspace' component={Workspace} />
              <Route path='/settings' component={Settings} />
              <Route path='/reports' component={Reports} />
              <Route path="/calendar" component={ReportCalendar} />
              <Route path="/password/forgot" component={ForgotPassword} />
              <Route path="/password/update" component={UpdatePassword} />

              <Route path='/subscription' component={ComingSoon} />
              <Route path='/training' component={ComingSoon} />
              <Route path='/mobile-app' component={ComingSoon} />
              <Route path='/desktop-app' component={ComingSoon} />
              <Route path='/blog' component={ComingSoon} />
              <Route path='/jobs' component={ComingSoon} />
              <Route path='/insights' component={ComingSoon} />
              <Route path='/help' component={ComingSoon} />
              <Route path='/import' component={ComingSoon} />
              <Route path='/achievements' component={ComingSoon} />
              
            </>
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}
