import React from "react";
import { NavLink } from "react-router-dom";
import { BlackLogo } from "../common/BlackLogo";

export const Navbar = () => {
  return (
    <nav className='navbar header-navbar login-nav navbar-expand-md navbar-light'>
      <NavLink to="/" className="navbar-brand">
      <BlackLogo />
      </NavLink>
      <button
        type='button'
        className='navbar-toggler'
        data-toggle='collapse'
        data-target='#navbarCollapse'
      >
        <span className='navbar-toggler-icon'></span>
      </button>
      <div className='collapse navbar-collapse' id='navbarCollapse'>
        <div className='navbar-nav ml-auto'>
          {/*<NavLink
            to='/'
            className='nav-item nav-link active'
            activeClassName='activeRoute'
          >
            Features
          </NavLink>
          <NavLink
            to='/'
            className='nav-item nav-link active'
            activeClassName='activeRoute'
          >
            Pricing
          </NavLink>*/}
          <NavLink
            to='/login'
            className='nav-item nav-link active'
            activeClassName='activeRoute'
          >
            Login
          </NavLink>
          <NavLink
            to='/'
            className='nav-item nav-link active'
            activeClassName='activeRoute'
          >
            Sign Up
          </NavLink>
        </div>
      </div>
    </nav>
  );
};
