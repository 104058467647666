import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, ListGroup, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { loadUser } from "../../actions/auth";
import CheckToken from "../common/check_token";
import Footer from "../common/footer";
import Loader from "../common/loader";
import SideBar from "../common/sidebar";

export default function ProfilePage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((state) => state.auth);
  const [profile_name, setProfileName] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [notification_long_run, setNotification] = useState(false);
  const [send_weekly_report, setWeeklyReport] = useState(false);
  const [send_news_letter, setNewsLetter] = useState(false);
  const [openZoneDD, selectOpenZone] = useState(false);
  const [state, setState] = useState({ file: null, fileName: null });
  const [openImg, setOpenImg] = useState(false);
  const [urlImage, setUrlImage] = useState(false);
  const [country, setCountry] = useState("");
  const [openCountry, setOpenCountryList] = useState(false);
  const [countries, setCountries] = useState([]);

  const [openDateFormatDD, setDateFormatDD] = useState(false);
  const [openTimeFormatDD, setTimeFormatDD] = useState(false);
  const [openFirstDayDD, setFirstDayDD] = useState(false);
  const [openTimeDurationDD, setTimeDurationDD] = useState(false);
  const [timeZone, setTimeZone] = useState("");
  const [dateFormat, setDateFormat] = useState("");
  const [timeFormat, setTimeFormat] = useState("");
  const [firstDay, setFirstDay] = useState("");
  const [timeDurationFormat, setTimeDurationFormat] = useState("");
  const [openAccountModal, SetOpenAccountmodal] = useState(false);
  const [is_google_signin, setGoogleSignIn] = useState(false);
  const [is_apple_signin, setAppleSignIn] = useState(false);
  const [openGoogleModal, setGoogleModalOpen] = useState(false);
  const [openAppleModal, setAppleModalOpen] = useState(false);
  const [timeZonesList, setTimeZoneList] = useState([]);
  const [isUploading, setUploading] = useState(false);
  const [is_group_similar, setGroupSimilar] = useState(false);
  const [cloudUrl, setCloudUrl] = useState("");
  const format = "DD-MM-YYYY hh:mm:ss a Z";
  const dateformats = [
    "MM/DD/YYYY",
    "DD-MM-YYYY",
    "MM-DD-YYYY",
    "YYYY-MM-DD",
    "DD/MM/YYYY",
    "DD.MM.YYYY",
  ];
  const timeFormats = ["12-hour", "24-hour"];
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const durationFormat = [
    "Classic (47:06 min)",
    "Improved (0:47:06)",
    "Decimal (0.79 h)",
  ];

  useEffect(() => {
    document.addEventListener(
      "click",
      () => {
        selectOpenZone(false);
        setOpenCountryList(false);
        setTimeDurationDD(false);
        setDateFormatDD(false);
        setTimeFormatDD(false);
        setFirstDayDD(false);
      },
      true
    );
    getProfile();
    getAllTimeZones();
  }, []);

  const getAllTimeZones = () => {
    const tzOptions = moment.tz
      .names()
      .map((name) => {
        const now = Date.now();
        const zone = moment.tz.zone(name);
        return { name, offset: zone !== null ? zone.utcOffset(now) : 0 };
      })
      .sort((a, b) =>
        a.offset === b.offset
          ? a.name.localeCompare(b.name)
          : b.offset - a.offset
      )
      .map((zone) => {
        const gmtOffset = `GMT${moment.tz(zone.name).format("Z")}`;
        const abbr = moment.tz(zone.name).format("z");
        return {
          value: zone.name,
          label: `${zone.name} - (${gmtOffset})`, // ${abbr}`,
        };
      });
    setTimeZoneList(tzOptions);
  };

  const getProfile = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let workspace_id = auth.workspace_id;
      const body = JSON.stringify({ workspace_id });
      let res = await axios.post("/api/profile/settings", body, config);
      if (res.status === 200) {
        if (is_url(res.data.results[0][0].avatar)) {
          setUrlImage(true);
        }
        if (res.data.results[1][0].is_group_similar_entries === 1) {
          setGroupSimilar(true);
        }
        setTimeZone(res.data.results[1][0].timeZone);
        setDateFormat(res.data.results[1][0].dateFormat);
        setFirstDay(res.data.results[1][0].firstDayOfWeek);
        setTimeDurationFormat(res.data.results[1][0].timeDurationFormat);
        setTimeFormat(res.data.results[1][0].timeFormat);
        if (res.data.results[0][0].is_google_signin === 1) {
          setGoogleSignIn(true);
        }
        if (res.data.results[0][0].is_apple_signin === 1) {
          setAppleSignIn(true);
        }
        setCountry(res.data.results[0][0].country_name);
        setCountries(res.data.results[2]);
        setState({ fileName: res.data.results[0][0].avatar });
        setProfileName(
          res.data.results[0][0].first_name +
            " " +
            res.data.results[0][0].last_name
        );
        setEmail(res.data.results[0][0].email);
        if (res.data.results[1][0].notification_long_run === 1) {
          setNotification(true);
        } else {
          setNotification(false);
        }
        if (res.data.results[1][0].send_weekly_report === 1) {
          setWeeklyReport(true);
        } else {
          setWeeklyReport(false);
        }
        if (res.data.results[1][0].send_newsletter === 1) {
          setNewsLetter(true);
        } else {
          setNewsLetter(false);
        }
      }
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  const closeAccount = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let workspace_id = auth.workspace_id;
      const body = JSON.stringify({ email, workspace_id });
      let res = await axios.put("/api/profile/close_account", body, config);
      if (res.status === 200) {
        history.push("/logout");
      }
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  const manageAdditionalSignIn = async (id, value) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let changed_signin_option = 0;
      if (value === true) {
        changed_signin_option = 0;
      } else {
        changed_signin_option = 1;
      }
      let workspace_id = auth.workspace_id;
      const body = JSON.stringify({ id, changed_signin_option, workspace_id });
      let res = await axios.put(
        "/api/profile/change_signin_option",
        body,
        config
      );
      if (res.status === 200) {
        if (id === 1) {
          setGoogleSignIn(!is_google_signin);
          setGoogleModalOpen(false);
        } else if (id === 2) {
          setAppleSignIn(!is_apple_signin);
          setAppleModalOpen(false);
        }
        getProfile();
      }
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  const closeAccountOpen = () => {
    SetOpenAccountmodal(false);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleNameChange = (e) => {
    setProfileName(e.target.value);
  };

  const passWordChange = (e) => {
    setPassword(e.target.value);
  };
  const newPassChange = (e) => {
    setNewPass(e.target.value);
  };
  const confirmPassChange = (e) => {
    setConfirmPass(e.target.value);
  };

  const validation = () => {
    let errors = {};
    let formIsValid = true;
    if (profile_name === "") {
      formIsValid = false;
      errors["profile_name"] = "Please enter the profile name";
    }
    if (profile_name !== "") {
      let arr = profile_name.split(" ");
      if (arr.length < 2) {
        formIsValid = false;
        errors["profile_name"] = "Please enter the last name";
      }
    }
    if (email === "") {
      formIsValid = false;
      errors["email"] = "Please enter your email address.";
    }

    if (email !== "") {
      let pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        formIsValid = false;
        errors["email"] = "Please enter valid email address.";
      }
    }

    setErrors(errors);
    return formIsValid;
  };

  const changeEmail = async () => {
    try {
      if (validation()) {
        const config = {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            "Content-Type": "application/json",
          },
        };
        let workspace_id = auth.workspace_id;
        const body = JSON.stringify({ email, workspace_id });
        let res = await axios.put("/api/profile/edit_email", body, config);
        if (res.status === 200) {
          getProfile();
        }
      }
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  const validatePassword = () => {
    let errors = {};
    let formIsValid = true;
    if (password === "") {
      formIsValid = false;
      errors["password"] = "Password field shouldn't be empty.";
    }
    if (password !== "") {
      if (
        !password.match(
          /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/
        )
      ) {
        formIsValid = false;
        errors["password"] = "*Please enter secure and strong password";
      }
    }
    if (newPass === "") {
      formIsValid = false;
      errors["newPass"] = "Please enter secure and strong password.";
    }
    if (newPass !== "") {
      if (
        !newPass.match(
          /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/
        )
      ) {
        formIsValid = false;
        errors["newPass"] = "*Please enter secure and strong password";
      }
    }
    if (confirmPass !== newPass) {
      formIsValid = false;
      errors["confirmPass"] = "Password doesn't match";
    }
    if (confirmPass !== "") {
      if (
        !confirmPass.match(
          /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/
        )
      ) {
        formIsValid = false;
        errors["confirmPass"] = "*Please enter secure and strong password";
      }
    }
    setErrors(errors);
    return formIsValid;
  };
  const changePassword = async () => {
    try {
      if (validatePassword()) {
        const config = {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            "Content-Type": "application/json",
          },
        };
        let workspace_id = auth.workspace_id;
        let current_password = password;
        let new_password = newPass;
        const body = JSON.stringify({
          current_password,
          new_password,
          workspace_id,
        });
        let res = await axios.put("/api/profile/edit_password", body, config);
        if (res.status === 200) {
          getProfile();
          setShow(false);
          setPassword("");
          setNewPass("");
          setConfirmPass("");
        }
      }
    } catch (err) {
      if (err.response.data.errors.length > 0) {
        let errors = {};
        errors["password"] = err.response.data.errors[0].password;
        setErrors(errors);
      }
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  const changeName = async () => {
    try {
      if (validation()) {
        const config = {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            "Content-Type": "application/json",
          },
        };
        let workspace_id = auth.workspace_id;
        let arr = profile_name.split(" ");
        let first_name, last_name;
        if (arr.length > 2) {
          first_name = arr[0];
          last_name = profile_name.substr(profile_name.indexOf(" ") + 1);
        } else {
          first_name = arr[0];
          last_name = arr[1];
        }

        const body = JSON.stringify({ first_name, last_name, workspace_id });
        let res = await axios.put("/api/profile/edit_name", body, config);
        if (res.status === 200) {
          getProfile();
        }
      }
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  const notificationChange = async () => {
    let long_run_notification = !notification_long_run;
    setNotification(!notification_long_run);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let workspace_id = auth.workspace_id;
      const body = JSON.stringify({ long_run_notification, workspace_id });
      let res = await axios.put(
        "/api/profile/update_long_notification",
        body,
        config
      );
      if (res.status === 200) {
        getProfile();
      }
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  const weeklyReportChange = async () => {
    let weekly_report = !send_weekly_report;
    setWeeklyReport(!send_weekly_report);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let workspace_id = auth.workspace_id;
      const body = JSON.stringify({ weekly_report, workspace_id });
      let res = await axios.put(
        "/api/profile/update_weekly_report",
        body,
        config
      );
      if (res.status === 200) {
        getProfile();
      }
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  const newsLetterChange = async () => {
    let news_letter = !send_news_letter;
    setNewsLetter(!send_news_letter);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let workspace_id = auth.workspace_id;
      const body = JSON.stringify({ news_letter, workspace_id });
      let res = await axios.put(
        "/api/profile/update_send_newsletter",
        body,
        config
      );
      if (res.status === 200) {
        getProfile();
      }
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  const selectCountry = async (item) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let workspace_id = auth.workspace_id;
      let country = item.country_id;
      const body = JSON.stringify({ country, workspace_id });
      let res = await axios.put("/api/profile/edit_country", body, config);
      if (res.status === 200) {
        setCountry(item.country_name);
        setOpenCountryList(false);
        getProfile();
      }
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  const groupSimilarEntries = async (value) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let workspace_id = auth.workspace_id;
      let group_similar = 0;
      if (value === true) {
        group_similar = 0;
      } else if (value === false) {
        group_similar = 1;
      }
      const body = JSON.stringify({ group_similar, workspace_id });
      let res = await axios.put("/api/profile/group_similar", body, config);
      if (res.status === 200) {
        setGroupSimilar(!is_group_similar);
        getProfile();
      }
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  const onInputChange = (e) => {
    setState({ file: e.target.files[0] });
  };

  const handleClose = () => {
    setShow(false);
  };

  const imgClick = () => {
    setOpenImg(true);
  };

  const closeImg = () => {
    setErrors({});
    setOpenImg(false);
    getProfile();
  };

  const validateImage = () => {
    let fileSize = 1024 * 1024 * 2;
    let errors = {};
    let isValid = false;
    let isValidType = false;
    let allowedExtension = ["jpeg", "jpg", "png"];
    if (state.file !== null && state.file !== undefined && state.file !== "") {
      isValid = true;
    } else {
      errors["input_file"] = "Please select a file.";
      setErrors(errors);
      isValid = false;
      return isValid;
    }
    if (state.file && state.file.type.includes("image")) {
      isValid = true;
    } else {
      errors["input_file"] = "File is not an Image.";
      setErrors(errors);
      isValid = false;
      return isValid;
    }
    let fileExtension = state.file.type.split("/")[1];
    for (let x in allowedExtension) {
      if (fileExtension === allowedExtension[x]) {
        isValidType = true;
        break;
      }
    }
    if (isValidType === false) {
      let errors = {};
      errors["input_file"] = "File Extendion is not acceptable";
      setErrors(errors);
      isValid = false;
      return isValid;
    }
    if (state.file.size < fileSize) {
      isValid = true;
      return isValid;
    } else {
      let errors = {};
      errors["input_file"] = "Image size should be less than 2 mb";
      setErrors(errors);
      isValid = false;
      return isValid;
    }
  };
  const uploadImage = async (e) => {
    try {
      if (validateImage() === true) {
        setUploading(true);
        setErrors({});
        const formData = new FormData();
        formData.append("image", state.file);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${auth.token}`,
          },
        };

        let res = await axios.post(
          "/api/profile/picture_upload",
          formData,
          config
        );
        if (res.status === 200) {
          let ff = res.data.filename;
          if (is_url(ff)) {
            setUrlImage(true);
          }
          setState({ fileName: ff });
          setOpenImg(false);
          setUploading(false);
        } else {
          let errors = {};
          errors["input_file"] = "Image upload failed.";
          setErrors(errors);
          setTimeout(() => {
            setOpenImg(false);
            setUploading(false);
          }, 2000);
        }
      }
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      }
      let errors = {};
      errors["input_file"] = "Image upload failed.";
      setErrors(errors);
      setUploading(false);
    }
  };
  const is_url = (str) => {
    //console.log(str)
    let regexp =
      /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (regexp.test(str)) {
      return true;
    } else {
      return false;
    }
  };

  const selectTimeZone = async (item) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let workspace_id = auth.workspace_id;
      let timeZone = item.label;
      const body = JSON.stringify({ timeZone, workspace_id });
      let res = await axios.put("/api/profile/update_timeZone", body, config);
      if (res.status === 200) {
        setTimeZone(timeZone);
        await restoreReduxStore(item.label, "timezone");
        getProfile();
      }
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  const selectDateFormat = async (item) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let workspace_id = auth.workspace_id;
      let dateFormat = item;
      const body = JSON.stringify({ dateFormat, workspace_id });
      let res = await axios.put("/api/profile/update_dateFormat", body, config);
      if (res.status === 200) {
        setDateFormat(dateFormat);
        await restoreReduxStore(item, "dateformat");
        getProfile();
      }
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  const selectTimeFormat = async (item) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let workspace_id = auth.workspace_id;
      let timeFormat = item;
      const body = JSON.stringify({ timeFormat, workspace_id });
      let res = await axios.put("/api/profile/update_timeFormat", body, config);
      if (res.status === 200) {
        setTimeFormat(timeFormat);
        await restoreReduxStore(item, "timeformat");
        getProfile();
      }
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  const selectTimeDuration = async (item) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let workspace_id = auth.workspace_id;
      let timeDurationFormat = item;
      const body = JSON.stringify({ timeDurationFormat, workspace_id });
      let res = await axios.put(
        "/api/profile/update_timeDurationFormat",
        body,
        config
      );
      if (res.status === 200) {
        setTimeDurationFormat(timeDurationFormat);
        await restoreReduxStore(item, "timedurationformat");
        getProfile();
      }
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  const selectFirstDay = async (item, index) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let workspace_id = auth.workspace_id;
      let firstDayOfWeek = index;
      const body = JSON.stringify({ firstDayOfWeek, workspace_id });
      let res = await axios.put(
        "/api/profile/update_firstDayOfWeek",
        body,
        config
      );
      if (res.status === 200) {
        setFirstDay(firstDayOfWeek);
        await restoreReduxStore(index, "firstday");
        getProfile();
      }
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  const restoreReduxStore = async (data, id) => {
    let user;
    let dbDateFormat = "DD-MM-YYYY";
    let dbFirstDayOfWeek = 0;
    let dbTimeDurationFormat = "Improved (0:47:06)";
    let dbTimeFormat = "12-hour";
    let dbTimeZone = moment.tz.setDefault();
    if (auth.user_properties !== null && auth.user_properties !== undefined) {
      dbDateFormat = auth.user_properties.dateFormat;
      dbFirstDayOfWeek = auth.user_properties.firstDayOfWeek;
      dbTimeDurationFormat = auth.user_properties.timeDurationFormat;
      dbTimeFormat = auth.user_properties.timeFormat;
      dbTimeZone = auth.user_properties.timeZone;
    }
    switch (id) {
      case "timeformat":
        user = {
          token: auth.token,
          workspace_id: auth.workspace_id,
          is_workspace_admin: auth.is_workspace_admin,
          user_id: auth.user_id,
          confirmation: true,
          user_properties: {
            dateFormat: dbDateFormat,
            firstDayOfWeek: dbFirstDayOfWeek,
            timeDurationFormat: dbTimeDurationFormat,
            timeFormat: data,
            timeZone: timeZone,
          },
        };
        break;

      case "dateformat":
        user = {
          token: auth.token,
          workspace_id: auth.workspace_id,
          is_workspace_admin: auth.is_workspace_admin,
          user_id: auth.user_id,
          confirmation: true,
          user_properties: {
            dateFormat: data,
            firstDayOfWeek: dbFirstDayOfWeek,
            timeDurationFormat: dbTimeDurationFormat,
            timeFormat: dbTimeFormat,
            timeZone: timeZone,
          },
        };
        break;
      case "timezone":
        user = {
          token: auth.token,
          workspace_id: auth.workspace_id,
          is_workspace_admin: auth.is_workspace_admin,
          user_id: auth.user_id,
          confirmation: true,
          user_properties: {
            dateFormat: dbDateFormat,
            firstDayOfWeek: dbFirstDayOfWeek,
            timeDurationFormat: dbTimeDurationFormat,
            timeFormat: dbTimeFormat,
            timeZone: data,
          },
        };
        break;
      case "timedurationformat":
        user = {
          token: auth.token,
          workspace_id: auth.workspace_id,
          is_workspace_admin: auth.is_workspace_admin,
          user_id: auth.user_id,
          confirmation: true,
          user_properties: {
            dateFormat: dbDateFormat,
            firstDayOfWeek: dbFirstDayOfWeek,
            timeDurationFormat: data,
            timeFormat: dbTimeFormat,
            timeZone: timeZone,
          },
        };
        break;
      case "firstday":
        user = {
          token: auth.token,
          workspace_id: auth.workspace_id,
          is_workspace_admin: auth.is_workspace_admin,
          user_id: auth.user_id,
          confirmation: true,
          user_properties: {
            dateFormat: dbDateFormat,
            firstDayOfWeek: data,
            timeDurationFormat: dbTimeDurationFormat,
            timeFormat: dbTimeFormat,
            timeZone: timeZone,
          },
        };
        break;
      default:
        return null;
    }
    //console.log(user)
    let res = { data: user };
    await dispatch(loadUser(res));
    //window.location.reload();
  };

  return (
    <div>
      <CheckToken />
      <SideBar />
      <div class="header">
        <div class="container-fluid">
          <div class="row" style={{ flexDirection: "column" }}>
            <div class="reports-header-contain setting-header-contain">
              <h3>My Profile</h3>

              <div class="reports-right-item-cont tags-right-header-item">
                <div class="export-account-data-contain">
                  {/*<a >
									<button id="add-new-button" data-toggle="modal" data-target="#clientModal">
										<span>Export account data</span>
									</button>
								</a>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<!-- ==================================HEADER END HERE================================== -->*/}

      <div class="content-wrap" id="wrapper">
        <div class="main">
          <div class="d-flex align-items-center setting-top-workspace-set-container">
            <div class="setting-workspace-logo-box">
              <div
                //style={{width:"20%",height:"20%"}}
                onClick={() => imgClick()}
                //class="left-logo-inner-box profile-picture-select-box"
              >
                <img
                  src={
                    urlImage
                      ? state.fileName
                      : `${process.env.REACT_APP_CLOUDINARY_URL}/${state.fileName}`
                  }
                  alt=""
                  height={200}
                  width={200}
                />

                <svg
                  class="profile-picture-ellipse"
                  style={{ display: state.fileName === null ? "" : "none" }}
                  width="220"
                  height="220"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <ellipse ry="110" rx="110" cy="110" cx="110"></ellipse>
                </svg>
                <svg
                  width="80"
                  style={{ display: state.fileName === null ? "" : "none" }}
                  height="80"
                  viewBox="0 0 80 80"
                >
                  <path
                    fill="#B1B1B5"
                    fillRule="evenodd"
                    d="M48.008 12A7.99 7.99 0 0156 19.99V20h7.991C68.414 20 72 23.582 72 27.994v28.012C72 60.421 68.422 64 63.991 64H16.01C11.586 64 8 60.418 8 56.006V27.994C8 23.579 11.578 20 16.009 20H24v-.01A7.991 7.991 0 0131.992 12h16.016zM40 28c-7.732 0-14 6.268-14 14s6.268 14 14 14 14-6.268 14-14-6.268-14-14-14zm0 6a8 8 0 110 16 8 8 0 010-16zm24-10a4 4 0 100 8 4 4 0 000-8z"
                  ></path>
                </svg>
                <span
                  style={{ display: state.fileName === null ? "" : "none" }}
                >
                  Select picture
                </span>
                {/*<span style={{display:state.fileName === null ?"":"none"}}>or drag one here</span>*/}
              </div>
            </div>
            <div class="right-setting-workspace-info-box">
              <div class="workspace-inner-info">
                <form>
                  <label>YOUR NAME </label>
                  <div class="put-workspace-field-contain">
                    <input
                      type="text"
                      class="setting-workspace-name-field"
                      placeholder=""
                      onChange={handleNameChange}
                      onBlur={changeName}
                      value={profile_name}
                    />
                    <span>{errors.profile_name}</span>
                  </div>
                  <label>YOUR EMAIL</label>
                  <div class="put-workspace-field-contain">
                    <input
                      type="text"
                      class="setting-workspace-name-field"
                      placeholder=""
                      onChange={handleEmailChange}
                      onBlur={changeEmail}
                      value={email}
                    />
                    <span>{errors.email}</span>
                  </div>
                </form>
                <div class="in-profile-select-country-contain dropdown">
                  <label>COUNTRY</label>
                  <div
                    class="project-popup-choose-workspace data-toggle"
                    data-toggle="dropdown"
                    onClick={() => setOpenCountryList(true)}
                  >
                    <span class="workspace-info selectedCountryName">
                      {country}
                    </span>
                    <i class="fa fa-angle-down"></i>
                  </div>

                  <div
                    class={
                      openCountry
                        ? "dropdown-menu filter-by-button-dropdown-wrap show"
                        : "dropdown-menu filter-by-button-dropdown-wrap"
                    }
                  >
                    <div class="Teamdropdown_container">
                      <ul class="select-country-profile" id="selectCountry">
                        {countries.map((item, index) => (
                          <li
                            key={index}
                            class={
                              country === item.country_name
                                ? "selected-country"
                                : ""
                            }
                            onClick={() => selectCountry(item)}
                          >
                            {item.country_name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  {/*<!-- dropdown end here -->*/}
                </div>
              </div>
            </div>
          </div>
          {/*<!-- setting top workspace info section end here -->*/}
          <div class="project-group-work-section" style={{ margin: "0px" }}>
            <div
              class="working-group-area-contain setting-sections-wrapper"
              style={{ margin: "0px" }}
            >
              {/*<!-- team member rights section -->*/}
              <section class="setting-blocks-section-contain">
                <div class="setting-block-header-contain">
                  <div class="header-heading">
                    <span>Email preferences</span>
                  </div>
                  <div class="header-description">
                    <span>
                      Specify which types of emails you'd like to receive
                    </span>
                  </div>
                </div>
                {/*<!-- setting-header-block end here -->*/}
                <div class="setting-block-info-contain profile-info-contain-email-ref">
                  <div class="info-inner-blocks">
                    <div class="select-who-can-contain">
                      <div class="custom-checkbox-wrap">
                        <span
                          class={
                            send_news_letter
                              ? "custom-checkboxes selected"
                              : "custom-checkboxes"
                          }
                        >
                          <input
                            type="checkbox"
                            name="filter-item[]"
                            onClick={() => newsLetterChange()}
                          />
                        </span>
                        <span>Toggl can send newsletters by email</span>
                      </div>
                      <div class="custom-checkbox-wrap">
                        <span
                          class={
                            send_weekly_report
                              ? "custom-checkboxes selected"
                              : "custom-checkboxes"
                          }
                        >
                          <input
                            type="checkbox"
                            name="filter-item[]"
                            onClick={() => weeklyReportChange()}
                          />
                        </span>
                        <span>Send weekly time entry updates</span>
                      </div>
                      <div class="custom-checkbox-wrap">
                        <span
                          class={
                            notification_long_run
                              ? "custom-checkboxes selected"
                              : "custom-checkboxes"
                          }
                        >
                          <input
                            type="checkbox"
                            name="filter-item[]"
                            onClick={() => notificationChange()}
                          />
                        </span>
                        <span>
                          Email about long running (over 8 hours) time entries
                        </span>
                      </div>
                    </div>
                  </div>
                  {/*<!-- WHO CAN SEE TEAM ACTIVITY BLOCK END HERE -->*/}
                </div>
              </section>
              {/*<!--============================== team member rights section end here=================== -->*/}

              {/*<!--===========================timer page set-up section===================== -->*/}
              <section class="setting-blocks-section-contain">
                <div class="setting-block-header-contain">
                  <div class="header-heading">
                    <span>Timer page</span>
                  </div>
                  <div class="header-description">
                    <span>Define your preferences for a better workflow</span>
                  </div>
                </div>
                {/*<!-- setting-header-block end here -->*/}
                <div class="setting-block-info-contain profile-info-contain-email-ref">
                  <div class="info-inner-blocks">
                    <div class="select-who-can-contain">
                      <div class="custom-checkbox-wrap">
                        <span
                          class={
                            is_group_similar
                              ? "custom-checkboxes selected"
                              : "custom-checkboxes"
                          }
                        >
                          <input
                            type="checkbox"
                            name="filter-item[]"
                            onClick={() =>
                              groupSimilarEntries(is_group_similar)
                            }
                          />
                        </span>
                        <span>Group similar time entries</span>
                      </div>
                      {/*<div class="custom-checkbox-wrap">
				    <span class="custom-checkboxes">
						<input type="checkbox" name="filter-item[]" /> 
					</span>
					<span>Show running time in the title bar</span>
				   </div>*/}
                    </div>
                  </div>
                  {/*<!-- NEW PROJECTS BLOCK END HERE -->*/}
                </div>
              </section>
              {/*<!--==================== timer page section end here============================ -->*/}

              {/*<!--==================Time and date section===================== -->*/}
              <section class="setting-blocks-section-contain">
                <div class="setting-block-header-contain">
                  <div class="header-heading">
                    <span>Time and date</span>
                  </div>
                  <div class="header-description">
                    <span>Choose how your times are shown across Toggl</span>
                  </div>
                </div>
                {/*<!-- setting-header-block end here -->*/}

                <div class="setting-block-info-contain in-profile-time-date-contain">
                  <div class="info-inner-blocks">
                    <div class="block-title">
                      <h5>TIME ZONE</h5>
                    </div>
                    <div class="select-who-can-contain">
                      <div
                        class={
                          openZoneDD
                            ? "in-profile-select-country-contain dropdown show"
                            : "in-profile-select-country-contain dropdown"
                        }
                      >
                        <div
                          class="project-popup-choose-workspace data-toggle"
                          onClick={() => selectOpenZone(true)}
                          data-toggle="dropdown"
                          aria-expanded={openZoneDD ? "true" : "false"}
                        >
                          <span class="workspace-info selectedTimeZone">
                            {timeZone}
                          </span>
                          <i class="fa fa-angle-down"></i>
                        </div>

                        <div
                          class={
                            openZoneDD
                              ? "dropdown-menu filter-by-button-dropdown-wrap show"
                              : "dropdown-menu filter-by-button-dropdown-wrap"
                          }
                          x-placement="bottom-start"
                          style={{
                            position: "absolute",
                            transform: "translate3d(0px, 43px, 0px)",
                            top: "0px",
                            left: "0px",
                            willChange: "transform",
                          }}
                        >
                          <div class="Teamdropdown_container">
                            <ul class="select-country-profile" id="Timezone">
                              {timeZonesList.map((item, i) => (
                                <li
                                  key={i}
                                  onClick={() => selectTimeZone(item)}
                                  class={
                                    item.label === timeZone
                                      ? "selected-time-zone"
                                      : ""
                                  }
                                >
                                  {item.label}
                                </li> //
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                      {/*<!-- time zone end here -->*/}

                      <div class="block-title" style={{ marginTop: "20px" }}>
                        <h5>DURATION DISPLAY FORMAT</h5>
                      </div>

                      <div
                        class={
                          openTimeDurationDD
                            ? "in-profile-select-country-contain dropdown"
                            : "in-profile-select-country-contain dropdown show"
                        }
                      >
                        <div
                          class="project-popup-choose-workspace data-toggle"
                          onClick={() => setTimeDurationDD(true)}
                          data-toggle="dropdown"
                        >
                          <span class="workspace-info selectedDurationDisplayFormat">
                            {timeDurationFormat}
                          </span>
                          <i class="fa fa-angle-down"></i>
                        </div>
                        <div
                          class={
                            openTimeDurationDD
                              ? "dropdown-menu filter-by-button-dropdown-wrap show"
                              : "dropdown-menu filter-by-button-dropdown-wrap"
                          }
                        >
                          <div class="Teamdropdown_container">
                            <ul
                              class="select-country-profile"
                              id="durationFormat"
                            >
                              {durationFormat.map((item, i) => (
                                <li
                                  key={i}
                                  onClick={() => selectTimeDuration(item)}
                                  class={
                                    item === timeDurationFormat
                                      ? "selected-durationDisplay"
                                      : ""
                                  }
                                >
                                  {item}
                                </li> //
                              ))}
                            </ul>
                          </div>
                        </div>
                        {/*<!-- dropdown end here -->*/}
                      </div>
                      {/*<!-- time zone end here -->*/}
                    </div>
                  </div>
                  <div class="info-inner-blocks">
                    <div class="block-title">
                      <h5>DATE FORMAT</h5>
                    </div>
                    <div class="select-who-can-contain">
                      <div
                        class={
                          openDateFormatDD
                            ? "in-profile-select-country-contain dropdown show"
                            : "in-profile-select-country-contain dropdown"
                        }
                      >
                        <div
                          class="project-popup-choose-workspace data-toggle"
                          onClick={() => setDateFormatDD(true)}
                          data-toggle="dropdown"
                        >
                          <span class="workspace-info selectedDateFormat">
                            {dateFormat}
                          </span>
                          <i class="fa fa-angle-down"></i>
                        </div>

                        <div
                          class={
                            openDateFormatDD
                              ? "dropdown-menu filter-by-button-dropdown-wrap show"
                              : "dropdown-menu filter-by-button-dropdown-wrap"
                          }
                        >
                          <div class="Teamdropdown_container">
                            <ul class="select-country-profile" id="dateFormat">
                              {dateformats.map((item, i) => (
                                <li
                                  key={i}
                                  onClick={() => selectDateFormat(item)}
                                  class={
                                    item === dateFormat
                                      ? "selected-date-format"
                                      : ""
                                  }
                                >
                                  {item}
                                </li> //
                              ))}
                            </ul>
                          </div>
                        </div>
                        {/*<!-- dropdown end here -->*/}
                      </div>
                      {/*<!-- time zone end here -->*/}

                      <div class="block-title" style={{ marginTop: "20px" }}>
                        <h5>FIRST DAY OF THE WEEK</h5>
                      </div>

                      <div
                        class={
                          openFirstDayDD
                            ? "in-profile-select-country-contain dropdown show"
                            : "in-profile-select-country-contain dropdown"
                        }
                      >
                        <div
                          class="project-popup-choose-workspace data-toggle"
                          onClick={() => setFirstDayDD(true)}
                          data-toggle="dropdown"
                        >
                          <span class="workspace-info selectedWeekDay">
                            {days[firstDay]}
                          </span>
                          <i class="fa fa-angle-down"></i>
                        </div>

                        <div
                          class={
                            openFirstDayDD
                              ? "dropdown-menu filter-by-button-dropdown-wrap show"
                              : "dropdown-menu filter-by-button-dropdown-wrap"
                          }
                        >
                          <div class="Teamdropdown_container">
                            <ul class="select-country-profile" id="weekDay">
                              {days.map((item, i) => (
                                <li
                                  key={i}
                                  onClick={() => selectFirstDay(item, i)}
                                  class={
                                    item === firstDay ? "selected-firstDay" : ""
                                  }
                                >
                                  {item}
                                </li> //
                              ))}
                            </ul>
                          </div>
                        </div>
                        {/*<!-- dropdown end here -->*/}
                      </div>
                      {/*<!-- time zone end here -->*/}
                    </div>
                  </div>
                  <div class="info-inner-blocks">
                    <div class="block-title">
                      <h5>TIME FORMAT</h5>
                    </div>
                    <div class="select-who-can-contain">
                      <div
                        class={
                          openTimeFormatDD
                            ? "in-profile-select-country-contain dropdown show"
                            : "in-profile-select-country-contain dropdown"
                        }
                      >
                        <div
                          class="project-popup-choose-workspace data-toggle"
                          onClick={() => setTimeFormatDD(true)}
                          data-toggle="dropdown"
                        >
                          <span class="workspace-info selectedTimeFormat">
                            {timeFormat}
                          </span>
                          <i class="fa fa-angle-down"></i>
                        </div>

                        <div
                          class={
                            openTimeFormatDD
                              ? "dropdown-menu filter-by-button-dropdown-wrap show"
                              : "dropdown-menu filter-by-button-dropdown-wrap"
                          }
                        >
                          <div class="Teamdropdown_container">
                            <ul class="select-country-profile" id="timeFormat">
                              {timeFormats.map((item, i) => (
                                <li
                                  key={i}
                                  onClick={() => selectTimeFormat(item)}
                                  class={
                                    item === timeFormat
                                      ? "selected-time-format"
                                      : ""
                                  }
                                >
                                  {item}
                                </li> //
                              ))}
                            </ul>
                          </div>
                        </div>
                        {/*<!-- dropdown end here -->*/}
                      </div>
                      {/*<!-- time zone end here -->*/}
                    </div>
                  </div>
                </div>
              </section>
              {/*<!--========== Time and date section end here========================= -->*/}

              {/*<!--=======Additional sign in options section start here============== -->*/}
              <section class="setting-blocks-section-contain">
                <div
                  class="setting-block-header-contain"
                  style={{ borderBottom: "0px" }}
                >
                  <div class="header-heading">
                    <span>Additional sign in options</span>
                  </div>
                  <div class="header-description">
                    <span>Manage additional sign in options</span>
                  </div>
                </div>
                {/*<!-- setting-header-block end here -->*/}
                <div class="setting-block-info-contain profile-info-contain-email-ref">
                  <div class="info-inner-blocks">
                    <div class="sign-in-with-wrapper">
                      {/*<!-- sign-in-with-google start form here -->*/}
                      <div
                        class="in-profile-sign-in-google-contain" //active-signIn"
                        style={{
                          borderColor:
                            is_google_signin === true ? "rgb(75, 200, 0)" : "",
                        }}
                      >
                        <svg width="30" height="30" viewBox="-2.5 -2.5 24 24">
                          <path fill="none" d="M-1-1h21v21H-1z"></path>
                          <g>
                            <g fill="none" fillRule="evenodd">
                              <path
                                d="M18.14 9.705c0-.639-.057-1.252-.164-1.841H9.5v3.481h4.844a4.14 4.14 0 01-1.796 2.716v2.259h2.908c1.702-1.567 2.684-3.875 2.684-6.615z"
                                fill="#4285F4"
                              ></path>
                              <path
                                d="M9.5 18.5c2.43 0 4.467-.806 5.956-2.18l-2.908-2.259c-.806.54-1.837.86-3.048.86-2.344 0-4.328-1.584-5.036-3.711H1.457v2.332A8.997 8.997 0 009.5 18.5z"
                                fill="#34A853"
                              ></path>
                              <path
                                d="M4.464 11.21a5.41 5.41 0 01-.282-1.71c0-.593.102-1.17.282-1.71V5.458H1.457A8.997 8.997 0 00.5 9.5c0 1.452.348 2.827.957 4.042l3.007-2.332z"
                                fill="#FBBC05"
                              ></path>
                              <path
                                d="M9.5 4.08c1.321 0 2.508.454 3.44 1.345l2.582-2.58C13.963 1.391 11.926.5 9.5.5a8.997 8.997 0 00-8.043 4.958L4.464 7.79C5.172 5.663 7.156 4.08 9.5 4.08z"
                                fill="#EA4335"
                              ></path>
                              <path d="M.5.5h18v18H.5V.5z"></path>
                            </g>
                          </g>
                        </svg>
                        <div class="sign-in-content">
                          <span>Google sign in</span>
                        </div>
                        <div
                          class=" More-option-btn-wrap dropdown"
                          style={{
                            display: is_google_signin === true ? "block" : "",
                          }}
                        >
                          <div
                            class="more-option-inner dropdown-toggle"
                            data-toggle="dropdown"
                            title="More options"
                            aria-expanded="false"
                          >
                            <svg
                              width="4"
                              height="16"
                              viewBox="0 0 4 16"
                              onClick={() => setGoogleModalOpen(true)}
                            >
                              <path
                                fill="#cecece"
                                fillRule="evenodd"
                                d="M0 2a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm0 12a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm0-6a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"
                              ></path>
                            </svg>
                          </div>
                          <div
                            class={
                              openGoogleModal
                                ? "dropdown-menu action_btns intimer-dot-option-box show"
                                : "dropdown-menu action_btns intimer-dot-option-box"
                            }
                            style={{
                              transform:
                                "translate3d(-129px, 19px, 0px) !important",
                            }}
                          >
                            <div
                              class="delete option-items"
                              style={{
                                color: "rgb(226, 5, 5)",
                                fontWeight: "500",
                              }}
                            >
                              <span
                                onClick={() =>
                                  manageAdditionalSignIn(1, is_google_signin)
                                }
                              >
                                Disable google sign in
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="sign-in-enable-wrap">
                          <span class="mark-sign-in">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="10"
                              viewBox="2 2 10 10"
                            >
                              <g fill="none" fillRule="evenodd">
                                <path
                                  stroke="#4bc800"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M3.5 7.5L6 10l4-6"
                                ></path>
                              </g>
                            </svg>
                          </span>
                          <span class="signIn-mark-status">
                            {is_google_signin === true ? "Enabled" : "Enable"}
                          </span>
                        </div>
                      </div>
                      {/*<!-- sign-in-with-google end here here -->*/}

                      {/*<!-- sign-in-with-apple start form here -->*/}
                      <div
                        class="in-profile-sign-in-google-contain"
                        style={{
                          borderColor:
                            is_apple_signin === true ? "rgb(75, 200, 0)" : "",
                        }}
                      >
                        <svg width="30" height="30" viewBox="16 14 24 24">
                          <g fill="none" fillRule="evenodd">
                            <path
                              d="M28.223 20.385c.832 0 1.875-.58 2.496-1.353.562-.7.972-1.68.972-2.658 0-.132-.011-.265-.035-.374-.926.036-2.039.64-2.707 1.45-.527.615-1.008 1.582-1.008 2.572 0 .145.024.29.036.338.058.013.152.025.246.025zM25.293 35c1.137 0 1.64-.785 3.059-.785 1.441 0 1.757.76 3.023.76 1.242 0 2.074-1.183 2.86-2.342.878-1.33 1.242-2.634 1.265-2.694-.082-.024-2.46-1.027-2.46-3.841 0-2.44 1.874-3.54 1.98-3.624-1.243-1.836-3.13-1.884-3.645-1.884-1.395 0-2.531.87-3.246.87-.774 0-1.793-.822-3-.822-2.297 0-4.629 1.957-4.629 5.653 0 2.295.867 4.723 1.934 6.293.914 1.329 1.71 2.416 2.859 2.416z"
                              fill="#000000"
                              fillRule="nonzero"
                            ></path>
                          </g>
                        </svg>
                        <div class="sign-in-content">
                          <span>Apple sign in</span>
                        </div>
                        <div
                          class=" More-option-btn-wrap dropdown"
                          style={{
                            display: is_apple_signin === true ? "block" : "",
                          }}
                        >
                          <div
                            class="more-option-inner dropdown-toggle"
                            data-toggle="dropdown"
                            title="More options"
                          >
                            <svg
                              width="4"
                              height="16"
                              viewBox="0 0 4 16"
                              onClick={() => setAppleModalOpen(true)}
                            >
                              <path
                                fill="#cecece"
                                fillRule="evenodd"
                                d="M0 2a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm0 12a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm0-6a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"
                              ></path>
                            </svg>
                          </div>
                          <div
                            class={
                              openAppleModal === true
                                ? "dropdown-menu action_btns intimer-dot-option-box show"
                                : "dropdown-menu action_btns intimer-dot-option-box"
                            }
                            style={{
                              transform:
                                "translate3d(-129px, 19px, 0px) !important",
                            }}
                          >
                            <div
                              class="delete option-items"
                              style={{
                                color: "rgb(226, 5, 5)",
                                fontWeight: "500",
                              }}
                            >
                              <span
                                onClick={() =>
                                  manageAdditionalSignIn(2, is_apple_signin)
                                }
                              >
                                Disable apple sign in
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="sign-in-enable-wrap">
                          <span class="mark-sign-in">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="10"
                              viewBox="2 2 10 10"
                            >
                              <g fill="none" fillRule="evenodd">
                                <path
                                  stroke="#4bc800"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M3.5 7.5L6 10l4-6"
                                ></path>
                              </g>
                            </svg>
                          </span>
                          <span class="signIn-mark-status">
                            {is_apple_signin === true ? "Enabled" : "Enable"}
                          </span>
                        </div>
                      </div>
                      {/*<!-- sign-in-with-apple end here here -->*/}
                    </div>
                  </div>
                </div>
              </section>
              {/*<!-- ======Additional sign in options section end here================ -->*/}

              {/*<section class="beta-tester-section">
					<div class="row">
						<div class="col-sm-6">
							<div class="yellow-eye-pipe-col-box">
								<img src={pipeeyeball}/>
							</div>
						</div>           
						<div class="col-sm-6">
							<div class="beta-tester-box-wrap enable_beta">
								<div class="beta-box-heading">
									Become a Beta Tester
								</div>
								<div class="beta-info-box">
									<p class="beta-test-description">
										You will get early versions of our new releases before we roll<br/> them out to everyone else.
									</p>
								</div>
								<div class="beta-tester-action-btns-contain">
									<div class="disable-feautes-btn">
										<button class="feaute-btn" id="enableBeta-features" type="button">Enable beta features</button>
									</div>
									<div class="learn-more-lin">
										<a href='javascript:void(0)'>Learn more</a>
									</div>
								</div>
							</div>
							<div class="beta-tester-box-wrap disable_beta" style={{display: "none"}}>
								<div class="beta-box-heading"> You’re a Beta Tester </div>
								<div class="beta-info-box">
									<p class="beta-test-description">
										You get early versions of our new releases before anyone  else. New features are indicated with “Beta feature” symbol.
									</p>
								</div>
								<div class="beta-tester-action-btns-contain">
									<div class="disable-feautes-btn">
										<button class="feaute-btn" id="disableBeta-features" type="button">Disable beta features</button>
									</div>
									<div class="learn-more-lin">
										<a href='javascript:void(0)'>Learn more</a>
									</div>
								</div>
							</div>
						</div>           
					</div>
												</section>*/}
              {/*<!--==================beta tester section end here===================== -->*/}

              {/*<!--==================api token section start here===================== -->*/}
              {/*<section class="setting-blocks-section-contain">
					<div class="setting-block-header-contain">
						<div class="header-heading">
							<span>API Token</span>
						</div>
						<div class="header-description">
							<span>This is a unique identifier used to authenticate you to Toggl. Keep your Token private to avoid sharing sensitive information.
							</span>

						</div>
						<div class="reset-link" data-toggle="modal" data-target="#resetApiToken" style={{marginLeft:"auto"}}>
							<a href='javascript:void(0)'> RESET</a>
						</div>
					</div>{/*<!-- setting-header-block end here -->*
					<div class="setting-block-info-contain profile-info-contain-email-ref">
						<div class="profile-api-code-field">
							<input type="text" readOnly="readonly" value="154908b64733b6acf8da7a0ae64da288"/>
						</div>
					</div>
				</section>*/}
              {/*<!--===================api token section end here=================== -->*/}

              {/*<!--===================acount action start here===================== -->*/}
              <section class="setting-blocks-section-contain">
                <div class="setting-block-header-contain">
                  <div class="header-heading">
                    <span>Account actions</span>
                  </div>
                </div>
                {/*<!-- setting-header-block end here -->*/}

                <div class="setting-block-info-contain profile-info-contain-email-ref">
                  <div class="account-action-btns-wrap">
                    <button
                      class="btns change-password"
                      onClick={() => setShow(true)}
                      data-toggle="modal"
                      data-target="#showPopupPassChange"
                    >
                      Change password
                    </button>
                    <button
                      class="btns close-account"
                      onClick={() => SetOpenAccountmodal(true)}
                    >
                      Close account
                    </button>
                  </div>
                </div>
              </section>
              {/*<!--===============account action section end here================== -->*/}

              {/*<!-- need help making toggle in end bottom -->*/}
              {/*<!-- <div class="setting-bottom-hr-section">
					<div class="horizontal-line"></div>
					<span class="need-help-bottom-content">
						<span>Need help making Toggl fit your team's needs? 
							<a href="#" target="_blank"><span>Get a free demo</span></a>
						</span>
					</span>
				</div> -->*/}
            </div>
          </div>
        </div>{" "}
        {/*<!-- main div end here -->*/}
      </div>
      {/*<!-- content wrapper end here -->*/}
      <Footer />

      {/*<!-- RESET API TOKEN POPUP start from here-->*/}
      <div id="resetApiToken" class="modal fade" role="dialog">
        <div class="modal-dialog">
          {/*<!-- Modal content-->*/}
          <div class="modal-content add-New-tag-popup-box-container">
            <div class="close-popup-btn">
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div class="popup-title">
              <h3>Reset API Token</h3>
            </div>
            <div class="popup-apireset-descr">
              <p>
                Resetting your API token may break third party integrations or
                applications you are using.
              </p>
              <p>
                You will also be logged out from all devices and may lose any
                unsynced data.
              </p>
            </div>

            <div
              class="create-tag-name-btn popup-full-btn"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                style={{
                  flex: "2",
                  backgroundColor: "#fff",
                  border: "1px solid #4bc800",
                  color: "#000",
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                class="popup-button"
                style={{ flex: "2", margin: "0px 0px 0px 10px" }}
              >
                <span>Reset</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/*<!-- RESET API TOKEN POPUP END  here-->*/}

      {/*<!-- CHANGE PASSWORD POPUP start from here-->*/}

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="add-New-tag-popup-box-container"
        bsPrefix=""
      >
        <div class="popup-title">
          <h3>Change Password</h3>
        </div>
        <div class="change-password-form-wrap">
          <form>
            <div class="current-password-field-contain change-pass-pop">
              <input
                type="password"
                name="current"
                id="current"
                onKeyUp={validatePassword}
                onChange={passWordChange}
                placeholder="Current password"
              />
              <span class="error-msg-in-ch-ps">{errors.password}</span>
            </div>
            <div class="current-password-field-contain change-pass-pop">
              <input
                type="password"
                name="new_password"
                id="new_password"
                onChange={newPassChange}
                placeholder="New password"
                onKeyUp={validatePassword}
              />
              <span class="error-msg-in-ch-ps">{errors.newPass}</span>
            </div>
            <div class="current-password-field-contain change-pass-pop">
              <input
                type="password"
                name="confirm_password"
                id="confirm_password"
                onChange={confirmPassChange}
                placeholder="Repeat new password"
                onKeyUp={validatePassword}
              />
              <span class="error-msg-in-ch-ps">{errors.confirmPass}</span>
            </div>
            <div
              class="create-tag-name-btn popup-full-btn change-password-btn-submit"
              onClick={() => changePassword()}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                class="popup-button"
                type="button"
                style={{
                  flex: "1",
                  margin: "0px 0px 0px 10px",
                  backgroundColor: "rgb(76, 198, 66)",
                  pointerEvents: "none",
                }}
              >
                <span>Save</span>
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        show={openImg}
        onHide={closeImg}
        dialogClassName="add-New-tag-popup-box-container modal-90w"
        bsPrefix=""
      >
        <Modal.Header>Select Your Profile Picture</Modal.Header>
        <Modal.Body>
          <h1>File Upload</h1>
          <div style={{ display: isUploading ? "" : "none" }}>
            <Loader />
          </div>
          <span class="error-msg-in-ch-ps" style={{ color: "red" }}>
            {errors.input_file}
          </span>
          <Modal.Footer>
            <table style={{ boarder: "none" }}>
              <tr>
                <td>
                  <input
                    type="file"
                    name="myImage"
                    class="selelct-picture"
                    onChange={onInputChange}
                  />
                </td>
                <td>
                  <Button
                    variant="success"
                    size="sm"
                    onClick={() => uploadImage()}
                  >
                    Upload
                  </Button>
                </td>
              </tr>
            </table>
          </Modal.Footer>
        </Modal.Body>
      </Modal>

      <Modal
        show={openAccountModal}
        onHide={closeAccountOpen}
        dialogClassName="add-New-tag-popup-box-container modal-90w"
        bsPrefix=""
      >
        <Modal.Header>
          <h5>On Closing Timeily account</h5>
        </Modal.Header>
        <Modal.Body>
          <div>
            <ListGroup variant="flush">
              <ListGroup.Item>
                You won't be able to reactivate your account.
              </ListGroup.Item>
              <ListGroup.Item>
                Once you closed all data will be deleted after 90 days.
              </ListGroup.Item>
            </ListGroup>
          </div>
          <Modal.Footer>
            <Button
              variant="secondary"
              size="sm"
              onClick={() => closeAccountOpen()}
            >
              Cancel
            </Button>
            <Button variant="success" size="sm" onClick={() => closeAccount()}>
              Close Account
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </div>
  );
}
