import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Navbar } from "../common/Navbar";
import axios from "axios";
import PassWordCheck from "../common/PassWordCheck";
import { setAlert } from "../../actions/alert";

export default function UpdatePassword () {

    const history = useHistory();
    const [email, setemail] = useState("");
    const [user_id, setUserId] = useState(0);
    const [password, setPassword] = useState("");
    const [confirm_password, setconfirmation] = useState("");
    const [errors, setErrors] = useState("");
    const [code, setCode] = useState("");
    const [passwordCheck, setpasswordCheck] = useState({
      length: false,
      number: false,
      letter: false,
      special: false,
    });
    const [hidden1, setHidden1] = useState(false);
    const [hidden2, setHidden2] = useState(false);
    useEffect(()=> {
      getUsercode();
    },[])

    const getUsercode = async () => {
      let url_string = window.location.href;
      let url = new URL(url_string);
      let c = url.searchParams.get("user");
      if (c) {
        let buff = Buffer.from(c, "base64");
        let text = buff.toString("ascii");
        let data = text.split(" ");
        let email = data[0];
        let user_id = data[1];
        setemail(email);
        setUserId(user_id);
        setCode(c);
      }
    }

    const validatePassword = () => {
        let password1 = password || "";
        let password2 = confirm_password || "";
        let errors = {};
        let formIsValid = true;
        if (password1 === "") {
          formIsValid = false;
          errors["password"] = "Please enter secure and strong password.";
        }
        if (password1 !== "") {
          if (
            !password1.match(
              /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/
            )
          ) {
            formIsValid = false;
            errors["password"] = "*Please enter secure and strong password";
          }
        }
        if(password1 !== password2) {
          formIsValid = false;
          errors["password"] = "*Passwords doesn't match";
        }
        setErrors(errors)
        return formIsValid;
    }

    const checkPassword = () => {
      let password1 = password;
      let letter = false,
        length = false,
        number = false,
        special = false;
      if (password1.length > 8) {
        length = true;
      }
      if (/[a-z]/.test(password1) === true && /[A-Z]/.test(password1) === true) {
        letter = true;
      }
      if (/[0-9]/.test(password1)) {
        number = true;
      }
      if (/[!@#$]/.test(password1)) {
        special = true;
      }
      setpasswordCheck({
        length: length,
        letter: letter,
        special: special,
        number: number,
      })
    };

    const toggleShow1 = () => {
      setHidden1(!hidden1);
    };
    const toggleShow2 = () => {
      setHidden2(!hidden2);
    };

    const updatePassword = async () => {
        try {
            if(validatePassword()) {
                const config = {
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                    },
                };
                const body = JSON.stringify({
                    password : password,  
                    email,
                    user_id,
                    code 
                });
                let res = await axios.post("/api/profile/update_password",body,config);
                if(res.status === 200) {
                    if(res.data.user === true) {
                      await setAlert(res.data.msg,"success");
                      history.push("/login")
                    }
                }
            }
        }
        catch(error) {
            if (error.response) {
                let errors = {};
                console.log(error.response)
                if(error.response.status !== 500 && error.response.status !== 404) {
                    errors["password"] = error.response.data.errors[0].msg;
                    setErrors(errors);
                }
            }
        }
    } 

    const onPasswordChange = (e) => {
        setPassword(e.target.value);
    }

    const onConfirmationChange = (e) => {
        setconfirmation(e.target.value);
    }

    return (
        <div>
            <div>
        <div>
        <Navbar />
          <main>
            {/*<!-- top hero section -->*/}
            <div className='main-wrapper-container'>
              <section className='hero'>
                <div className='page-header-content-wrap animate__animated animate__fadeInUp'>
                  <h1>Reset Password</h1>
                  <h2>Enter your new password and confirm it. We will redirect you to Login Page.</h2>
                </div>
              </section>
              <section className='form-login-wrap animate__animated animate__fadeInUp'>
                <div className='container'>
                  <div className='d-flex justify-content-center'>
                    <div className='col-md-6 '>
                      <form
                        action='/'
                        className='form-login'
                        id='login-form'
                        method='post'
                        name='loginform'
                      >

                        <div
                          className='form-field form-input-field'
                          data-valid-entry={
                            errors.password ? "false" : "true"
                          }
                        >
                          <label htmlFor='login-password'>Password</label>
                          <input
                            type={hidden1 ? "password" : "text"}
                            name='password'
                            id='login-password'
                            onChange={onPasswordChange}
                            value={password}
                            required=''
                            autoComplete='current-password'
                            className={
                              errors.password ? "error" : ""
                            }
                            onKeyUp={() => checkPassword()}
                          />
                          <span className='form-icon' onClick={toggleShow1}>
                            {hidden1 ? (
                              <svg
                                className='form-icon--eyeout'
                                style={{ enableBackground: "new 0 0 56 41" }}
                                version='1.1'
                                viewBox='0 0 56 41'
                                x='0px'
                                xmlns='http://www.w3.org/2000/svg'
                                y='0px'
                              >
                                <path
                                  d='M55.3,21l-0.6,1c-1.3,2.3-2.8,4.4-4.2,6.3l-3.3-2.2c1.2-1.5,2.4-3.2,3.5-5.1
                                      C41.8,6.3,29.7,1.7,18.8,7.3l-3.7-2.5c13.4-8.2,29-3.2,39.6,15.2L55.3,21z'
                                ></path>
                                <path
                                  d='M39.5,21l-4.7-3.1c-1.1-2.5-3.7-4.3-6.6-4.4l-4.7-3.1c1.4-0.6,2.9-0.9,4.5-0.9
                                      C34.3,9.5,39.5,14.7,39.5,21z'
                                ></path>
                                <path
                                  d='M55.5,37.1c-0.1-0.5-0.4-1-0.9-1.3l-6.7-4.5l-3.4-2.2l-5.8-3.9L35.2,23l-11.8-7.9l-3.5-2.3
                                      l-4.8-3.2l-3.5-2.3l-7.5-5C3.2,1.7,1.9,2,1.3,2.9S1,5.1,1.9,5.7l6.7,4.5c-0.9,0.9-1.8,2-2.7,3c-1.6,2-3.2,4.3-4.7,6.8l-0.6,1
                                      l0.6,1C12,40.7,28,45.6,41.5,36.8c1.1-0.7,2.3-1.6,3.4-2.5l7.5,5c0.5,0.3,1,0.4,1.5,0.3c0.5-0.1,1-0.4,1.3-0.9
                                      S55.6,37.6,55.5,37.1z M33.2,26.4c-1.1,1.1-2.6,1.8-4.2,2c-0.3,0-0.6,0.1-1,0.1c-3.5,0-6.3-2.3-7.2-5.5c-0.2-0.6-0.3-1.3-0.3-2
                                      c0-0.9,0.2-1.8,0.5-2.7L33.2,26.4z M37.8,34.3C26.8,40.5,14.4,36,5.3,21c1.3-2.1,2.6-4,4-5.6c0.9-1.1,1.8-2.1,2.7-3l5.6,3.7
                                      c-0.6,1.3-1,2.6-1.1,4.1c0,0.3,0,0.5,0,0.8c0,6.3,5.2,11.5,11.5,11.5c1.9,0,3.7-0.5,5.2-1.2c1.3-0.6,2.4-1.5,3.3-2.6l4.9,3.2
                                      C40.3,32.8,39.1,33.7,37.8,34.3z'
                                ></path>
                              </svg>
                            ) : (
                              <svg
                                className='form-icon--eye'
                                height='40'
                                viewBox='0 0 54 40'
                                width='54'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M125,100c-13.8-24-36.2-24-50,0h0c13.8,24,36.2,24,50,0h0Z'
                                  fill='none'
                                  stroke-linecap='round'
                                  stroke-linejoin='round'
                                  stroke-width='4'
                                  transform='translate(-73 -80)'
                                ></path>
                                <circle
                                  cx='27'
                                  cy='20'
                                  fill='none'
                                  r='9.5'
                                  stroke-linecap='round'
                                  stroke-linejoin='round'
                                  stroke-width='4'
                                ></circle>
                              </svg>
                            )}
                          </span>
                          <label
                            id='login-email-error'
                            className='error'
                            htmlFor='login-email'
                          >
                            {errors.password}
                          </label>
                        </div>
                        <div
                          className='form-field form-input-field'
                          data-valid-entry={
                            errors.password ? "false" : "true"
                          }
                        >
                          <label htmlFor='login-password'>Confirm Password</label>
                          <input
                            type={hidden2 ? "password" : "text"}
                            name='password'
                            id='login-password'
                            onChange={onConfirmationChange}
                            value={confirm_password}
                            required=''
                            autoComplete='current-password'
                            className={
                              errors.password ? "error" : ""
                            }
                            //onKeyUp={() => checkPassword()}
                          />
                          <span className='form-icon' onClick={toggleShow2}>
                            {hidden2 ? (
                              <svg
                                className='form-icon--eyeout'
                                style={{ enableBackground: "new 0 0 56 41" }}
                                version='1.1'
                                viewBox='0 0 56 41'
                                x='0px'
                                xmlns='http://www.w3.org/2000/svg'
                                y='0px'
                              >
                                <path
                                  d='M55.3,21l-0.6,1c-1.3,2.3-2.8,4.4-4.2,6.3l-3.3-2.2c1.2-1.5,2.4-3.2,3.5-5.1
                                      C41.8,6.3,29.7,1.7,18.8,7.3l-3.7-2.5c13.4-8.2,29-3.2,39.6,15.2L55.3,21z'
                                ></path>
                                <path
                                  d='M39.5,21l-4.7-3.1c-1.1-2.5-3.7-4.3-6.6-4.4l-4.7-3.1c1.4-0.6,2.9-0.9,4.5-0.9
                                      C34.3,9.5,39.5,14.7,39.5,21z'
                                ></path>
                                <path
                                  d='M55.5,37.1c-0.1-0.5-0.4-1-0.9-1.3l-6.7-4.5l-3.4-2.2l-5.8-3.9L35.2,23l-11.8-7.9l-3.5-2.3
                                      l-4.8-3.2l-3.5-2.3l-7.5-5C3.2,1.7,1.9,2,1.3,2.9S1,5.1,1.9,5.7l6.7,4.5c-0.9,0.9-1.8,2-2.7,3c-1.6,2-3.2,4.3-4.7,6.8l-0.6,1
                                      l0.6,1C12,40.7,28,45.6,41.5,36.8c1.1-0.7,2.3-1.6,3.4-2.5l7.5,5c0.5,0.3,1,0.4,1.5,0.3c0.5-0.1,1-0.4,1.3-0.9
                                      S55.6,37.6,55.5,37.1z M33.2,26.4c-1.1,1.1-2.6,1.8-4.2,2c-0.3,0-0.6,0.1-1,0.1c-3.5,0-6.3-2.3-7.2-5.5c-0.2-0.6-0.3-1.3-0.3-2
                                      c0-0.9,0.2-1.8,0.5-2.7L33.2,26.4z M37.8,34.3C26.8,40.5,14.4,36,5.3,21c1.3-2.1,2.6-4,4-5.6c0.9-1.1,1.8-2.1,2.7-3l5.6,3.7
                                      c-0.6,1.3-1,2.6-1.1,4.1c0,0.3,0,0.5,0,0.8c0,6.3,5.2,11.5,11.5,11.5c1.9,0,3.7-0.5,5.2-1.2c1.3-0.6,2.4-1.5,3.3-2.6l4.9,3.2
                                      C40.3,32.8,39.1,33.7,37.8,34.3z'
                                ></path>
                              </svg>
                            ) : (
                              <svg
                                className='form-icon--eye'
                                height='40'
                                viewBox='0 0 54 40'
                                width='54'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M125,100c-13.8-24-36.2-24-50,0h0c13.8,24,36.2,24,50,0h0Z'
                                  fill='none'
                                  stroke-linecap='round'
                                  stroke-linejoin='round'
                                  stroke-width='4'
                                  transform='translate(-73 -80)'
                                ></path>
                                <circle
                                  cx='27'
                                  cy='20'
                                  fill='none'
                                  r='9.5'
                                  stroke-linecap='round'
                                  stroke-linejoin='round'
                                  stroke-width='4'
                                ></circle>
                              </svg>
                            )}
                          </span>
                          <label
                            id='login-email-error'
                            className='error'
                            htmlFor='login-email'
                          >
                          </label>
                          <PassWordCheck
                            length={passwordCheck.length}
                            number={passwordCheck.number}
                            letter={passwordCheck.letter}
                            special={passwordCheck.special}
                          />
                        </div>
                        <div
                          className='form-field form-input-field'
                          //data-valid-entry={
                            //this.state.errors.password ? "false" : "true"
                          //}
                        >
                        </div>
                        <div className='form-field form-btn-submit'>
                          <div>
                            <button
                              className='button'
                              id='login-button'
                              type='button'
                              onClick={updatePassword}
                            >
                              <span>Reset Password</span>
                            </button>
                          </div>
                        </div>
                        <div className='form-field--divider'>
                          <span>or</span>
                        </div>
                        <div className='form-field form-btn-submit'>
                          <div>
                          <NavLink to="/">                            
                          <button
                              className='button'
                              id='login-button'
                              type='button'
                              //onClick={this.loginUser}
                            >
                              <span>Sign Up For Free</span>
                            </button></NavLink> 
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            {/*<!-- ==============SIGN UP htmlFor FREE SECTION START FORM HERE============== -->*/}
            {/*<section className='signup-free__link animate__animated animate__fadeInUp'>
              <div className='container'>
                <div className='link-banner link-banner--medium'>
                  <div className='container'>
                    <p>Don't have an account?</p>
                    <a href='/' className='button'>
                      <span>Sign Up For Free</span>
                    </a>
                  </div>
                </div>
              </div>
    </section>*/}
            {/*<!-- ==============SIGN UP htmlFor FREE SECTION END HERE============== -->*/}
          </main>
          <section id='main-content'>
            <div className='row'>{/*<!-- /# column -->*/}</div>
            {/*<!-- /# row -->*/}
          </section>
        </div>
      </div>
        </div>
    )
}