import PropTypes from "prop-types";
import React, { Component } from "react";

import "../../assets/css/toggleButton.css";

export default class ToggleButton extends Component {
  constructor(props) {
    super();
    this.state = {};
  }

  render() {
    const { selected, toggleSelected } = this.props;
    return (
      <div className="toggle-container" onClick={toggleSelected} >
        <div className={`dialog-button ${selected ? "" : "disabled"}`}
            onClick={toggleSelected} style={{backgroundColor:selected?"#02B900":""}}
        >
        </div>
      </div>
    );
  }
}

ToggleButton.propTypes = {
  selected: PropTypes.bool.isRequired,
  toggleSelected: PropTypes.func.isRequired
};
