import React, { useEffect, useRef, useState } from "react";
import SideBar from "../common/sidebar";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import CheckToken from "../common/check_token";
import ToggleButton from "../common/togglebutton";
import { Calendar } from "react-date-range";
import moment from "moment-timezone";
import { Modal, Button } from "react-bootstrap";
import Loader from "../common/loader";
import Footer from "../common/footer";

///import { ca } from "date-fns/locale";

export default function Settings() {
  const auth = useSelector((state) => state.auth);
  const [workspaceData, setWorkspaceData] = useState({ title: "", id: "" });
  const [errors, setErrors] = useState({});
  const [profileInfo, setProfileInfo] = useState([]);
  const [is_sole_team_viewer, setTeamViewer] = useState(false);
  const [is_sole_project_creator, setProjectCreator] = useState(false);
  const [is_sole_billable_rate_viewer, setBillablerate] = useState(false);
  const [requiredFields, setRequiredFields] = useState(false);
  const [selected, setSelected] = useState(false);
  const [selectedLocked, setSelectedLocked] = useState(false);
  const [isLockedTimeEntry, setLockedTimeEntry] = useState(false);
  const [calendarDate, setDate] = useState(new Date());
  const [openCalendar, setOpenCalendar] = useState(false);
  const [maxDate, setMaxDate] = useState(new Date());
  const [dateString, setDateString] = useState("");
  const [isTimeRounding, setTimeRounding] = useState(false);
  const [selectedRounding, setSelectedRounding] = useState(false);
  const [openRoundingTo, setOpenRoundTo] = useState(false);
  const [openRoundingDuration, setOpenRoundingDuration] = useState(false);
  const [roundingToValue, setRoundingTo] = useState("");
  const [roundingDurationValue, setRoundingDuration] = useState("");
  const [state, setState] = useState({ file: null, fileName: null });
  const [openImg, setOpenImg] = useState(false);
  const [defaultBillable, setDefaultBillable] = useState(false);
  const [isUploading, setUploading] = useState(false);
  const ref = useRef(null);
  const roundToRef = useRef(null);
  const roundDurationRef = useRef(null);

  const roundTo = ["Round up to", "Round to nearest", "Round down to"];
  const roundingDuration = [
    "1 minute",
    "5 minutes",
    "6 minutes",
    "10 minutes",
    "12 minutes",
    "15 minutes",
    "30 minutes",
    "1 hour",
    "4 hours",
  ];
  useEffect(() => {
    document.addEventListener("click", () => {
      //setOpenRoundTo(false);
      //setOpenRoundingDuration(false);
    });
    if (auth.user_properties !== null && auth.user_properties !== undefined) {
      let timeZone = auth.user_properties.timeZone;
      if (timeZone === "" || timeZone === null || timeZone === undefined) {
        moment.tz.setDefault();
      } else {
        timeZone = timeZone.split(" ")[0];
        moment.tz.setDefault(timeZone);
      }
      let today = moment();
      let dd1 = today.date();
      let MM1 = today.month();
      let yy1 = today.year();

      let todayDate = moment({ year: yy1, month: MM1, day: dd1 });
      setMaxDate(todayDate._d);
    } else {
      moment.tz.setDefault();
      let today = moment();
      let dd1 = today.date();
      let MM1 = today.month();
      let yy1 = today.year();
      let todayDate = moment({ year: yy1, month: MM1, day: dd1 });
      setMaxDate(todayDate._d);
    }

    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        //alert("You clicked outside of me!");
        setOpenCalendar(false);
      }
      if (roundToRef.current && !roundToRef.current.contains(event.target)) {
        setOpenRoundTo(false);
      }
      if (
        roundDurationRef.current &&
        !roundDurationRef.current.contains(event.target)
      ) {
        setOpenRoundingDuration(false);
      }
    }

    getWorkspaceInfo();
    getProfile();
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getWorkspaceInfo = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let time_duration_format = "Improved";
      let date_format = "DD-MM-YYYY";
      let time_format = "12-hour";
      if (auth.user_properties !== null && auth.user_properties !== undefined) {
        time_duration_format =
          auth.user_properties.timeDurationFormat.split(" ")[0];
        date_format = auth.user_properties.dateFormat;
        time_format = auth.user_properties.timeFormat;
      }
      let workspace_id = auth.workspace_id;
      const body = JSON.stringify({ workspace_id });
      let res = await axios.post(
        "/api/workspace/listbyworkspaceid",
        body,
        config
      );
      if (res.status === 200) {
        res.data.results.map((item) => {
          if (item.id === workspace_id) {
            setState({ fileName: item.workspace_logo });
            if (item.is_default_billable === 1) {
              setDefaultBillable(true);
            }
            if (item.is_time_rounding === 1) {
              setTimeRounding(true);
              setSelectedRounding(true);
              setRoundingTo(item.rounding_to);
              setRoundingDuration(item.rounding_duration);
            }
            if (item.is_time_entry_locked === 1) {
              setLockedTimeEntry(true);
              setSelectedLocked(true);
            }
            if (
              item.locked_date !== "" &&
              item.locked_date !== null &&
              item.locked_date !== undefined
            ) {
              let arr = item.locked_date.split("-");
              let dd = arr[0];
              let MM = arr[1];
              let yy = arr[2];
              let m = moment({ year: yy, month: MM, date: dd });
              setDate(m._d);
              setDateString(m.format(date_format));
            } else {
              let yesterday = moment().subtract(1, "days");
              let dd2 = yesterday.date();
              let MM2 = yesterday.month();
              let yy2 = yesterday.year();

              let yesterdayDate = moment({ year: yy2, month: MM2, day: dd2 });
              setDateString(yesterdayDate.format(date_format));
              setDate(yesterdayDate._d);
            }
            setWorkspaceData({
              title: item.title,
              id: item.id,
            });
          }
        });
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  const getProfile = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let workspace_id = auth.workspace_id;
      const body = JSON.stringify({ workspace_id });
      let res = await axios.post("/api/workspace/settings", body, config);
      if (res.status === 200) {
        if (res.data.results[1][0].is_sole_billable_rate_viewer === 1) {
          setBillablerate(true);
        }
        if (res.data.results[1][0].is_sole_project_creator === 1) {
          setProjectCreator(true);
        }
        if (res.data.results[1][0].is_sole_team_viewer === 1) {
          setTeamViewer(true);
        }
        if (res.data.results[1][0].is_required_fields === 1) {
          setRequiredFields(true);
          setSelected(true);
        }
        setProfileInfo(res.data.results[0][0]);
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  const userCode = () => {
    let firstLetter = "";
    let lastLeter = "";
    if (profileInfo.first_name !== null && profileInfo.last_name != null) {
      firstLetter = profileInfo.first_name.charAt(0);
      lastLeter = profileInfo.last_name.charAt(0);
    } else if (
      profileInfo.email !== null &&
      typeof profileInfo.email !== "undefined"
    ) {
      firstLetter = profileInfo.email.charAt(0);
      lastLeter = profileInfo.email.charAt(1);
    }
    return firstLetter.concat(lastLeter).toUpperCase();
  };

  const handleWorkspaceChange = (e) => {
    setWorkspaceData({ title: e.target.value });
  };

  const validateForm = () => {
    let errors = {};
    let formIsValid = true;
    let title = workspaceData.title;
    if (title === "") {
      formIsValid = false;
      errors["title"] = "Please enter the Workspace Title";
    }
    setErrors(errors);
    return formIsValid;
  };

  const editWorkspace = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${auth.token}`,
        "Content-Type": "application/json",
      },
    };
    let title = workspaceData.title;
    let workspace_id = workspaceData.id || auth.workspace_id;
    const body = JSON.stringify({ workspace_id, title });

    try {
      if (validateForm() && auth.is_workspace_admin === 1) {
        let res = await axios.put("/api/workspace/edit", body, config);
        if (res.status === 200) {
          window.location.reload();
        }
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  const changeTeamViewer = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let id = 1;
      let value;
      if (is_sole_team_viewer === true) {
        value = 0;
      } else {
        value = 1;
      }
      let workspace_id = auth.workspace_id;
      const body = JSON.stringify({ id, value, workspace_id });
      let res = await axios.put("/api/workspace/update_settings", body, config);
      if (res.status === 200) {
        setTeamViewer(!is_sole_team_viewer);
        getProfile();
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  const changeProjectCreator = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let id = 2;
      let value;
      if (is_sole_project_creator === true) {
        value = 0;
      } else {
        value = 1;
      }
      let workspace_id = auth.workspace_id;
      const body = JSON.stringify({ id, value, workspace_id });
      let res = await axios.put("/api/workspace/update_settings", body, config);
      if (res.status === 200) {
        setProjectCreator(!is_sole_project_creator);
        getProfile();
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  const changeBillablerateView = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let id = 3;
      let value;
      if (is_sole_billable_rate_viewer === true) {
        value = 0;
      } else {
        value = 1;
      }
      let workspace_id = auth.workspace_id;
      const body = JSON.stringify({ id, value, workspace_id });
      let res = await axios.put("/api/workspace/update_settings", body, config);
      if (res.status === 200) {
        setBillablerate(!is_sole_billable_rate_viewer);
        getProfile();
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  const updateRequiredField = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-type": "application/json",
        },
      };
      let id = 4;
      let value;
      if (requiredFields) {
        value = 0;
      } else {
        value = 1;
      }
      let workspace_id = auth.workspace_id;
      const body = JSON.stringify({
        workspace_id,
        value,
        id,
      });
      let res = await axios.put("api/workspace/update_settings", body, config);
      if (res.status === 200) {
        setRequiredFields(!requiredFields);
        getProfile();
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  const changeDate = async (date) => {
    try {
      let time_duration_format = "Improved";
      let date_format = "DD-MM-YYYY";
      let time_format = "12-hour";
      if (auth.user_properties !== null && auth.user_properties !== undefined) {
        time_duration_format =
          auth.user_properties.timeDurationFormat.split(" ")[0];
        date_format = auth.user_properties.dateFormat;
        time_format = auth.user_properties.timeFormat;
      }
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-type": "application/json",
        },
      };
      setDate(date);
      let dd = date.getDate();
      let MM = date.getMonth();
      let yy = date.getFullYear();
      let m = moment({ year: yy, month: MM, date: dd });
      let dString = m.format("DD-MM-YYYY");
      let dd1 = m.date();
      let MM1 = m.month();
      let yy1 = m.year();
      let dbDate = `${dd1}-${MM1}-${yy1}`;
      setDateString(m.format(date_format));
      let value = 1;
      let workspace_id = auth.workspace_id;
      const body = JSON.stringify({
        workspace_id,
        value,
        date: dbDate,
      });
      let res = await axios.put(
        "api/workspace/update_locked_settings",
        body,
        config
      );
      if (res.status === 200) {
        setOpenCalendar(false);
        getProfile();
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  const changeLockedTimeEntry = async () => {
    try {
      let time_duration_format = "Improved";
      let date_format = "DD-MM-YYYY";
      let time_format = "12-hour";
      if (auth.user_properties !== null && auth.user_properties !== undefined) {
        time_duration_format =
          auth.user_properties.timeDurationFormat.split(" ")[0];
        date_format = auth.user_properties.dateFormat;
        time_format = auth.user_properties.timeFormat;
      }
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-type": "application/json",
        },
      };
      let value;
      if (isLockedTimeEntry === true) {
        value = 0;
        let yesterday = moment().subtract(1, "days");
        let dd2 = yesterday.date();
        let MM2 = yesterday.month();
        let yy2 = yesterday.year();

        let yesterdayDate = moment({ year: yy2, month: MM2, day: dd2 });
        setDateString(yesterdayDate.format(date_format));
        setDate(yesterdayDate._d);
      } else {
        value = 1;
      }
      let dd = calendarDate.getDate();
      let MM = calendarDate.getMonth();
      let yy = calendarDate.getFullYear();
      let date = `${dd}-${MM}-${yy}`;
      let workspace_id = auth.workspace_id;
      const body = JSON.stringify({
        workspace_id,
        value,
        date,
      });
      let res = await axios.put(
        "api/workspace/update_locked_settings",
        body,
        config
      );
      if (res.status === 200) {
        setLockedTimeEntry(!isLockedTimeEntry);
        getProfile();
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  const changeTimeRounding = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-type": "application/json",
        },
      };
      let value, round_to, rounding_duration;
      if (isTimeRounding === true) {
        value = 0;
        round_to = "";
        rounding_duration = "";
      } else {
        value = 1;
        round_to = roundTo[0];
        rounding_duration = roundingDuration[0];
      }
      const body = JSON.stringify({
        workspace_id: auth.workspace_id,
        value,
        round_to,
        rounding_duration,
      });
      let res = await axios.put(
        "api/workspace/update_rounding_settings",
        body,
        config
      );
      if (res.status === 200) {
        setRoundingTo(round_to);
        setRoundingDuration(rounding_duration);
        setTimeRounding(!isTimeRounding);
        getProfile();
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  const changeRoundTo = async (item) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-type": "application/json",
        },
      };
      let value = 1,
        round_to,
        rounding_duration;
      round_to = item;
      rounding_duration = roundingDurationValue;
      const body = JSON.stringify({
        workspace_id: auth.workspace_id,
        value,
        round_to,
        rounding_duration,
      });
      let res = await axios.put(
        "api/workspace/update_rounding_settings",
        body,
        config
      );
      if (res.status === 200) {
        setRoundingTo(round_to);
        setRoundingDuration(rounding_duration);
        setOpenRoundTo(false);
        getProfile();
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  const changeRoundingDuration = async (item) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-type": "application/json",
        },
      };
      let value = 1,
        round_to,
        rounding_duration;
      round_to = roundingToValue;
      rounding_duration = item;
      const body = JSON.stringify({
        workspace_id: auth.workspace_id,
        value,
        round_to,
        rounding_duration,
      });
      let res = await axios.put(
        "api/workspace/update_rounding_settings",
        body,
        config
      );
      if (res.status === 200) {
        setRoundingTo(round_to);
        setRoundingDuration(rounding_duration);
        setOpenRoundingDuration(false);
        getProfile();
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  const closeImg = () => {
    setErrors({});
    setOpenImg(false);
    getWorkspaceInfo();
  };

  const onInputChange = (e) => {
    setState({ file: e.target.files[0] });
  };

  const imgClick = () => {
    setOpenImg(true);
  };

  const validateImage = () => {
    let fileSize = 1024 * 1024 * 2;
    let errors = {};
    let isValid = false;
    let isValidType = false;
    let allowedExtension = ["jpeg", "jpg", "png"];
    if (state.file !== null && state.file !== undefined && state.file !== "") {
      isValid = true;
    } else {
      errors["input_file"] = "Please select a file.";
      setErrors(errors);
      isValid = false;
      return isValid;
    }
    if (state.file && state.file.type.includes("image")) {
      isValid = true;
    } else {
      errors["input_file"] = "File is not an Image.";
      setErrors(errors);
      isValid = false;
      return isValid;
    }
    let fileExtension = state.file.type.split("/")[1];
    for (let x in allowedExtension) {
      if (fileExtension === allowedExtension[x]) {
        isValidType = true;
        break;
      }
    }
    if (isValidType === false) {
      let errors = {};
      errors["input_file"] = "File Extendion is not acceptable";
      setErrors(errors);
      isValid = false;
      return isValid;
    }
    if (state.file.size < fileSize) {
      isValid = true;
      return isValid;
    } else {
      let errors = {};
      errors["input_file"] = "Image size should be less than 2 mb";
      setErrors(errors);
      isValid = false;
      return isValid;
    }
  };

  const uploadImage = async (e) => {
    try {
      if (validateImage() === true) {
        setUploading(true);
        const formData = new FormData();
        formData.append("image", state.file);
        formData.append("workspace_id", auth.workspace_id);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${auth.token}`,
          },
        };
        let res = await axios.post(
          "/api/workspace/logo_upload",
          formData,
          config
        );
        //console.log(res.data)
        if (res.status === 200) {
          let ff = res.data.filename;
          setErrors({});
          setState({ fileName: ff });
          setOpenImg(false);
          setUploading(false);
        }
        else {
          let errors = {};
          errors["input_file"] = "Image upload failed.";
          setErrors(errors);
          setTimeout(() => {
            setOpenImg(false);
            setUploading(false);
          }, 2000);
        }
      }
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      }
      let errors = {};
      errors["input_file"] = "Image upload failed.";
      setErrors(errors);
      setUploading(false);
    }
  };

  const changeDefaultBillable = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let id = 5;
      let value;
      if (defaultBillable === true) {
        value = 0;
      } else {
        value = 1;
      }
      let workspace_id = auth.workspace_id;
      const body = JSON.stringify({ id, value, workspace_id });
      let res = await axios.put("/api/workspace/update_settings", body, config);
      if (res.status === 200) {
        setDefaultBillable(!defaultBillable);
        getProfile();
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  return (
    <div>
      <CheckToken />
      <SideBar />
      <div class="header">
        <div class="container-fluid">
          <div class="row" style={{ flexDirection: "column" }}>
            <div class="reports-header-contain setting-header-contain">
              <h3>Settings</h3>
              {/*<a href="settings.html" class="reports-header-items" 
                                style={{color:"rgb(0,0,0)", fontWeight: "500"}}>General</a>
                              <a href="alerts.html" class="reports-header-items">Alerts</a>
                              <a href="reminders.html" class="reports-header-items">Reminders</a>
                              <a href="integrations.html" class="reports-header-items">Integrations</a>
                              <a href="import.html" class="reports-header-items">CSV import</a>*/}
            </div>
          </div>
        </div>
      </div>
      {/*<!-- ==================================HEADER END HERE================================== -->*/}

      <div class="content-wrap" id="wrapper">
        <div class="main">
          <div class="d-flex align-items-center setting-top-workspace-set-container">
            <div class="setting-workspace-logo-box">
              <div
                class="left-logo-inner-box"
                onClick={() => imgClick()}
                style={{
                  display:
                    state.fileName === "" ||
                    state.fileName === null ||
                    state.fileName === undefined
                      ? ""
                      : "none",
                  pointerEvents: auth.is_workspace_admin === 0 ? "none" : "",
                  padding: "0px",
                }}
              >
                <svg width="64" height="64" viewBox="0 0 18 16">
                  <path
                    d="M6.503 2h4.994c.098 0 .252.119.275.21l.758 3.033L13.5 4h-9l.97 1.243.758-3.032c.024-.094.173-.211.275-.211zm7.778 2l-.569-2.274C13.466.74 12.51 0 11.497 0H6.503c-1.02 0-1.97.743-2.215 1.726L3.719 4H1.995C.893 4 0 4.9 0 6.009v7.982C0 15.098.893 16 1.995 16h14.01C17.107 16 18 15.1 18 13.991V6.01A2.004 2.004 0 0016.005 4H14.28z"
                    fill="#cecece"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span>Workspace logo</span>
              </div>
              <div
                class="left-logo-inner-box"
                onClick={() => imgClick()}
                style={{
                  display:
                    state.fileName === "" ||
                    state.fileName === null ||
                    state.fileName === undefined
                      ? "none"
                      : "",
                  pointerEvents: auth.is_workspace_admin === 0 ? "none" : "",
                  padding: "0px",
                }}
              >
                <img
                  src={state.fileName ? `${process.env.REACT_APP_CLOUDINARY_URL}/${state.fileName}` : ''}
                  width={220}
                  height={220}
                  alt=""
                />
              </div>
            </div>
            <div class="right-setting-workspace-info-box">
              <div class="workspace-inner-info">
                <label>
                  WORKSPACE NAME &nbsp;{" "}
                  <i class="fa fa-info-circle" aria-hidden="true"></i>
                </label>
                <div class="put-workspace-field-contain">
                  <form>
                    <input
                      readOnly={auth.is_workspace_admin === 1 ? false : true}
                      type="text"
                      class="setting-workspace-name-field"
                      onChange={handleWorkspaceChange}
                      onBlur={editWorkspace}
                      value={workspaceData.title}
                      placeholder="Your workspace name"
                    />
                    <span>{errors.title}</span>
                  </form>
                </div>

                {/*<div class="workspace-free-badge-contain">
                <label>PRICING PLAN</label>
                <span class="free-badge">FREE</span>
                <span class="free-info"><a href='javascript:void(0)'>Upgrade</a> to unlock more features</span>
                </div>*/}
              </div>
            </div>
          </div>
          {/*<!-- setting top workspace info section end here -->*/}
          <div class="project-group-work-section" style={{ margin: "0px" }}>
            <div
              class="working-group-area-contain setting-sections-wrapper"
              style={{ margin: "0px" }}
            >
              {/*<!-- team member rights section -->*/}
              <section
                class="setting-blocks-section-contain"
                style={{ display: auth.is_workspace_admin === 1 ? "" : "none" }}
              >
                <div class="setting-block-header-contain">
                  <div class="header-heading">
                    <span>Team member rights</span>
                  </div>
                  <div class="header-description">
                    <span>Access and visibility rights for team members</span>
                  </div>
                </div>
                {/*<!-- setting-header-block end here -->*/}
                <div class="setting-block-info-contain">
                  <div class="info-inner-blocks">
                    <div class="block-title">
                      <h5>WHO CAN SEE TEAM ACTIVITY</h5>
                    </div>
                    <div class="select-who-can-contain">
                      <p>
                        {/**                 <span class={is_sole_team_viewer?"custom-checkboxes selected":"custom-checkboxes"}>
						<input type="checkbox" name="filter-item[]" onClick={()=>changeTeamViewer()}/> 
					</span>&nbsp;&nbsp;&nbsp;
                    <label for="admin">Admin</label>*/}

                        <input
                          type="radio"
                          id="admin1"
                          name="radio-group-team-viewer"
                          checked={is_sole_team_viewer === true}
                        />
                        <label for="admin" onClick={() => changeTeamViewer()}>
                          Admin
                        </label>
                      </p>
                      <p>
                        <input
                          type="radio"
                          id="everyone1"
                          name="radio-group-team-viewer"
                          checked={is_sole_team_viewer === false}
                        />
                        <label
                          for="everyone"
                          onClick={() => changeTeamViewer()}
                        >
                          Everyone
                        </label>
                      </p>
                    </div>
                  </div>
                  {/*<!-- WHO CAN SEE TEAM ACTIVITY BLOCK END HERE -->*/}
                  <div class="info-inner-blocks">
                    <div class="block-title">
                      <h5>WHO CAN CREATE PROJECT</h5>
                    </div>
                    <div class="select-who-can-contain">
                      <p>
                        <input
                          type="radio"
                          id="admin2"
                          name="radio-group-project-creator"
                          checked={is_sole_project_creator === true}
                        />
                        <label
                          for="admin"
                          onClick={() => changeProjectCreator()}
                        >
                          Admin
                        </label>
                      </p>
                      <p>
                        <input
                          type="radio"
                          id="everyone2"
                          name="radio-group-project-creator"
                          checked={is_sole_project_creator === false}
                        />
                        <label
                          for="everyone"
                          onClick={() => changeProjectCreator()}
                        >
                          Everyone
                        </label>
                      </p>
                    </div>
                  </div>
                  {/*<!-- WHO CAN CREATE PROJECT BLOCK END HERE -->*/}
                  <div class="info-inner-blocks">
                    <div class="block-title">
                      <h5>WHO CAN SEE BILLABLE RATES</h5>
                    </div>
                    <div class="select-who-can-contain">
                      <p>
                        <input
                          type="radio"
                          id="admin3"
                          name="radio-group-billable-view"
                          checked={is_sole_billable_rate_viewer === true}
                        />
                        <label
                          for="admin"
                          onClick={() => changeBillablerateView()}
                        >
                          Admin
                        </label>
                      </p>
                      <p>
                        <input
                          type="radio"
                          id="everyone3"
                          name="radio-group-billable-view"
                          checked={is_sole_billable_rate_viewer === false}
                        />
                        <label
                          for="everyone"
                          onClick={() => changeBillablerateView()}
                        >
                          Everyone
                        </label>
                      </p>
                    </div>
                  </div>
                  {/*<!-- WHO CAN SEE BILLABLE RATES BLOCK END HERE -->*/}
                </div>
              </section>
              {/*<!--============================== team member rights section end here=================== -->*/}

              {/*<!--===========================Default billing set-up section===================== -->*/}
              <section
                class="setting-blocks-section-contain"
                style={{ display: auth.is_workspace_admin === 1 ? "" : "none" }}
              >
                <div class="setting-block-header-contain">
                  <div class="header-heading">
                    <span>Default billing set-up</span>
                  </div>
                  <div class="header-description">
                    <span>
                      Workspace and all new Projects will have these settings by
                      default
                    </span>
                  </div>
                </div>
                {/*<!-- setting-header-block end here -->*/}
                <div class="setting-block-info-contain">
                  <div class="info-inner-blocks">
                    <div class="block-title">
                      <h5>NEW PROJECTS</h5>
                    </div>
                    <div class="select-who-can-contain">
                      <p>
                        <input
                          type="radio"
                          id="billable"
                          name="radio-group-billable-project"
                          checked={defaultBillable === true}
                        />
                        <label
                          for="billable"
                          onClick={() => changeDefaultBillable()}
                        >
                          Billable
                        </label>
                      </p>
                      <p>
                        <input
                          type="radio"
                          id="non-billable"
                          name="radio-group-billable-project"
                          checked={defaultBillable === false}
                        />
                        <label
                          for="non-billable"
                          onClick={() => changeDefaultBillable()}
                        >
                          Non-billable
                        </label>
                      </p>
                    </div>
                  </div>
                  {/*<!-- NEW PROJECTS BLOCK END HERE -->*/}
                </div>
              </section>
              {/*<!--==================== Default billing set-up section end here=================================== -->*/}

              {/*<!--===========================Time entry restrictions set-up section===================== -->*/}
              <section
                class="setting-blocks-section-contain"
                style={{ display: auth.is_workspace_admin === 1 ? "" : "none" }}
              >
                <div class="setting-block-header-contain">
                  <div class="header-heading">
                    <span>Time entry restrictions</span>
                  </div>
                  <div class="header-description">
                    <span>
                      Set rules to make sure your reports are always orderly
                    </span>
                  </div>
                </div>
                {/*<!-- setting-header-block end here -->*/}
                <div class="toggle-list-contain">
                  <div class="toggle-btn-box">
                    <div class="toggle-switch">
                      <input
                        type="checkbox"
                        id="set-required"
                        onClick={() => updateRequiredField()}
                      />
                      <label
                        for="set-required"
                        style={{ marginBottom: "0px", marginTop: "15px" }}
                      >
                        {/*<span class="toggle-track"></span>*/}
                        <ToggleButton
                          selected={selected}
                          toggleSelected={() => {
                            setSelected(!selected);
                          }}
                        />
                      </label>
                    </div>
                  </div>
                  {/*<!-- on-off btn end here -->*/}
                  <div
                    class="toggle-btn-info-box"
                    style={{ borderBottom: "1px solid rgb(243, 243, 243)" }}
                  >
                    <div class="header-heading">
                      <span>Set required fields for new Time entries</span>
                    </div>
                    <div class="header-description">
                      <span>
                        Setting required fields helps to ensure your team fills
                        in all the information you need for accurate reporting
                      </span>
                    </div>
                  </div>
                </div>
                {/*<!-- time-entry toggle row end here -->*/}
                <div class="toggle-list-contain">
                  <div class="toggle-btn-box">
                    <div class="toggle-switch">
                      <input
                        type="checkbox"
                        id="lockTime"
                        onClick={() => changeLockedTimeEntry()}
                      />
                      <label
                        for="lockTime"
                        style={{ marginBottom: "0px", marginTop: "15px" }}
                      >
                        {/*<span class="toggle-track"></span>*/}
                        <ToggleButton
                          selected={selectedLocked}
                          toggleSelected={() => {
                            setSelectedLocked(!selectedLocked);
                          }}
                        />
                      </label>
                    </div>
                  </div>
                  {/*<!-- on-off btn end here -->*/}
                  <div class="toggle-btn-info-box">
                    <div class="header-heading">
                      <span>Lock Time entries</span>
                    </div>
                    <div class="header-description">
                      <span>
                        This allows to lock existing Time entries and prevent
                        creating new ones before selected date
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: isLockedTimeEntry ? "" : "none",
                    marginLeft: "5%",
                    marginBottom: "2%",
                  }}
                >
                  <div
                    class="header-heading"
                    style={{
                      fontSize: "12px",
                      marginTop: "0%",
                      padding: "4px",
                    }}
                  >
                    LOCK TIME ENTRIES UP TO
                  </div>
                  <div style={{ marginTop: "0%" }}>
                    <div class="select-who-can-contain">
                      <div class="in-profile-select-country-contain dropdown">
                        <div
                          class="project-popup-choose-workspace data-toggle"
                          data-toggle="dropdown"
                          onClick={() => setOpenCalendar(true)}
                          ref={ref}
                        >
                          <span class="workspace-info selectedTimeFormat">
                            {dateString}
                          </span>
                          <i class="fa fa-angle-down"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="datepicker datepicker-dropdown dropdown-menu datepicker-orient-left datepicker-orient-bottom"
                    style={{
                      display: openCalendar ? "block" : "none",
                      top: "70%",
                      left: "20%",
                      zIndex: "10",
                    }}
                    ref={ref}
                    //ref={this.calendarRef}
                  >
                    <Calendar
                      onChange={(item) => changeDate(item)}
                      weekStartsOn={
                        auth.user_properties !== null &&
                        auth.user_properties !== undefined
                          ? auth.user_properties.firstDayOfWeek
                          : 0
                      }
                      date={calendarDate}
                      maxDate={maxDate}
                    />
                  </div>
                </div>
                {/*<!-- time-entry toggle row end here -->*/}
              </section>
              {/*<!--==================== Time entry restrictions section end here=================================== -->*/}

              {/*<!--===========================Time rounding set-up section===================== -->*/}
              <section
                class="setting-blocks-section-contain"
                style={{ display: auth.is_workspace_admin === 1 ? "" : "none" }}
              >
                <div class="toggle-list-contain">
                  <div class="toggle-btn-box">
                    <div class="toggle-switch">
                      <input
                        type="checkbox"
                        id="roundTime"
                        onClick={() => changeTimeRounding()}
                      />
                      <label
                        for="roundTime"
                        style={{ marginBottom: "0px", marginTop: "15px" }}
                      >
                        {/*<span class="toggle-track"></span>*/}
                        <ToggleButton
                          selected={selectedRounding}
                          toggleSelected={() => {
                            setSelectedRounding(!selectedRounding);
                          }}
                        />
                      </label>
                    </div>
                  </div>
                  {/*<!-- on-off btn end here -->*/}
                  <div class="toggle-btn-info-box">
                    <div class="header-heading">
                      <span>Time rounding in reports</span>
                    </div>
                    <div class="header-description">
                      <span>
                        Reports will have these settings by default.&nbsp;
                        {/*<a href='javascript:void(0)' 
                    style={{color:"rgb(67, 180, 0)", textDecoration: "underline !important", fontWeight:"500"}}>
                    Find out more
                </a>*/}
                      </span>
                    </div>
                  </div>
                </div>
                {/*<!-- time-entry toggle row end here -->*/}
                <div
                  style={{
                    display: isTimeRounding === true ? "flex" : "none",
                    marginLeft: "5%",
                    marginBottom: "2%",
                  }}
                >
                  <div
                    class="select-who-can-contain"
                    style={{ display: "block", width: "20%" }}
                    ref={roundToRef}
                  >
                    <div class="in-profile-select-country-contain dropdown">
                      <div
                        class="project-popup-choose-workspace data-toggle"
                        data-toggle="dropdown"
                        onClick={() => setOpenRoundTo(true)}
                      >
                        <span class="workspace-info selectedTimeFormat">
                          {roundingToValue}
                        </span>
                        <i class="fa fa-angle-down"></i>
                      </div>
                      <div
                        class={
                          openRoundingTo
                            ? "dropdown-menu filter-by-button-dropdown-wrap show"
                            : "dropdown-menu filter-by-button-dropdown-wrap"
                        }
                      >
                        <div class="Teamdropdown_container">
                          <ul class="select-country-profile" id="roundingTo">
                            {roundTo.map((item, i) => (
                              <li
                                class={
                                  roundingToValue === item
                                    ? "selected-time-format"
                                    : ""
                                }
                                key={i}
                                onClick={() => changeRoundTo(item)}
                              >
                                {item}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="select-who-can-contain"
                    style={{
                      display: "block",
                      marginLeft: "20px",
                      width: "20%",
                    }}
                    ref={roundDurationRef}
                  >
                    <div class="in-profile-select-country-contain dropdown">
                      <div
                        class="project-popup-choose-workspace data-toggle"
                        data-toggle="dropdown"
                        onClick={() => setOpenRoundingDuration(true)}
                      >
                        <span class="workspace-info selectedTimeFormat">
                          {roundingDurationValue}
                        </span>
                        <i class="fa fa-angle-down"></i>
                      </div>
                      <div
                        class={
                          openRoundingDuration
                            ? "dropdown-menu filter-by-button-dropdown-wrap show"
                            : "dropdown-menu filter-by-button-dropdown-wrap"
                        }
                      >
                        <div class="Teamdropdown_container">
                          <ul
                            class="select-country-profile"
                            id="roundingDuration"
                          >
                            {roundingDuration.map((item, i) => (
                              <li
                                class={
                                  roundingDurationValue === item
                                    ? "selected-time-format"
                                    : ""
                                }
                                key={i}
                                onClick={() => changeRoundingDuration(item)}
                              >
                                {item}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/*<!--==================== Time rounding section end here=================================== -->*/}

              {/*<!--===========================Workspace ownership section===================== -->*/}
              <section class="setting-blocks-section-contain">
                <div class="setting-block-header-contain">
                  <div class="header-heading">
                    <span>Workspace ownership</span>
                  </div>
                  <div class="header-description">
                    <span>
                      Team member who controls the data associated with this
                      Workspace.&nbsp;
                      {/*<a href='javascript:void(0)' style={{color:"rgb(67, 180, 0)", 
                    textDecoration: "underline !important", fontWeight:"500"}}>
                    Find out more
                        </a>*/}
                    </span>
                  </div>
                </div>
                {/*<!-- setting-header-block end here -->*/}
                <div class="toggle-list-contain workspace-owenrship">
                  <div class="toggle-btn-box in-setting-user-short-contain">
                    <span
                      class="short-name"
                      style={{
                        backgroundColor:
                          "#" + profileInfo.profile_name_colorcode,
                      }}
                    >
                      {userCode()}
                    </span>
                    <span
                      class="user-full-name"
                      style={{
                        width: "200px",
                        height: "50px",
                        padding: "13px",
                      }}
                    >
                      {profileInfo.first_name + " " + profileInfo.last_name}
                    </span>
                  </div>

                  <div
                    class="toggle-btn-info-box"
                    style={{ borderBottom: "1px solid rgb(243, 243, 243)" }}
                  >
                    {/*<div class="header-description">
            <span>To change the ownership of the Workspace, first add another Admin on the &nbsp; 
                <a href='javascript:void(0)' 
                style={{color:"rgb(67, 180, 0)", textDecoration: "underline !important", fontWeight:"500"}}>
                    Team page</a>
            </span>
                    </div>*/}
                  </div>
                </div>
                {/*<!-- time-entry toggle row end here -->*/}
              </section>
              
              {/*<!--==================== Workspace ownership section end here=================================== -->*/}
              <Modal
                show={openImg}
                onHide={closeImg}
                dialogClassName="add-New-tag-popup-box-container"
                bsPrefix=""
                dialogClassName="modal-90w"
              >
                <Modal.Header>Select Your Profile Picture</Modal.Header>
                <Modal.Body>
                  <h1>File Upload</h1>
                  <div style={{ display: isUploading ? "" : "none" }}>
                    <Loader />
                  </div>
                  <span class="error-msg-in-ch-ps" style={{ color: "red" }}>
                    {errors.input_file}
                  </span>
                  <Modal.Footer>
                    <table style={{ boarder: "none" }}>
                      <tr>
                        <td>
                          <input
                            type="file"
                            name="myImage"
                            accept="image"
                            class="selelct-picture"
                            onChange={onInputChange}
                          />
                        </td>
                        <td>
                          <Button
                            variant="success"
                            size="sm"
                            onClick={() => uploadImage()}
                          >
                            Upload
                          </Button>
                        </td>
                      </tr>
                    </table>
                  </Modal.Footer>
                </Modal.Body>
              </Modal>
              {/*<!-- need help making toggle in end bottom -->*/}
              <div class="setting-bottom-hr-section">
                <div class="horizontal-line"></div>
                <span class="need-help-bottom-content"></span>
              </div>
            </div>
          </div>
        </div>{" "}
        {/*<!-- main div end here -->*/}
      </div>
      <Footer />
      {/*<!-- content wrapper end here -->*/}
    </div>
  );
}
