import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Navbar } from "../common/Navbar";
import axios from "axios";
import { setAlert } from "../../actions/alert";
import { useSelector, useDispatch } from "react-redux";
import Alert from "../common/Alert";

export default function ForgotPassword () {

    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState("");
    const dispatch = useDispatch();

    useEffect(()=> {

    },[])

    const validateEmail = () => {
        let emailText = email || "";
        let errors = {};
        let formIsValid = true;
        if (emailText === "") {
            formIsValid = false;
            errors["email"] = "Please enter your email address.";
        }
        if (emailText !== "") {
            let pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );
            if (!pattern.test(emailText)) {
            formIsValid = false;
            errors["email"] = "Please enter valid email address.";
            }
        }
        setErrors(errors)
        return formIsValid;
    }

    const sendEail = async () => {
        try {
            if(validateEmail()) {
                const config = {
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                    },
                };
                const body = JSON.stringify({
                    email : email,  
                });
                let res = await axios.post("/api/profile/forgot_password",body,config);
                if(res.status === 200) {
                    if(res.data.user === true) {
                      dispatch(setAlert(res.data.msg,"success"));
                      setEmail("");
                    }
                }
            }
        }
        catch(error) {
            if (error.response) {
                let errors = {};
                console.log(error.response)
                if(error.response.status !== 500 && error.response.status !== 404) {
                    errors["email"] = error.response.data.errors[0].msg;
                    setErrors(errors);
                }
            }
        }
    } 

    const onEmailChange = (e) => {
        setEmail(e.target.value);
    }

    return (
        <div>
            <div>
        <div>
        <Navbar />
          <main>
            {/*<!-- top hero section -->*/}
            <div className='main-wrapper-container'>
              <section className='hero'>
                <div className='page-header-content-wrap animate__animated animate__fadeInUp'>
                  <h1>Trouble Logging In?</h1>
                  <h2>Enter your email and we'll send you a link to get back into your account.</h2>
                </div>
              </section>
              <section className='form-login-wrap animate__animated animate__fadeInUp'>
                <div className='container'>
                  <div className='d-flex justify-content-center'>
                    <div className='col-md-6 '>
                      <form
                        action='/'
                        className='form-login'
                        id='login-form'
                        method='post'
                        name='loginform'
                      >
                        <Alert alerts='' />
                        <div
                          className='form-field form-input-field'
                          data-valid-entry={
                            errors.email ? "false" : "true"
                          }
                        >
                          <label htmlFor='login-email'>Email Address</label>
                          <input
                            type='email'
                            name='email'
                            id='login-email'
                            onChange={onEmailChange}
                            value={email}
                            autoCorrect='off'
                            autoCapitalize='off'
                            autoComplete='username'
                            spellCheck='false'
                            required=''
                            pattern='.+@.+\..+$'
                            className={errors.email ? "error" : ""}
                          />
                          <label
                            id='login-email-error'
                            className='error'
                            htmlFor='login-email'
                          >
                            {errors.email}
                          </label>
                        </div>
                        <div
                          className='form-field form-input-field'
                          //data-valid-entry={
                            //this.state.errors.password ? "false" : "true"
                          //}
                        >
                        </div>
                        <div className='form-field form-btn-submit' style={{marginBottom:"15%",marginTop:"15%"}}>
                          <div>
                            <button
                              className='button'
                              id='login-button'
                              type='button'
                              onClick={sendEail}
                            >
                              <span>Send Reset Email</span>
                            </button>
                          </div>
                        </div>
                        <div className='form-field--divider' >
                          <span>or</span>
                        </div>
                        <div className='form-field form-btn-submit' style={{marginTop:"15%"}}>
                          <div>
                          <NavLink to="/">                            
                          <button
                              className='button'
                              id='login-button'
                              type='button'
                              //onClick={this.loginUser}
                            >
                              <span>Sign Up For Free</span>
                            </button></NavLink> 
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            {/*<!-- ==============SIGN UP htmlFor FREE SECTION START FORM HERE============== -->*/}
            {/*<section className='signup-free__link animate__animated animate__fadeInUp'>
              <div className='container'>
                <div className='link-banner link-banner--medium'>
                  <div className='container'>
                    <p>Don't have an account?</p>
                    <a href='/' className='button'>
                      <span>Sign Up For Free</span>
                    </a>
                  </div>
                </div>
              </div>
    </section>*/}
            {/*<!-- ==============SIGN UP htmlFor FREE SECTION END HERE============== -->*/}
          </main>
          <section id='main-content'>
            <div className='row'>{/*<!-- /# column -->*/}</div>
            {/*<!-- /# row -->*/}
          </section>
        </div>
      </div>
        </div>
    )
}