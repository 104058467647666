import React, { useState,useEffect,useRef, lazy, Suspense } from "react";
import SideBar from "../common/sidebar";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import ColorPicker from "../common/ColorPicker";
import { setAlert } from "../../actions/alert";
import Alert from "../common/Alert";
import {Modal } from "react-bootstrap";
import {Doughnut,defaults } from "react-chartjs-2";
import { Link, useHistory,NavLink } from "react-router-dom";
import CheckToken from "../common/check_token";
import Loader from "../common/loader";
import { alterTimeFormat, alterDisplayTime } from "../common/displayTime";
import moment from "moment-timezone";

const Dashboard = lazy(()=> import("./dashboard"));
const ProjectReport = lazy(() => import('./reports'));


export default function ProjectTeam () {
    

    //defaults.global.animation = false;
    const auth = useSelector(state=>state.auth)
    const dispatch = useDispatch();
    const history = useHistory();
    const [memberModalOpen,setMemberModal] = useState(false);
    const [teamModalOpen,setTeamModal] = useState(false);
    const [editModalOpen,setEditModal] = useState(false);
    const [clientModalOpen,setClientModal] = useState(false);
    const [project_id, setProjeId] = useState(0);
    const [idCode, setIdCode] = useState("");
    const [memberList, setMemberList] = useState([]);
    const [projectName,setProjectName] = useState("");
    const [displayProjectName, setDisplayProject] = useState("");
    const [teamList,setTeamList] = useState([]);
    const [clientList,setClientList] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [time,setTime] = useState({hours:"00",minutes:"00",seconds:"00"});
    const [clientData, setClientData] = useState({ title: "No Client", id: "" });
    const [errors, setErrors] = useState({});
    const [checkArray,setCheckArray] = useState([]);
    const [project_color, setColor] = useState("");
    const [showRole, setShowRole] = useState(0);
    const [memberCheckArray, setMemberCheck] = useState([]);
    const [moreArray, setMoreArray] = useState([]);
    const [tagTime, setTagTime] = useState([]);
    const [tagLabel,setTagLabel] = useState([]);
    const [tagColor, setTagColor] = useState([]);
    const [manager,setManager] = useState(false);
    const [is_project_manager, setProjectManager] = useState(false);
    const [tab, setTab] = useState(1);
    const [sortMemberState, setSortMemberState] = useState(false)
    const [is_sole_team_viewer, setTeamViewer] = useState(false);
    const [timeDurationFormat, setTimeDurationFormat] = useState("");
    const [timeFormat, setTimeFormat] = useState("");
    const [dateFormat, setDateFormat] = useState("");
    const [defaultBillable, setDefaultBillable] = useState(false);
    const [firstDayOfWeek, setFirstday] = useState(0);
    const [reload, setReload] = useState(0);
    const chart = useRef(null);


    useEffect(()=>{
      document.addEventListener(
        "click",
        () => {
          setMemberCheck([]);
          setMoreArray([]);
          setClientModal(false);
          setTeamModal(false);
        },
      true
    );
    if(auth.user_properties !== null && 
      auth.user_properties !== undefined) {
        let timeZone = auth.user_properties.timeZone;
        if(timeZone === "" || timeZone === null || timeZone === undefined) {
          moment.tz.setDefault();
        }
        else {
          timeZone = timeZone.split(' ')[0];
          moment.tz.setDefault(timeZone)
        }
        setTimeDurationFormat(auth.user_properties.timeDurationFormat.split(' ')[0]);
        setTimeFormat(alterTimeFormat(auth.user_properties.timeFormat));
        setDateFormat(auth.user_properties.dateFormat);
        setFirstday(auth.user_properties.firstDayOfWeek);
      }
      else {
        moment.tz.setDefault();
        setTimeDurationFormat("Improved");
        setTimeFormat("hh:mm A");
        setDateFormat("DD-MM-YYYY");
        setFirstday(0);
      }
          
        let url_string = window.location.href;
        var url = new URL(url_string);
        var idstring = url.searchParams.get("id");
        setIdCode("/projectteam?id="+idstring);
        let buff = Buffer.from(idstring,'base64');
        let project_id = buff.toString("ascii");
        getWorkspaceInfo(project_id);
        setProjeId(project_id);

        getTeamList(project_id);
        getClientList();
    },[])

    const getWorkspaceInfo = async (project_id) => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            "Content-Type": "application/json",
          },
        };
        let workspace_id = auth.workspace_id;
        const body = JSON.stringify({ workspace_id });
        let res = await axios.post(
          "/api/workspace/listbyworkspaceid",
          body,
          config
        );
        if (res.status === 200) {
          res.data.results.map((item)=> {
            if(item.id === workspace_id) {
              if(item.is_default_billable === 1) {
                setDefaultBillable(true);
              }
                if(item.is_sole_team_viewer === 1) {
                  if(auth.is_workspace_admin === 1) {
                    setTeamViewer(true);
                    ProjectDetails(project_id,true);
                  }
                  else {
                    setTeamViewer(false);
                    ProjectDetails(project_id, false);
                  }
                }
                else {
                  setTeamViewer(true);
                  ProjectDetails(project_id, true);
                }
            }
        })
        }
      } catch (err) {
        console.log(err.response);
        return err.response;
      }
    };

    const ProjectDetails = async (project_id, data) => {
        const config = {
            headers:{
                Authorization: `Bearer ${auth.token}`,
                "Content-Type": "application/json",
            },
        };
        let workspace_id = auth.workspace_id;
        let is_sole_teamviewer = data;
        const body = JSON.stringify({workspace_id, project_id, is_sole_teamviewer});
        let res = await axios.post("/api/project/get",body,config);
        if(res.status === 200) {
            setMemberList(res.data.project_members);
            setDisplayProject(res.data.project_details[0].project_name);
            setProjectName(res.data.project_details[0].project_name);
            setColor(res.data.project_details[0].project_color)
            setClientData({title:res.data.project_details[0].client_name,id:res.data.project_details[0].client_id})
            res.data.project_details[0].role === 1 ?setProjectManager(true) : setProjectManager(false);
            if(res.data.project_time[0].time_by_project !== null || res.data.project_time[0].time_by_project !== "" || res.data.project_time[0].time_by_project !== undefined) {
                const timerTime = res.data.project_time[0].time_by_project;
                let seconds = (timerTime / 1000).toFixed();
                // 2- Extract hours:
                let hours = parseInt( seconds / 3600); // 3,600 seconds in 1 hour
                if(parseInt(hours/10) === 0) {
                  hours = ("0" + hours);
                }
                seconds = seconds % 3600; // seconds remaining after extracting hours
                // 3- Extract minutes:
                let minutes = ("0" +parseInt( seconds / 60 )).slice(-2); // 60 seconds in 1 minute
                // 4- Keep only seconds not extracted to minutes:
                seconds = ("0" + (seconds % 60)).slice(-2);
                //return `${hours}:${minutes}:${seconds}`;
                setTime({hours:hours,minutes:minutes,seconds:seconds});
            }
            let tags = [];
            let tagTimes = [];
            let tagColors = [];
            res.data.tag_time.map((item) => {
              if(item.tag_id !== null && item.tag_id !== undefined && item.tag_id !== "") {
                tags.push(item.tag_title);
                tagTimes.push(item.time_by_tag);
                tagColors.push(item.tagcolor) 
              }
              else {
                tags.push("No Tag");
                tagTimes.push(item.time_by_tag);
                tagColors.push("#F5FFFA");
              }
            })
            setTagLabel(tags);
            setTagTime(tagTimes);
            setTagColor(tagColors);
        }
    }

    const sortMemberList = () => {
      let array = memberList;
      let ascendingArray = array.sort(function(a, b){
        let nameA=a.name.toLowerCase(), nameB=b.name.toLowerCase();
        if (nameA < nameB) //sort string ascending
         return -1;
        if (nameA > nameB)
         return 1;
        return 0; //default return value (no sorting)
       });
       if(sortMemberState === false) {
        setSortMemberState(true);
        setMemberList([...ascendingArray]);
       }
       else {
         setSortMemberState(false)
        let descendingArray =  ascendingArray.reverse();
        setMemberList([...descendingArray]);
       }
    }

    const getClientList = async () => {
        try {
          const config = {
            headers: {
              Authorization: `Bearer ${auth.token}`,
              "Content-Type": "application/json",
            },
        };
          let workspace_id = auth.workspace_id;
          const body = JSON.stringify({ workspace_id, searchTerm });
          let res = await axios.post("/api/client/search", body, config);
          if (res.status === 200) {
            setClientList(res.data.results);
          }
        } catch (err) {
          console.log(err);
          if (err.response) {
            console.log(err.response);
          }
        }
      };

    const getTeamList = async (project_id) => {
        try {
          const config = {
            headers: {
              Authorization: `Bearer ${auth.token}`,
              "Content-Type": "application/json",
            },
          };
          let workspace_id = auth.workspace_id;
          const body = JSON.stringify({ project_id, workspace_id });
          let res = await axios.post("/api/project/teamlist", body, config);
          if (res.status === 200) {
            setTeamList(res.data.results);
          }
        } catch (err) {
          if (err.response) {
            console.log(err.response);
          }
        }
      };

    const handleSearchTerm = (e) => {
        setSearchTerm(e.target.value);
    };

    const clientOnClick = (item) => {
        setClientData({title:item.client_name,id:item.id});
        setClientModal(false)
    }
    
    const handleProjectName = (e) => {
        setProjectName(e.target.value);
    };

    const validateForm = () => {
        let errors = {};
        let formIsValid = true;
        if (projectName === "") {
          formIsValid = false;
          errors["project_name"] = "Please enter the Project name";
        }
        setErrors(errors);
        return formIsValid;
      };

      const selectAllTeam = () => {
        setCheckArray([]);
        let rowState = [];
        teamList.map((item, index) => {
          rowState.push(item.user_id);
          setCheckArray(rowState);
        });
      }
  
      const selectNone = () => {
        setCheckArray([]);
      }

    const handleCheck = item => {
        let newArr = [];
        if (!checkArray.includes(item)) {
            newArr = [...checkArray, item];
        } else {
          newArr = checkArray.filter(a => a !== item);
        }
        setCheckArray(newArr); 
      };

    const addProjectMember = async () => {
        try {
            if (checkArray.length>0) {
              const config = {
                headers: {
                  Authorization: `Bearer ${auth.token}`,
                  "Content-Type": "application/json",
                },
              };
              let workspace_id = auth.workspace_id;
              let project_name = projectName;
              const body = JSON.stringify({ project_id , workspace_id,manager, checkArray, project_name });
              let res = await axios.post("/api/project_member/addmember", body, config);
              if (res.status === 200) {
                    setCheckArray([]);
                    ProjectDetails(project_id);
                    getTeamList(project_id);
                    setMemberModal(false);
              }
            }
          } catch (err) {
            console.log(err);
            if (err.response) {
              console.log(err.response);
            }
          }
    }

    const editProject = async () => {
        try {
          if (validateForm()) {
            const config = {
              headers: {
                Authorization: `Bearer ${auth.token}`,
                "Content-Type": "application/json",
              },
            };
            let workspace_id = auth.workspace_id;
            let project_name = projectName;
            let client_id;
            if (clientData.title === "No Client" && clientData.id === "") {
              client_id = "";
            } else {
              client_id = clientData.id;
            }
            let is_default_billable = 0;
            if(defaultBillable === true) {
              is_default_billable = 1;
            }
            const body = JSON.stringify({ project_id , is_default_billable, 
                project_name, workspace_id, client_id, project_color });
            let res = await axios.put("/api/project/edit", body, config);
            if (res.status === 200) {
                getWorkspaceInfo(project_id);
                setReload(reload  + 1);
                setSearchTerm("");
                setEditModal(false);
            }
          }
        } catch (err) {
          console.log(err);
          if (err.response) {
            console.log(err.response);
          }
        }
      };

    const handleMemberCheck = (pm_id) => {
      let newArr = [];
      if(!memberCheckArray.includes(pm_id)) {
        newArr = [...memberCheckArray,pm_id];
      }
      else {
        newArr = memberCheckArray.filter(a => a !== pm_id);
        }
        setMemberCheck(newArr);
      } 

    const handleMoreCheck = (pm_id) => {
      let newArr = [];
      if(!moreArray.includes(pm_id)) {
        newArr = [...moreArray,pm_id];
      }
      else {
        newArr = moreArray.filter(a => a !== pm_id);
      }
      setMoreArray(newArr);
    }
    
    const changeRole = async (item) => {

      try {
          const config = {
            headers: {
              Authorization: `Bearer ${auth.token}`,
              "Content-Type": "application/json",
            },
          };
          let workspace_id = auth.workspace_id;
          let role, project_member_id;
          if(item.role === 1) {
            role = 0;
          }
          else {
            role = 1;
          }

          project_member_id = item.pm_id;

          const body = JSON.stringify({ project_member_id ,role ,workspace_id });
          let res = await axios.put("/api/project_member/edit", body, config);
          if (res.status === 200) {
              ProjectDetails(project_id);
              getTeamList(project_id)
          }
        
      } catch (err) {
        console.log(err);
        if (err.response) {
          console.log(err.response);
        }
      }
    }

    const removeMember = async (pm_id) => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            "Content-Type": "application/json",
          },
        };
        let workspace_id = auth.workspace_id;
        let project_member_id = pm_id;

        const body = JSON.stringify({ project_member_id ,workspace_id });
        let res = await axios.post("/api/project_member/delete", body, config);
        if (res.status === 200) {
            ProjectDetails(project_id);
            getTeamList(project_id);
        }
      
    } catch (err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }
    } 

    const handleColor = (color) => {
      setColor(color);
    };

    const isDisabled = () => {
      if(teamList.length>0) {
        setMemberModal(true)      
      }
      else{
       dispatch(setAlert("Added all team members to this project","info"))
      }
    }

    const data = {
      labels: tagLabel,
      datasets: [
        {
          label: 'My First dataset',
          fill: false,
          lineTension: 0.1,
          backgroundColor: tagColor,//'rgba(75,192,192,0.4)',
          borderColor: "#EEF1EE",
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: tagTime
        }
      ]
    };

    return(
        <div>
          <CheckToken />
            <SideBar />
            <div class="header">
            <div class="container-fluid">
                <div class="row" style={{flexDirection: "column"}}>
                        <div class="reports-header-contain">
                          <h3><NavLink to='/projects'>Projects</NavLink></h3>&nbsp;
                          <h3 style={{color:project_color}}>{displayProjectName}</h3>  
                              <div class="reports-right-item-cont tags-right-header-item">
                               <div class="create-new-tag-btn">
                                  <button id="add-new-button" 
                                    onClick={()=>{
                                      setEditModal(true)}} 
                                      data-toggle="modal" 
                                      data-target="#clientModal"
                                      disabled={is_project_manager?false:true}
                                    >
                                      <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        width="20" 
                                        height="20" 
                                        viewBox="0 0 20 20"
                                      >
                                        <path 
                                          fill="#ffffff" 
                                          fillRule="evenodd" 
                                          d="M9 9V5.002a.999.999 0 1 1 2 0V9h3.998a.999.999 0 1 1 0 2H11v3.998a.999.999 0 1 1-2 0V11H5.002a.999.999 0 1 1 0-2H9z"></path></svg>
                                    <span>Edit project</span>
                                  </button>
                                  </div>
                              </div>
                        </div>
                        <div class="reports-filter-row filter-by-team-btn">
                          <div class="reports-header-contain" style={{padding:0, boxShadow:"unset"}}>
                        <div  onClick={()=>{setTab(1)}}
                          class="reports-header-items" 
                          style={{color:"#000",fontWeight:tab===1?"bold":"normal"}}
                        >
                          Team
                        </div>
                        &nbsp;&nbsp;
                        <div  class="reports-header-items" 
                          style={{color:"#000",fontWeight:tab===2?"bold":"normal"}} 
                          onClick={()=>{setTab(2)}}
                        >
                          Dashboard
                        </div>
                        &nbsp;&nbsp;
                        <div  onClick={()=>{setTab(3)}}
                          class="reports-header-items" 
                          style={{color:"#000",fontWeight:tab===3?"bold":"normal"}}
                        >
                          Reports
                        </div>
                    </div>
                </div>    
                </div>
            </div>
        </div>
        {tab === 1? 
        <div class="content-wrap" id="wrapper">
            <div class="main">
                    <div class="project-group-work-section team-member-list-wrapper">
                        <div class="working-group-area-contain"><Alert alerts='' />
                            <div class="group-main-inner-box">
                                <div class="group-inner-box-first-row">
                                    <div class="first-row-inner-box">
                                 
                                        <div class="group-rounding-btn-wrap" style={{marginLeft:"auto"}}>
                                           {/*<!--  <button  tabIndex="-1" type="button" class="rounding-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                <path fill="#ffffff" fillRule="evenodd" d="M9 9V5.002a.999.999 0 1 1 2 0V9h3.998a.999.999 0 1 1 0 2H11v3.998a.999.999 0 1 1-2 0V11H5.002a.999.999 0 1 1 0-2H9z">
                                                </path></svg><span>Add Member</span></button> -->*/}

                                        <div class="export-account-data-contain add-Member-btn">
                                        <a href='javascript:void(0)'>
                                            <button 
                                              onClick={()=>{isDisabled()}}
                                              id="add-new-button" 
                                              data-toggle="modal" 
                                              data-target="#addmember"
                                              disabled={is_project_manager?false:true}
                                              >
                                               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path fill="#ffffff" fillRule="evenodd" d="M9 9V5.002a.999.999 0 1 1 2 0V9h3.998a.999.999 0 1 1 0 2H11v3.998a.999.999 0 1 1-2 0V11H5.002a.999.999 0 1 1 0-2H9z"></path></svg>
                                                <span>Add Member</span>
                                        </button></a>
                                      </div>

                                        </div>

                                    </div>
                                </div>
                                {/*<!-- TOP FIRST ROW END HERE  -->*/}

                                {/*<!--============MIDDLE ROW/SECOND ROW START FROM HERE==== -->*/}
                                <div class="group-inner-box-second-row">
                                    <div class="group-box-second-inner">
                                        <div class="group-inner-main-wrap">
                                       
                                        <div class="group-by-title-wrap"><span>ALL MEMBERS/GROUPS</span>
                                                <div class="filter-arrow-cont">
                                                    <div class="arrow-top-dur">
                                                      <i class="fa fa-angle-up" aria-hidden="true"
                                                        onClick={()=>sortMemberList()}
                                                      ></i></div>
                                                    <div class="arrow-down-dur">
                                                      <i class="fa fa-angle-down" aria-hidden="true"
                                                        onClick={()=>sortMemberList()}
                                                      ></i></div>
                                                </div>
                                            </div>  
                                        </div>
                                        <div class="filter-by-duration-wrap">
                                            <div class="duration-inner"><span>Role</span>
                                                {/*<div class="filter-arrow-cont">
                                                    <div class="arrow-top-dur"><i class="fa fa-angle-up" aria-hidden="true"></i></div>
                                                    <div class="arrow-down-dur"><i class="fa fa-angle-down" aria-hidden="true"></i></div>
                                              </div>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*<!--============MIDDLE ROW/SECOND ROW END HERE -->*/}



                                {/*<!--============THIRD ROW GROUP BY PROJECT AND TIME ENTRY END HERE==== -->*/}

                                {/*<!--============THIRD ROW GROUP BY CLIENT AND TIME ENTRY START FORM HERE==== -->*/}
                                {memberList.map((item,index) => (
                                    <div class="group-by-user-main-container" key={index}>
                                    <div class="group-inner-box-second-row filter-by-project-last-row">
                                        <div class="group-box-second-inner filter-by-project">
                                            <div class="group-inner-main-wrap">
                                               {/*<!--  <button class="collape-data-btn" title="Expand report" type="button"> 3 </button> -->*/}
                                                <div class="group-by-title-wrap group-by-client-name">
                                                    <span class="user-short-name" 
                                                      style={{backgroundColor:"#"+item.profile_name_colorcode}}
                                                    >
                                                      {item.first_name.charAt(0)+item.last_name.charAt(0)}
                                                    </span>
                                                    <span class="project-client-name">
                                                      {item.name}
                                                    </span>
                                                </div> 
                                            </div>
                                            {is_project_manager?
                                            <div class="filter-by-duration-wrap">
                                              <div class={memberCheckArray.includes(item.pm_id)?
                                                    "project-team-role-btn dropdown show" : 
                                                    "project-team-role-btn dropdown"}>
                                              {item.role === 1?
                                              <div>
                                              <div class="member-role-btn dropdown-toggle" 
                                                  data-toggle="dropdown" 
                                                  style={{background:showRole === item.pm_id?"rgb(240, 240, 240)":"rgb(255, 255, 255)"}}
                                                  onMouseEnter={()=>setShowRole(item.pm_id)}
                                                  onMouseLeave={()=>setShowRole(0)} 
                                                  onClick={()=>{handleMemberCheck(item.pm_id)}}
                                                  aria-expanded="true">
                                                    {showRole === item.pm_id?"Change Role":"Manager"}
                                                </div>
                                                <div class={memberCheckArray.includes(item.pm_id)?
                                                      "dropdown-menu memberRole-rights-drop-wrap show":
                                                      "dropdown-menu memberRole-rights-drop-wrap"} 
                                                      x-placement="top-start" 
                                                      style={{position: "absolute", 
                                                        transform: "translate3d(0px, -67px, 0px)", top: "0px", left: "0px", 
                                                        willChange: "transform"}}
                                                >
                                                  <div class="revoke-right rights-list" onClick={()=>{changeRole(item)}}>
                                                    Revoke manager rights
                                                  </div>
                                                </div>
                                              </div>
                                                
                                               :
                                               <div>
                                              <div class="member-role-btn dropdown-toggle" 
                                                  data-toggle="dropdown" 
                                                  style={{background:showRole === item.pm_id?"rgb(240, 240, 240)":"rgb(255, 255, 255)"}}
                                                  onMouseEnter={()=>setShowRole(item.pm_id)}
                                                  onMouseLeave={()=>setShowRole(0)} 
                                                  onClick={()=>{handleMemberCheck(item.pm_id)}}
                                                  aria-expanded="true">{showRole === item.pm_id?"Add Role":"Member"}
                                                </div>
                                                <div class={memberCheckArray.includes(item.pm_id)?
                                                      "dropdown-menu memberRole-rights-drop-wrap show":
                                                      "dropdown-menu memberRole-rights-drop-wrap"} 
                                                      x-placement="top-start" 
                                                      style={{
                                                        position: "absolute", 
                                                        transform: "translate3d(0px, -67px, 0px)", 
                                                        top: "0px", 
                                                        left: "0px", 
                                                        willChange: "transform"}}>
                                                     <div class="revoke-right rights-list" 
                                                      onClick={()=>{changeRole(item)}}>
                                                       Give manager rights
                                                      </div>
                                                </div>
                                              </div>
                                               }
                                                   
                                                   
                                               </div>
                                            </div>: item.role ===1 ?<div >Manager</div>:<div>Member</div>}

                              {/*<!-- ================MORE OPTION BTN================ -->*/}

                          {is_project_manager?
                          <div class="member-role-row-more-btn-wrap" onClick={()=>handleMoreCheck(item.pm_id)}>
                            <div class="nav nav-pills nav-stacked in-team-edit-row-btn project-show-more-btn">
                            <div class={moreArray.includes(item.pm_id)?"dropdown show":"dropdown"} 
                              style={{marginLeft: "auto"}}>
                                <a class="dropdown-toggle" data-toggle="dropdown" href='javascript:void(0)' 
                                  aria-expanded={moreArray.includes(item.pm_id)?"true":"false"}>
                                    <div tabIndex="0" title="More actions" class="option-btn-show">
                                        <svg width="4" height="16" viewBox="0 0 4 16">
                                          <path fill="#cecece" fillRule="evenodd" d="M0 2a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm0 12a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm0-6a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"></path></svg>
                                    </div>
                                </a>
                                <div class={moreArray.includes(item.pm_id)?
                                  "dropdown-menu action_btns intimer-dot-option-box show":
                                  "dropdown-menu action_btns intimer-dot-option-box"} 
                                  x-placement="top-start" 
                                  style={{position: "absolute", transform:"translate3d(0px, -77px, 0px)", top: "0px", left: "0px", willChange: "transform"}}>
                                    <div class="delete option-items" style={{color: "rgb(226, 5, 5)"}}
                                      onClick={()=>removeMember(item.pm_id)}
                                    >
                                      <span>Remove from project</span></div>
                                </div>
                            </div>
                          </div>
                          </div>
                          :""}
                           {/*<!-- ================MORE OPTION BTN end================ -->*/}
                                        </div>
                                                    
                   
                                    </div>
                                </div>
                                ))}
                                
                                {/*<!-- filter group-by client container close here -->*/}
                            </div>
                        </div>

                        {/*<!-- HOUR WORKING METER SECTION START FROM HERE -->*/}
                        <div class="work-hour-meter-container">
                            
                            <div class="activity-clock-meter-wrap">
                                <div class="project-overview-content">
                                    <h4>Project Overview</h4>
                                </div>
                                <div class="meter-title-wrap">
                                    <span>Clocked hours</span>
                                </div>
                                <div class="meter-count-cont">
                                    <span>
                                        <span class="meter-hour" style={{color: "rgb(163, 163, 163)"}}>{time.hours}:</span>
                                        <span class="meter-minute" style={{color: "rgb(0, 0, 0)"}}>{time.minutes}</span>
                                        <span class="meter-sec" style={{color: "rgb(163, 163, 163)"}}>:{time.seconds}</span>
                                    </span>
                                </div>
                            </div>
                            
                        </div>
                        {/*<!-- HOUR WORKING METER SECTION END HERE -->*/}
                    </div>
                    {is_project_manager?
                    <div style={{marginLeft:"75%"}}>{
                      tagTime.length>0?
                      <Doughnut 
                        ref={chart} 
                        data={data} 
                        width={200}
                        options={{
                          legend:false,
                          title: {
                            display:true,
                            text:"Time in Hours"
                          }
                        }} 
                      />:""}
                    </div>
                    :""}
                    
                    


    </div>
    </div>
        :tab === 2? 
        <Suspense fallback={
          <Loader />
        }>
          <Dashboard project_id={project_id} 
            is_sole_team_viewer={is_sole_team_viewer === false?(is_project_manager?true:false):true}/>
        </Suspense>
         :
         <Suspense fallback={
           <Loader />
         }>
          <ProjectReport 
            project_id={project_id}
            manager={is_project_manager}
            is_sole_team_viewer={is_sole_team_viewer === false?(is_project_manager?true:false):true}
            reload={reload}
          />
         </Suspense> 
        }


<Modal
        show={editModalOpen}
        onHide={()=>{setEditModal(false)}}
        dialogClassName='add-New-tag-popup-box-container'
        bsPrefix=''
      >
        <div class="popup-title">
            <h3>Edit Project</h3>
        </div>
        <div class="top-lebel-wrap">
          <span class="label-text">Name</span>
        </div>
        <div class="popup-inner-input-field " style={{margin: "10px 0px 7px"}}>
            <input onChange={handleProjectName}
                type="text" placeholder="Project Name....." name="" value={projectName}/>
                <span>{errors.project_name}</span>
        </div>
        <div class="top-lebel-wrap">
          <span class="label-text">Client</span>
        </div>

       <div class="input-field-width-color-wrap" >
        <div class={clientModalOpen?"popup-select-workspace show":"popup-select-workspace"} 
            style={{flex:"10 0 0px", marginBottom:"6px", marginTop:"4px"}} 
            onClick={()=>{setClientModal(true)}}>
        <div 
            class="project-popup-choose-workspace data-toggle"   
            data-toggle="dropdown" 
            aria-expanded={clientModalOpen?"true":"false"}>
        <span class="workspace-info">{clientData.id === 0?"No Client":clientData.title}</span>
          <i class="fa fa-angle-up"></i>
        </div>
      

          <div class={clientModalOpen?"dropdown-menu filter-by-button-dropdown-wrap show":
                "dropdown-menu filter-by-button-dropdown-wrap"}
                x-placement="top-start" 
                style={{
                    position: "absolute", 
                    transform: "translate3d(20px, -39px, 0px)", 
                    top: "0px", left: "0px", willChange: "transform"}}>    
            <div class="Teamdropdown_container">
            <div class="filter-search-field-wrap">
            <div class="team-search-contain">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
            <path fill="#cecece" d="M13.9,12.8L10.2,9c0.7-0.9,1.2-2.2,1.2-3.4C11.3,2.5,8.8,0,5.7,0C2.6,0,0,2.6,0,5.7c0,3.2,2.5,5.7,5.7,5.7 c1.2,0,2.3-0.4,3.4-1.2l3.6,3.6C12.8,14,12.9,14,13,14s0.1,0,0.2-0.1l0.8-0.8C14,13.1,14,12.9,13.9,12.8z M9.8,5.7 c0,2.2-1.8,4-4.1,4s-4.1-1.8-4.1-4c0-2.2,1.9-4.1,4.1-4.1C8,1.7,9.8,3.5,9.8,5.7z"></path>
            </svg>
            <input 
                type="text" 
                onChange={handleSearchTerm} 
                value={searchTerm} 
                placeholder="Find client..." 
                class="team-search-field" 
                onKeyUp={() => getClientList()}/>
            </div>
            </div>
            <div class="workispace-porject-list-contain">
                <div class="none-client-contain">
                    <span>No client</span>
                </div>
                <ul class="workspace-select-list client-list-in-popup">
                    {clientList.map((item,index) => (
                        <li onClick={()=> clientOnClick(item)} key={index}><a href='javascript:void(0)'>{item.client_name}</a></li>
                    ))}
                </ul>
            </div>
            {/*<div class="create-project-in-serchbox">
            <a href='javascript:void(0)'><i class="fa fa-plus" aria-hidden="true"></i>New Workspace</a>
           </div>*/}
            </div>
            </div>{/*<!-- dropdown end here -->*/}

    </div>
    <div style={{paddingTop:"6px"}}>
      <ColorPicker onSelectColor={(color) => handleColor(color)} 
        dbColor={project_color}/>
    </div>
                    </div>



        {/*<!-- ====================FIND CLIENT SELECTER  END HERE=======-->*/}

                {/*<!-- ====================PROJECT PRIVATE START FORM HERE==================== -->*/}
        {/*<div class="popup-select-workspace">
          <div class="project-popup-choose-workspace">
            <span class="workspace-info">
              <label style={{marginBottom: "0px"}}>Private project</label>
              <span class="info-about-project" data-toggle="tooltip" data-placement="top" title="Tooltip on top">
                <i class="fa fa-info-circle" aria-hidden="true"></i>
              </span>
            </span>
         
            <div class="toggle-switch">
              <input type="checkbox" id="private"/>
              <label htmlFor="private" style={{marginBottom: "0px", marginTop:"15px"}}>
                <span class="toggle-track"></span>
              </label>
            </div>
          </div>
        </div>*/}
        {/*<!-- ====================PROJECT PRIVATE END HERE=======-->*/}
        <div class="create-tag-name-btn popup-full-btn">
            <button type="submit" class="popup-button" onClick={()=>editProject()}><span>Save</span></button>

        </div>

      </Modal>

                            {/**Add member modal */}
      <Modal
        show={memberModalOpen}
        onHide={()=>{setMemberModal(false)}}
        dialogClassName='add-New-tag-popup-box-container'
        bsPrefix=''
        backdrop="static"
      >
        <Modal.Header closeButton>        
          <div class="popup-title">
            <h3>Add Project Members</h3>
          </div>
        </Modal.Header>

        
         {/*<!-- ====================SELECT TEAM MEMBER OR GROUP START FORM HERE==================== -->*/}
         <div class={teamModalOpen?"popup-select-workspace show":"popup-select-workspace"} 
        onClick={()=>{setTeamModal(true)}}
        style={{flex:"10 0 0px", marginBottom:"6px"}}>
        <div class="project-popup-choose-workspace data-toggle" data-toggle="dropdown">
        <span class="workspace-info">Select Team Member or Group</span>
          <i class="fa fa-angle-up"></i>
        </div>

        <div class={teamModalOpen?
            "dropdown-menu filter-by-button-dropdown-wrap show":
            "dropdown-menu filter-by-button-dropdown-wrap"} x-placement="top-start" 
            style={{position: "absolute", 
              transform: "translate3d(60px, 100px, 0px)", top: "0px", left: "0px", willChange: "transform"}}>
                            <div class="Teamdropdown_container">
                            <div class="filter-search-field-wrap">
                            <div class="team-search-contain">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                            <path fill="#cecece" d="M13.9,12.8L10.2,9c0.7-0.9,1.2-2.2,1.2-3.4C11.3,2.5,8.8,0,5.7,0C2.6,0,0,2.6,0,5.7c0,3.2,2.5,5.7,5.7,5.7 c1.2,0,2.3-0.4,3.4-1.2l3.6,3.6C12.8,14,12.9,14,13,14s0.1,0,0.2-0.1l0.8-0.8C14,13.1,14,12.9,13.9,12.8z M9.8,5.7 c0,2.2-1.8,4-4.1,4s-4.1-1.8-4.1-4c0-2.2,1.9-4.1,4.1-4.1C8,1.7,9.8,3.5,9.8,5.7z"></path>
                            </svg>
                            <input type="text" tabIndex="4" placeholder="Find members or groups..." class="team-search-field" value=""/>
                            </div>
                            </div>
                            <div class="team-list-contain-wrap">
                            <div class="team-list-inner-box">
                            <div class="virtualized__List">
                           <div class="top-lebel-wrap">
                              <span class="label-text">Invited Members</span>
                                <div>
                                  <a tabIndex="-1" onClick={()=> selectAllTeam()}>
                                  <span>All</span>
                                  </a>
                                  <a tabIndex="-1" onClick={()=>selectNone()}><span>None</span></a></div>
                            </div>

                        {/*<!-- =======MEMBERS LABEL===== -->*/}
                        {teamList.map((item,index) => (
                            <div key={index} class="inner-scrollar-container" role="rowgroup" style={{overflow: "hidden", position: "relative"}}>
                            <div class="filter-by-check-wrap" onClick={()=>handleCheck(item.user_id)}>
                                <div class="custom-checkbox-wrap">
                                <label><span class={checkArray.includes(item.user_id)?"custom-checkboxes selected":"custom-checkboxes"}>
                                    <input 
                                        
                                        type="checkbox" 
                                        name="filter-item[]"/></span></label>
                                </div>
                                <div class="team-name-filter-by">
                                <div title="webteam">{item.first_name} {item.last_name}</div>
                                </div>
                            </div>
                        </div>
                        ))}
                        
                         {/*<!-- =======MEMBERS LABEL End here===== -->*/}

                    </div>
                </div>
             </div>
          
            </div>
            </div>
    </div>

                    {/*<!-- ====================PROJECT PRIVATE START FORM HERE==================== -->*/}
                    <div class="popup-select-workspace">
        <div class="project-popup-choose-workspace">
        <span class="workspace-info">
            <label style={{marginBottom: "0px"}}>Manager rights</label>
        </span>
         
          <div class="toggle-switch">
            <input type="checkbox" onClick={()=>setManager(!manager)} id="private2"/>
            <label htmlFor="private2" style={{marginBottom: "0px", marginTop:"15px"}}>
            <span class="toggle-track"></span>
            </label>
        </div>
        </div>
        </div>
        {/*<!-- ====================PROJECT PRIVATE END HERE=======-->*/}

        
        <div class="create-tag-name-btn popup-full-btn" style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <button onClick={()=>addProjectMember()} type="submit" class="popup-button" style={{ margin: "0px 0px 0px 10px"}}>
                <span>Save</span>
            </button>
        </div>

      </Modal>

        </div>
    )
} 