import React,{lazy, Suspense} from "react";
import Sidebar from "../common/sidebar";
import ClientModal from "../client/ClientModal";
//import ClientList from "../client/ClientList";
import CheckToken from "../common/check_token";
import Loader from "../common/loader";
import Footer from "../common/footer";
const ClientList = lazy(()=> import("../client/ClientList"));

class Clients extends React.Component {
  constructor(props) {
    super();
    this.state = {
      open: false,
      reload: 0,
      clientTerm : ""
    };
  }
  showModal = () => {
    this.setState({ open: true });
  };
  hideModal = () => {
    this.setState({ open: false });
  };
  reload = () => {
    this.setState({ reload: this.state.reload + 1 });
  };

  handleSearchTerm = (e) => {
    this.setState({clientTerm:e.target.value});
  };

  render() {
    return (
      <div>
                <CheckToken />
        <Sidebar />
        <div class='header'>
          <div class='container-fluid'>
            <div class='row' style={{ flexDirection: "column" }}>
              <div class='reports-header-contain'>
                <h3>Clients</h3>
                <div class='find-tag-srch-field-headr'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='14'
                    height='14'
                    viewBox='0 0 14 14'
                  >
                    <path
                      fill='#cecece'
                      d='M13.9,12.8L10.2,9c0.7-0.9,1.2-2.2,1.2-3.4C11.3,2.5,8.8,0,5.7,0C2.6,0,0,2.6,0,5.7c0,3.2,2.5,5.7,5.7,5.7 c1.2,0,2.3-0.4,3.4-1.2l3.6,3.6C12.8,14,12.9,14,13,14s0.1,0,0.2-0.1l0.8-0.8C14,13.1,14,12.9,13.9,12.8z M9.8,5.7 c0,2.2-1.8,4-4.1,4s-4.1-1.8-4.1-4c0-2.2,1.9-4.1,4.1-4.1C8,1.7,9.8,3.5,9.8,5.7z'
                    ></path>
                  </svg>
                  <input
                    type='text'
                    tabindex='0'
                    placeholder='Find Client...'
                    class='find-tag-search'
                    onChange={this.handleSearchTerm}
                    value={this.state.clientTerm}
                    onKeyUp={this.reload}
                  />
                </div>

                <div class='reports-right-item-cont tags-right-header-item'>
                  <div class='create-new-tag-btn'>
                    <button
                      id='add-new-button'
                      onClick={this.showModal}
                      data-toggle='modal'
                      data-target='#clientModal'
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='20'
                        height='20'
                        viewBox='0 0 20 20'
                      >
                        <path
                          fill='#ffffff'
                          fill-rule='evenodd'
                          d='M9 9V5.002a.999.999 0 1 1 2 0V9h3.998a.999.999 0 1 1 0 2H11v3.998a.999.999 0 1 1-2 0V11H5.002a.999.999 0 1 1 0-2H9z'
                        ></path>
                      </svg>
                      <span>New client</span>
                    </button>
                  </div>
                </div>
              </div>

              <ClientModal
                show={this.state.open}
                handleClose={this.hideModal}
                reloadList={this.reload}
              />
            </div>
          </div>
        </div>
        <Suspense fallback={
          <Loader />
        }>
          <ClientList reload={this.state.reload} searchTerm={this.state.clientTerm}/>
        </Suspense>
        <Footer />
      </div>
    );
  }
}

export default Clients;
