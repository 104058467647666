import { combineReducers } from "redux";
import auth from "./auth";
import alert from "./alert";
import invite_ids from "./invite_ids";
import {  LOGOUT } from "../actions/types";
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
const appReducer = combineReducers({
  auth,
  alert,
  invite_ids,
});
const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    storage.removeItem('persist:root')
      state = undefined;
  }  
  return appReducer(state, action)
} 
export default rootReducer;
