import React from "react";
import moment from "moment";

export const alterDisplayTime = (value,time_duration_format) => {
    function number_test(n) {
      var result = (n - Math.floor(n)) !== 0; 
      
      if (result)
        return true;
      else
        return false;
    }
    if(time_duration_format === "Classic") {
      let arr = value.split(":");
      //console.log(arr)
      if(arr[0] === "00") {
        if(arr[1] === "00") {
          let time = moment.duration(value).asSeconds();
          if(number_test(time)) {
            return `${time.toFixed(2)} sec`;
          }
          else {
            return `${time} sec`;
          }
        }
        else {
          let time = moment.duration(value).asMinutes();
          if(number_test(time)) {
            return `${time.toFixed(2)} min`;
          }
          else {
            return `${time} min`;
          }
        }
      }
      else {
        return value;
      }
    }
    else if(time_duration_format === "Improved") {
      return value;
    }
    else if(time_duration_format === "Decimal") {
      let time = `${moment.duration(value).asHours()
    .toFixed(2)} h`;
    return time;
    }
}

export const alterTimeFormat = ( timeformat) => {
    if(timeformat === "12-hour") {
        return "hh:mm A";
    }
    else {
        return "HH:mm";
    }
}