import React, { useState, useEffect } from "react";
import "../../assets/css/modal.css";
import { useSelector } from "react-redux";
import axios from "axios";
import {Modal} from "react-bootstrap";
import CheckToken from "../common/check_token";


function TeamModal({ handleClose, show, reloadList, data }) {
  const showHideClassName = show ? "modal display-block" : "modal display-none";
  const auth = useSelector((state) => state.auth);
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [name, setName] = useState('');

  useEffect(() => {
    if (data !== null && data !== "" && data !== undefined) {
    }
  }, []);
  const emailChange = (e) => {
    setEmail(e.target.value);
  };
  const validateForm = () => {
    let errors = {};
    let formIsValid = true;
    if (email === "") {
        formIsValid = false;
        errors["email"] = "Please enter your email address.";
      }
  
      if (email !== "") {
        let pattern = new RegExp(
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (!pattern.test(email)) {
          formIsValid = false;
          errors["email"] = "Please enter valid email address.";
        }
      }
    setErrors(errors);
    return formIsValid;
  };
  const handleSubmit = async () => {
      try{
          if(validateForm()) {
                const config = {
                    headers: {
                    Authorization: `Bearer ${auth.token}`,
                    "Content-Type": "application/json",
                    },
                };
                let workspace_id = auth.workspace_id;
                  const body = JSON.stringify({ email,name,workspace_id });
                  let res = await axios.post("/api/team/sendemail",body,config);
                  if(res.data.msg === 'success') {
                    setEmail("");
                    handleClose();
                    reloadList();
                  }
                  else if(res.data.user === true){
                    let errors = {};
                    errors["email"] = res.data.msg;
                    setErrors(errors);
                  }
                }
                
          }
      catch(err) {
          console.log(err)
      }
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName='add-New-tag-popup-box-container'
        bsPrefix=''
      >
        <div class='popup-title'>
          <h3>Invite Members</h3>
        </div>
        <div class='popup-inner-input-field'>
          <input
            type='text'
            placeholder='Add Email'
            name='email'
            onChange={emailChange}
            value={email}
          />              
          <span>{errors.email}</span>
        </div>
        <div class='create-tag-name-btn popup-full-btn'>
          <button class='popup-button' type='submit' onClick={handleSubmit}>
            <span>Invite</span>
          </button>
        </div>
      </Modal>
    </>
  );
}
export default TeamModal;
