import React, { useState, useEffect } from "react";
import "../../assets/css/modal.css";
import { useSelector } from "react-redux";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";

function ClientModal({ handleClose, show, reloadList, data }) {
  const auth = useSelector((state) => state.auth);
  const [client_name, setClient] = useState("");
  const [company_name, setCompany] = useState("");
  const [client_id, setClientid] = useState("");
  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (data !== null && data !== "" && data !== undefined) {
      setClient(data.client_name);
      setCompany(data.company_name);
      setClientid(data.id);
    }
  }, []);
  const clientChange = (e) => {
    setClient(e.target.value);
  };
  const companyChange = (e) => {
    setCompany(e.target.value);
  };
  const validateForm = () => {
    let errors = {};
    let formIsValid = true;
    if (client_name === "") {
      formIsValid = false;
      errors["client_name"] = "Please enter the client name";
    }
    if (company_name === "") {
      formIsValid = false;
      errors["company_name"] = "Please enter the company name";
    }
    setErrors(errors);
    return formIsValid;
  };
  const handleSubmit = async () => {
    try {
      let workspace_id = auth.workspace_id;
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      };
      const body =
        client_id !== ""
          ? JSON.stringify({
              client_id,
              client_name,
              company_name,
              workspace_id,
            })
          : JSON.stringify({
              client_name,
              company_name,
              workspace_id,
            });
      if (client_id !== "") {
        if (validateForm()) {
          let res = await axios.put("/api/client/edit", body, config);
          if (res.status === 200) {
            handleClose();
            setClient("");
            setCompany("");
            reloadList();
          }
        }
      } else {
        if (validateForm()) {
          let res = await axios.post("/api/client/add", body, config);
          if (res.status === 200) {
            handleClose();
            setClient("");
            setCompany("");
            reloadList();
          }
        }
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName='add-New-tag-popup-box-container'
        bsPrefix=''
      >
        <div class='popup-title'>
          <h3>{client_id !== "" ? "Update" : "New"} Client</h3>
        </div>
        <div class='popup-inner-input-field'>
              <input
                type='text'
                placeholder='Client Name'
                name='client'
                onChange={clientChange}
                value={client_name}
              />
              <span>{errors.client_name}</span>
              <input
                type='text'
                placeholder='Company Name.....'
                name='comapny'
                onChange={companyChange}
                value={company_name}
              />
              <span>{errors.company_name}</span>
            </div>
            <div class='create-tag-name-btn popup-full-btn'>
              <button class='popup-button' type='submit' onClick={handleSubmit}>
                <span>{client_id !== "" ? "Update" : "Create"}</span>
              </button>
            </div>
      </Modal>
    </>
  );
}
export default ClientModal;
