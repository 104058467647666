import React from "react";
import Spinner from 'react-bootstrap/Spinner';

  const Loader = () => {
      return (
    <div style={{position: "fixed",
    top: "50%",
    left: "50%",
    /* bring your own prefixes */
    transform: "translate(-50%, -50%)",display:"flex"}}>
    <Spinner animation="border" role="status" size="lg" />
  </div>)
}; 
export default Loader;