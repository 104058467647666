import axios from "axios";
import { setAlert } from "./alert";

import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
} from "./types";
import { removeInvite } from "./invite_ids";

// Register User
export const register = ({ first_name, last_name, email, password, workspace_id, invite_id }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ first_name, last_name, email, password, workspace_id, invite_id });
  try {
    const res = await axios.post("/api/register", body, config);
    if(res.status === 200) {
      if(res.data.msg === "success") {
        return 1;
      }
      else {
        dispatch(removeInvite());
        /*dispatch({
          type: REGISTER_SUCCESS,
          payload: res.data,
        });*/
        dispatch(loadUser(res));
      }
    }

  } catch (err) {
    const errors = err.response.data.errors;
    console.log(errors);
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

// login user
export const login = ({email,password, workspace_id, invite_id}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ email, password, workspace_id, invite_id});
  try {
    //console.log(body)
    const res = await axios.post("/api/auth", body, config);
    if(res.status === 200) {
      dispatch(removeInvite())
      /*dispatch({
        type:LOGIN_SUCCESS,
        payload: res.data 
      });*/
      dispatch(loadUser(res));
    }
    
  }
  catch(err) {
    
    const errors = err.response.data.errors;
    if(errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type:LOGIN_FAIL
    });
  }
};


// Load User
export const loadUser = (res) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const logoutUser = () => async(dispatch) => {
    dispatch({type:LOGOUT});
}