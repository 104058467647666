import React, { useState, useEffect } from "react";
import "../../assets/css/modal.css";
import { useSelector } from "react-redux";
import ColorPicker from "../common/ColorPicker";
import axios from "axios";
import {Modal} from "react-bootstrap";

const workspaceStyleDisplay = {
    position: "absolute",
    transform: "translate3d(20px, -44px, 0px)",
    top: "0px",
    left: "0px",
    willChange: "transform",
    display: "block",
    minWidth: "25em",
};
const workspaceStyleHide = { display: "none" };

const clientStyleDisplay = {
    position: "absolute",
    transform: "translate3d(20px, -44px, 0px)",
    top: "0px",
    left: "0px",
    willChange: "transform",
    display: "block",
    minWidth: "25em",
};

const clientStyleHide = { display: "none" };

function ProjectModal({ handleClose, show, reloadList, setProjectInDashBoard }) {
    const showHideClassName = show ? "modal display-block" : "modal display-none";
    const auth = useSelector((state) => state.auth);
    const [openClient, setClient] = useState(false);
    const [project_name, setProjectName] = useState("");
    const [workspaceData, setWorkspaceData] = useState({ title: "", id: "" });
    const [clientData, setClientData] = useState({ title: "No Client", id: "" });
    const [searchTerm, setSearchTerm] = useState("");
    const [openworkspace, setWorkspace] = useState(false);
    const [errors, setErrors] = useState({});
    const [project_color, setColor] = useState("#000000");
    const [workspaceList, setWorkspaceList] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [defaultBillable, setDefaultBillable] = useState(false);

    useEffect(() => {

        document.addEventListener(
            "click",
            () => {
              setWorkspace(false);
              setClient(false);
              setSearchTerm("");
              //getClientList();
            },
            true
          );
          getClientList();
          getWorkspaceList();
    }, []);

    const getWorkspaceList = async () => {
        const config = {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            "Content-Type": "application/json",
          },
        };
        let workspace_id = auth.workspace_id;
        const body = JSON.stringify({ workspace_id });
        let res = await axios.post(
          "/api/workspace/listbyworkspaceid",
          body,
          config
        );
        if (res.status === 200) {
          res.data.results.map((item) => {
            if (item.id === workspace_id) {
              if(item.is_default_billable === 1) {
                setDefaultBillable(true);
              }
              
              setWorkspaceData({
                title: item.title,
                id: item.id,
              });
            }
          });
          setWorkspaceList(res.data.results);
        }
    };

    const validateForm = () => {
        let errors = {};
        let formIsValid = true;
        if (project_name === "") {
          formIsValid = false;
          errors["project_name"] = "Please enter the Project name";
        }
        setErrors(errors);
        return formIsValid;
    };

    const createProject = async () => {
        try {
          if (validateForm()) {
            const config = {
              headers: {
                Authorization: `Bearer ${auth.token}`,
                "Content-Type": "application/json",
              },
            };
            let workspace_id = workspaceData.id;
            let client_id;
            if (clientData.title === "No Client" && clientData.id === "") {
              client_id = "";
            } else {
              client_id = clientData.id;
            }
            let is_default_billable = 0;
            if(defaultBillable === true) {
              is_default_billable = 1;
            }
            const body = JSON.stringify({
              project_name,
              workspace_id,
              client_id,
              project_color,
              is_default_billable
            });
            let res = await axios.post("/api/project/add", body, config);
            if (res.status === 200) {
              let item = {
                client_name: clientData.id !== null && 
                              clientData.id !== undefined &&
                              clientData.id !== ""?clientData.title:"",
                client_id: clientData.id,
                project_name: project_name,
                id: res.data.results.insertId,
                workspace_id : workspace_id
              }
              
              setSearchTerm("");
              setProjectName("");
              setClientData({ title: "No Client", id: "" });
              await reloadList(item);
              handleClose();
              //setProjectInDashBoard(item);
            }
          }
        } catch (err) {
          console.log(err);
          if (err.response) {
            console.log(err.response);
          }
        }
    };

    const handleClient = (item) => {
        setClientData({ title: item.client_name, id: item.id });
        setClient(false);
    };

    const handleSearchTerm = (e) => {
        setSearchTerm(e.target.value);
    };
    const getClientList = async (wid) => {
        try {
          const config = {
            headers: {
              Authorization: `Bearer ${auth.token}`,
              "Content-Type": "application/json",
            },
          };
          let workspace_id = wid !== null &&
          wid !== undefined? wid : auth.workspace_id;
          const body = JSON.stringify({ workspace_id, searchTerm });
          //console.log(body)
          let res = await axios.post("/api/client/search", body, config);
          if (res.status === 200) {
            //console.log(res.data.results)
            setClientList(res.data.results);
          }
        } catch (err) {
          console.log(err);
          if (err.response) {
            console.log(err.response);
          }
        }
    };
    
    const handleNoClient = () => {
        setClientData({ title: "No Client", id: "" });
        setClient(false);
    };

    const handleWorkspace = (item) => {
        handleNoClient();
        setWorkspaceData({ title: item.title, id: item.id });
        setWorkspace(false);
        getClientList(item.id);
    };

    const handleProjectName = (e) => {
        setProjectName(e.target.value);
    };

    const handleColor = (color) => {
        setColor(color);
    };

    return (
    <>
    <Modal
        show={show}
        onHide={handleClose}
        dialogClassName='add-New-tag-popup-box-container'
        bsPrefix=''
      >
        <div class='popup-title'>
          <h3>New Project</h3>
        </div>
        <div>
          <div class='popup-inner-input-field'>
              <div style={{ display: "flex" }}>
              <input
                type='text'
                placeholder='Project Name'
                name=''
                onChange={handleProjectName}
                value={project_name}
              />
              <ColorPicker
                onSelectColor={(color) => handleColor(color)}
                dbColor={project_color}
              />
              </div>

              <span>{errors.project_name}</span>
            </div>
          <div class='popup-select-workspace'>
            <div
              class='project-popup-choose-workspace data-toggle'
              data-toggle='dropdown'
              onClick={() => {
                setWorkspace(!openworkspace);
              }}
            >
              <span class='workspace-info'>{workspaceData.title}</span>
              <i class='fa fa-angle-up'></i>
            </div>
            <div
              class='dropdown-menu filter-by-button-dropdown-wrap'
              style={openworkspace ? workspaceStyleDisplay : workspaceStyleHide}
            >
              <div class='Teamdropdown_container'>
                <div class='filter-search-field-wrap'>
                  <div class='team-search-contain'>
                    <svg
                      height='14'
                      viewBox='0 0 14 14'
                      width='14'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M13.9,12.8L10.2,9c0.7-0.9,1.2-2.2,1.2-3.4C11.3,2.5,8.8,0,5.7,0C2.6,0,0,2.6,0,5.7c0,3.2,2.5,5.7,5.7,5.7 c1.2,0,2.3-0.4,3.4-1.2l3.6,3.6C12.8,14,12.9,14,13,14s0.1,0,0.2-0.1l0.8-0.8C14,13.1,14,12.9,13.9,12.8z M9.8,5.7 c0,2.2-1.8,4-4.1,4s-4.1-1.8-4.1-4c0-2.2,1.9-4.1,4.1-4.1C8,1.7,9.8,3.5,9.8,5.7z'
                        fill='#cecece'
                      ></path>
                    </svg>
                    <input
                      class='team-search-field'
                      placeholder='Find workspace...'
                      type='text'
                      value=''
                    />
                  </div>
                </div>
                <div class='workispace-porject-list-contain'>
                  <ul class='workspace-select-list'>
                    {/**<li class='selected-workspace'> */}
                    {workspaceList.map((item, index) => (
                      <li key={index} onClick={() => handleWorkspace(item)}>
                        <span>{item.title}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class='popup-select-workspace'>
            <div
              class='project-popup-choose-workspace data-toggle'
              data-toggle='dropdown'
              onClick={() => {
                setClient(!openClient);
              }}
            >
              <span class='workspace-info'>{clientData.title}</span>
              <i class='fa fa-angle-up'></i>
            </div>
            <div
              class='dropdown-menu filter-by-button-dropdown-wrap'
              style={openClient ? clientStyleDisplay : clientStyleHide}
            >
              <div class='Teamdropdown_container'>
                <div class='filter-search-field-wrap'>
                  <div class='team-search-contain'>
                    <svg
                      height='14'
                      viewBox='0 0 14 14'
                      width='14'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M13.9,12.8L10.2,9c0.7-0.9,1.2-2.2,1.2-3.4C11.3,2.5,8.8,0,5.7,0C2.6,0,0,2.6,0,5.7c0,3.2,2.5,5.7,5.7,5.7 c1.2,0,2.3-0.4,3.4-1.2l3.6,3.6C12.8,14,12.9,14,13,14s0.1,0,0.2-0.1l0.8-0.8C14,13.1,14,12.9,13.9,12.8z M9.8,5.7 c0,2.2-1.8,4-4.1,4s-4.1-1.8-4.1-4c0-2.2,1.9-4.1,4.1-4.1C8,1.7,9.8,3.5,9.8,5.7z'
                        fill='#cecece'
                      ></path>
                    </svg>
                    <input
                      class='team-search-field'
                      placeholder='Find client...'
                      type='text'
                      value={searchTerm}
                      onChange={handleSearchTerm}
                      onKeyUp={() => getClientList()}
                    />
                  </div>
                </div>
                <div class='workispace-porject-list-contain'>
                  <ul class='workspace-select-list'>
                    {/**<li class='selected-workspace'> */}
                    <li onClick={() => handleNoClient()}>No Cllient</li>
                    {clientList.map((item, index) => (
                      <li key={index} onClick={() => handleClient(item)}>
                        <span>{item.client_name}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/*<div class='popup-select-workspace'>
            <div class='project-popup-choose-workspace'>
              <span class='workspace-info'>
                <label style={{ marginBottom: "0px" }}>Private project</label>
                <span
                  class='info-about-project'
                  data-toggle='tooltip'
                  data-placement='top'
                  title='Tooltip on top'
                >
                  <i class='fa fa-info-circle' aria-hidden='true'></i>
                </span>
              </span>

              <div class='toggle-switch'>
                <input type='checkbox' id='private' />
                <label
                  for='private'
                  style={{ marginBottom: "0px", marginTop: "15px" }}
                >
                  <span class='toggle-track'></span>
                </label>
              </div>
            </div>
          </div>*/}

          <div class='create-tag-name-btn popup-full-btn'>
            <button
              type='submit'
              class='popup-button'
              onClick={() => createProject()}
            >
              <span>Create</span>
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
export default ProjectModal;
