import React from "react";

export default function DeleteButton() {
  return (
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width="14" 
        height="18"
        viewBox='0 0 12 12'
    >
        <g 
            fill="none" 
            fill-rule="evenodd" 
            transform="translate(0 1)"
        >
            <rect 
                width="10" 
                height="2" 
                y="1" 
                fill="#CECECE" 
                rx="1"
            />
            <path 
                fill="#CECECE" 
                d="M1 4h8l-.358 5.013C8.564 10.11 7.613 11 6.494 11H3.506c-1.108 0-2.07-.887-2.148-1.987L1 4z"
            />
            <path 
                stroke="#CECECE" 
                stroke-linecap="round" 
                stroke-linejoin="round" 
                stroke-width="2" 
                d="M3.5 2L3.879.484A.664.664 0 0 1 4.505 0h.99c.279 0 .559.214.626.484L6.5 2"
            />
        </g>
    </svg>
  );
}
