import React from "react";

export default function ArrowButton() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12px'
      height='8'
      viewBox='0 0 12 8'
      version='1'
    >
      <g fill='#cecece' fillRule='evenodd'>
        <rect x='0' y='3' width='7px' height='2px'></rect>
        <polygon points='7 8, 7 0, 12 4'></polygon>
      </g>
    </svg>
  );
}
