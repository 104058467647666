import React, { useEffect, useState } from "react";
import { useSelector, useStore } from "react-redux";
import axios from "axios";
import { Modal } from "react-bootstrap";
import CheckToken from "../common/check_token";
import Footer from "../common/footer";

export default function TeamList(props) {
  const auth = useSelector((state) => state.auth);

  const [teamList, setTeamList] = useState([]);
  const [clickedArray, setClickedArray] = useState([]);
  const [team_id, setTeamId] = useState(0);
  const [editOpen, setEditOpen] = useState(false);
  const [memberName, setMemberName] = useState("");
  const [memberEmail, setMemeberEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [billable_rate, setBill] = useState(0);
  const [labor_cost, setLabor] = useState(0);
  const [user_id, setUserId] = useState(0);
  const [mode, setMode] = useState(1);
  const [sortTeam, setSortTeam] = useState(false);
  const [invitedList, setInvitedList] = useState([]);
  const [is_sole_billable_rate_viewer, setBillableViewer] = useState(false);

  useEffect(() => {
    document.addEventListener(
      "click",
      () => {
        setClickedArray([]);
      },
      true
    );
    if (auth.user_id !== null && auth.user_id !== undefined) {
      let buff = Buffer.from(auth.user_id, "base64");
      let user_id = buff.toString("ascii");
      setUserId(user_id);
    }
    let modeN = props.mode;
    if (modeN == 1) {
      setMode(1);
    } else if (modeN == 2) {
      setMode(2);
    } else if (modeN == 3) {
      setMode(3);
    }
    setTeamList([]);
    getTeamList(props.mode);
    getWorkspaceInfo();
  }, [props.mode, props.reload]);

  const getWorkspaceInfo = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let workspace_id = auth.workspace_id;
      const body = JSON.stringify({ workspace_id });
      let res = await axios.post(
        "/api/workspace/listbyworkspaceid",
        body,
        config
      );
      if (res.status === 200) {
        res.data.results.map((item) => {
          if (item.id === workspace_id) {
            if (item.is_sole_billable_rate_viewer === 1) {
              if (auth.is_workspace_admin === 1) {
                setBillableViewer(true);
              } else {
                setBillableViewer(false);
              }
            } else {
              setBillableViewer(true);
            }
          }
        });
      }
    } catch (err) {
      console.log(err.response);
      return err.response;
    }
  };

  const getTeamList = async (modenumber) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let workspace_id = auth.workspace_id;
      let is_workspace_admin = auth.is_workspace_admin;
      let is_active, modeN;
      modeN =
        modenumber !== null && modenumber !== undefined && modenumber !== ""
          ? modenumber
          : mode;
      if (modeN == 1) {
        is_active = 1;
      } else if (modeN == 2) {
        is_active = 0;
      } else if (modeN == 3) {
        is_active = 3;
      }
      const body = JSON.stringify({
        is_workspace_admin,
        workspace_id,
        is_active,
      });
      let res = await axios.post("/api/team/list", body, config);
      if (res.status === 200) {
        setTeamList(res.data.results);
      }
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  const sortTeamArray = () => {
    let array = teamList;
    let ascendingArray = array.sort(function (a, b) {
      let nameA = a.name.toLowerCase(),
        nameB = b.name.toLowerCase();
      if (nameA < nameB)
        //sort string ascending
        return -1;
      if (nameA > nameB) return 1;
      return 0; //default return value (no sorting)
    });
    if (sortTeam === false) {
      setSortTeam(true);
      setTeamList([...ascendingArray]);
    } else {
      setSortTeam(false);
      let descendingArray = ascendingArray.reverse();
      setTeamList([...descendingArray]);
    }
  };

  const handleClickDropdown = (item) => {
    setTeamId(item.team_id);
    setClickedArray([item.team_id]);
    item.name !== null
      ? setMemberName(item.name)
      : setMemberName(item.first_name + " " + item.last_name);
    setMemeberEmail(item.email);
    setBill(item.billable_rate);
    setLabor(item.labor_cost);
  };

  const handleMemberName = (e) => {
    setMemberName(e.target.value);
  };

  const handleClose = () => {
    setEditOpen(false);
  };

  const handleBillChange = (e) => {
    setBill(e.target.value);
  };

  const handleLaborChange = (e) => {
    setLabor(e.target.value);
  };

  const validateForm = () => {
    let errors = {};
    let formIsValid = true;
    if (memberName === "") {
      formIsValid = false;
      errors["member_name"] = "Please enter the client name";
    }
    if (billable_rate === "") {
      formIsValid = false;
      errors["billable_rate"] = "Please enter the billable rate";
    }
    if (labor_cost === "") {
      formIsValid = false;
      errors["labor_cost"] = "Please enter the labor cost";
    }
    setErrors(errors);
    return formIsValid;
  };

  const editMember = async () => {
    try {
      let workspace_id = auth.workspace_id;
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let name = memberName;
      const body = JSON.stringify({
        team_id,
        name,
        billable_rate,
        labor_cost,
        workspace_id,
      });
      if (validateForm() && team_id !== 0) {
        let res = await axios.put("/api/team/edit", body, config);
        if (res.status === 200) {
          handleClose();
          setClickedArray([]);
          getTeamList();
          setBill(0);
          setLabor(0);
        }
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  const deactivateUser = async (item) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      };
      const workspace_id = auth.workspace_id;
      const user_id = item.user_id;

      const body = JSON.stringify({
        workspace_id,
        user_id,
      });
      let res = await axios.put("/api/team/deactivate", body, config);
      if (res.status === 200) {
        getTeamList();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const activateUser = async (item) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      };
      const workspace_id = auth.workspace_id;
      const user_id = item.user_id;

      const body = JSON.stringify({
        workspace_id,
        user_id,
      });
      let res = await axios.put("/api/team/activate", body, config);
      if (res.status === 200) {
        getTeamList();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <CheckToken />
      <div class="content-wrap" id="wrapper">
        <div class="main">
          <div class="project-group-work-section" style={{ margin: "0px" }}>
            <div class="working-group-area-contain" style={{ margin: "0px" }}>
              <div class="group-main-inner-box">
                <div class="group-inner-box-second-row inner-team-first-row">
                  <div class="group-box-second-inner">
                    <div class="group-inner-main-wrap">
                      <div class="group-by-title-wrap">
                        <span>Name</span>
                        <div class="filter-arrow-cont">
                          <div class="arrow-top-dur">
                            <i
                              class="fa fa-angle-up"
                              aria-hidden="true"
                              onClick={() => sortTeamArray()}
                            ></i>
                          </div>
                          <div class="arrow-down-dur">
                            <i
                              class="fa fa-angle-down"
                              aria-hidden="true"
                              onClick={() => sortTeamArray()}
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="group-inner-main-wrap">
                      <div class="group-by-title-wrap">
                        <span>Email</span>
                      </div>
                    </div>
                    <div class="group-inner-main-wrap">
                      <div class="group-by-title-wrap">
                        <span>Rate</span>
                      </div>
                    </div>
                    <div class="group-inner-main-wrap">
                      <div class="group-by-title-wrap">
                        <span>Cost</span>
                      </div>
                    </div>

                    {/*<div class="group-inner-main-wrap">
            <div class="group-by-title-wrap"><span>Access</span>
            </div>  
        </div>
           <div class="group-inner-main-wrap">
            <div class="group-by-title-wrap"><span>Group</span>
            </div>  
        </div>*/}
                  </div>
                </div>
                {/*<!--============MIDDLE ROW/SECOND ROW END HERE -->*/}

                {/*<!--============THIRD ROW GROUP BY PROJECT AND TIME ENTRY START FORM HERE==== -->*/}
                {teamList.map((item, index) => (
                  <div
                    class="group-by-team-active"
                    style={{ display: "block" }}
                    key={index}
                  >
                    <div class="group-inner-box-second-row filter-by-project-last-row">
                      <div class="group-box-second-inner filter-by-project inner-team-active-info-row">
                        <div class="group-inner-main-wrap">
                          <div class="group-by-title-wrap group-by-client-name">
                            <span
                              class="user-short-name"
                              style={{
                                backgroundColor:
                                  mode == 1
                                    ? "#" + item.profile_name_colorcode
                                    : "#F1F1F1",
                                display: item.invited !== true ? "" : "none",
                              }}
                            >
                              {item.invited !== true
                                ? item.first_name.charAt(0) +
                                  item.last_name.charAt(0)
                                : ""}
                            </span>
                            <svg
                              width="16px"
                              height="16px"
                              viewBox="0 0 18 18"
                              style={{
                                display: item.invited === true ? "" : "none",
                              }}
                            >
                              <g
                                fill="none"
                                fill-rule="evenodd"
                                stroke="#cecece"
                                stroke-width="2"
                                transform="translate(1 1)"
                              >
                                <path
                                  stroke-linecap="round"
                                  d="M8 3.5V8m0 0l2.7 1.8"
                                ></path>
                                <circle cx="8" cy="8" r="8"></circle>
                              </g>
                            </svg>
                            <span class="project-client-name">
                              &nbsp;&nbsp;&nbsp;
                              {item.name}
                            </span>
                          </div>
                        </div>
                        <div class="group-inner-main-wrap">
                          <div class="group-by-title-wrap">
                            <span>{item.email}</span>
                          </div>
                        </div>
                        <div class="group-inner-main-wrap">
                          <div
                            class="group-by-title-wrap"
                            style={{
                              display:
                                mode == 1 &&
                                is_sole_billable_rate_viewer === true
                                  ? ""
                                  : "none",
                            }}
                          >
                            <span>
                              {item.billable_rate !== "" &&
                              item.billable_rate !== null &&
                              item.billable_rate !== undefined
                                ? item.billable_rate
                                : 0}
                            </span>
                          </div>
                        </div>
                        <div class="group-inner-main-wrap">
                          <div
                            class="group-by-title-wrap"
                            style={{
                              display:
                                mode == 1 &&
                                is_sole_billable_rate_viewer === true
                                  ? ""
                                  : "none",
                            }}
                          >
                            <span>
                              {item.labor_cost !== "" &&
                              item.labor_cost !== null &&
                              item.labor_cost !== undefined
                                ? item.labor_cost
                                : 0}
                            </span>
                          </div>
                        </div>
                        <div
                          class="nav nav-pills nav-stacked intimer-project-panel-option-btn-contain"
                          style={{
                            display:
                              auth.is_workspace_admin === 0 ? "none" : "",
                          }}
                        >
                          <div
                            class={
                              clickedArray.includes(item.team_id)
                                ? "dropdown show"
                                : "dropdown"
                            }
                            style={{
                              display:
                                user_id != item.user_id &&
                                auth.is_workspace_admin === 0
                                  ? "none"
                                  : "",
                            }}
                            disabled={
                              auth.is_workspace_admin === 0 ? "true" : "false"
                            }
                          >
                            <div
                              class="dropdown-toggle"
                              data-toggle="dropdown"
                              href="javascript:void(0)"
                            >
                              <div
                                tabindex="0"
                                title="More actions"
                                class="option-btn-show"
                                onClick={() => {
                                  handleClickDropdown(item);
                                }}
                              >
                                <svg width="4" height="16" viewBox="0 0 4 16">
                                  <path
                                    fill="#cecece"
                                    fill-rule="evenodd"
                                    d="M0 2a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm0 12a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm0-6a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"
                                  ></path>
                                </svg>
                              </div>
                            </div>
                            <div
                              class={
                                clickedArray.includes(item.team_id)
                                  ? "dropdown-menu action_btns intimer-dot-option-box show"
                                  : "dropdown-menu action_btns intimer-dot-option-box"
                              }
                              x-placement="bottom-start"
                              style={{
                                position: "absolute",
                                transform: "translate3d(-118px, 22px, 0px)",
                                top: "0px",
                                left: "0px",
                                willChange: "transform",
                              }}
                            >
                              <div
                                class="duplicate option-items"
                                style={{
                                  display:
                                    auth.is_workspace_admin === 1 && mode == 1
                                      ? ""
                                      : "none",
                                }}
                                onClick={() => {
                                  setEditOpen(true);
                                }}
                              >
                                <span>Edit</span>
                              </div>
                              <div
                                class="delete option-items"
                                style={{
                                  display:
                                    auth.is_workspace_admin === 1 &&
                                    mode == 1 &&
                                    user_id != item.user_id
                                      ? ""
                                      : "none",
                                }}
                                onClick={() => {
                                  deactivateUser(item);
                                  setClickedArray([]);
                                }}
                              >
                                <span>Deactivate</span>
                              </div>
                              <div
                                class="delete option-items"
                                style={{
                                  display:
                                    auth.is_workspace_admin === 1 && mode != 1
                                      ? ""
                                      : "none",
                                }}
                                onClick={() => {
                                  activateUser(item);
                                  setClickedArray([]);
                                }}
                              >
                                <span>Activate</span>
                              </div>
                              {/*<div class="delete option-items" 
                                        style={{color: "rgb(226, 5, 5)"}}>
                                      <span>Leave workspace</span>
                                        </div>*/}
                            </div>
                          </div>
                        </div>
                        {/*<div class="group-inner-main-wrap" style={{display:item.invited === true ?"none":""}}>
             <div class="nav nav-pills nav-stacked in-team-edit-row-btn"  
              style={{display:user_id != item.user_id && auth.is_workspace_admin === 0?"none":""}}
              disabled={auth.is_workspace_admin === 0?"true":"false"}>
                            <div class={clickedArray.includes(item.team_id)?"dropdown show":"dropdown"} >
                                <a class="dropdown-toggle" data-toggle="dropdown" href='javascript:void(0)' 
                                    aria-expanded={clickedArray.includes(item.team_id)?"true":"false"}>
                                    <div tabIndex="0" title="More actions" class="option-btn-show"
                                      onClick={()=>{handleClickDropdown(item)}}
                                    >
                                        <svg width="4" height="16" viewBox="0 0 4 16">

                                            <path 
                                                fill="#cecece" 
                                                fillRule="evenodd" 
                                                d="M0 2a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm0 12a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm0-6a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"></path></svg>
                                    </div>
                                </a>
                                <div class={clickedArray.includes(item.team_id)?"dropdown-menu action_btns intimer-dot-option-box show":"dropdown-menu action_btns intimer-dot-option-box"} 
                                    style={{transform: "translate3d(-65px, -107px, 0px)", position: "absolute", top: "0px", left: "0px", willChange: "transform"}} 
                                    x-placement="top-start">
                                    <div class="duplicate option-items" 
                                      style={{display:auth.is_workspace_admin === 1 && mode == 1?"":"none"}}
                                      onClick={()=>{setEditOpen(true)}}>
                                        <span>Edit</span>
                                      </div>
                                    <div class="delete option-items" 
                                        style={{display:
                                          auth.is_workspace_admin === 1 
                                          && mode == 1 && user_id != item.user_id?"":"none"}}
                                        onClick={()=>{deactivateUser(item);setClickedArray([]);}}
                                        >
                                      <span>Deactivate</span>
                                    </div>
                                    <div class="delete option-items" 
                                        style={{display:
                                          auth.is_workspace_admin === 1 && mode != 1?"":"none"}}
                                        onClick={()=>{activateUser(item);setClickedArray([]);}}
                                        >
                                      <span>Activate</span>
                                    </div>
                                    <div class="delete option-items" 
                                        style={{color: "rgb(226, 5, 5)"}}>
                                      <span>Leave workspace</span>
                                    </div>
                                </div>
                            </div>
                        </div>
        </div>      */}
                      </div>
                    </div>
                  </div>
                ))}
                {/*<!-- filter porject container close here -->*/}

                {/*<!--============THIRD ROW GROUP BY PROJECT AND TIME ENTRY END HERE==== -->*/}

                {/*<!--============THIRD ROW GROUP BY CLIENT AND TIME ENTRY START FORM HERE==== -->*/}

                <Modal
                  show={editOpen}
                  onHide={handleClose}
                  dialogClassName="add-New-tag-popup-box-container"
                  bsPrefix=""
                >
                  <div class="popup-title">
                    <h3>Edit Member</h3>
                  </div>
                  <div
                    class="top-lebel-wrap"
                    style={{ fontWeight: "500", marginBottom: "-2%" }}
                  >
                    <span class="label-text">Name</span>
                  </div>
                  <div class="input-field-width-color-wrap">
                    <div
                      className="popup-inner-input-field "
                      style={{
                        flex: "10 0 0px",
                        display: "flex",
                        marginBottom: "3%",
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Member Name"
                        name=""
                        onChange={handleMemberName}
                        value={memberName}
                      />
                      <span>{errors.member_name}</span>
                    </div>
                  </div>
                  <div
                    class="top-lebel-wrap"
                    style={{ fontWeight: "500", marginBottom: "-2%" }}
                  >
                    <span class="label-text">Email</span>
                  </div>
                  <div class="input-field-width-color-wrap">
                    <div
                      className="popup-inner-input-field "
                      style={{
                        flex: "10 0 0px",
                        display: "flex",
                        marginBottom: "3%",
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Member Name"
                        name=""
                        readOnly="true"
                        defaultValue={memberEmail}
                      />
                    </div>
                  </div>
                  <div
                    class="top-lebel-wrap"
                    style={{ fontWeight: "500", marginBottom: "-2%" }}
                  >
                    <span class="label-text">Billable Rate</span>
                  </div>
                  <div class="input-field-width-color-wrap">
                    <div
                      className="popup-inner-input-field "
                      style={{
                        flex: "10 0 0px",
                        display: "flex",
                        marginBottom: "3%",
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Billable Rate"
                        name="billable_rate"
                        onChange={handleBillChange}
                        value={billable_rate}
                      />
                      <span>{errors.billable_rate}</span>
                    </div>
                  </div>
                  <div
                    class="top-lebel-wrap"
                    style={{ fontWeight: "500", marginBottom: "-2%" }}
                  >
                    <span class="label-text">Labor Cost</span>
                  </div>
                  <div class="input-field-width-color-wrap">
                    <div
                      className="popup-inner-input-field "
                      style={{
                        flex: "10 0 0px",
                        display: "flex",
                        marginBottom: "3%",
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Labor Cost"
                        name="labor_cost"
                        onChange={handleLaborChange}
                        value={labor_cost}
                      />
                      <span>{errors.labor_cost}</span>
                    </div>
                  </div>
                  <div class="create-tag-name-btn popup-full-btn">
                    <button
                      type="submit"
                      class="popup-button"
                      onClick={() => editMember()}
                    >
                      <span>Save</span>
                    </button>
                  </div>
                </Modal>

                {/*<!-- filter porject container close here -->*/}
                {/*<!--============THIRD ROW GROUP BY CLIENT AND TIME ENTRY END HERE==== -->*/}
              </div>
            </div>
          </div>
        </div>{" "}
        {/*<!-- content wrapper end here -->*/}
        {/*<!-- Add new client modal -->*/}
        <div
          id="clientModal"
          class="modal fade"
          role="dialog"
          aria-hidden="true"
          style={{ display: "none" }}
        >
          <div class="modal-dialog">
            {/*<!-- Modal content-->*/}
            <div class="modal-content add-New-tag-popup-box-container">
              <div class="close-popup-btn">
                <button type="button" class="close" data-dismiss="modal">
                  ×
                </button>
              </div>
              <div class="popup-title">
                <h3>Invite members</h3>
              </div>
              <div class="popup-inner-input-field">
                <input
                  type="text"
                  placeholder="Add one or more emails....."
                  name=""
                />
                <span>Client Name is required....</span>
              </div>
              <p class="invite-member-info">
                <span>There can be maximum 5 members in this workspace.</span>{" "}
                <a href="subscription.html" class="invite-upgrade-link">
                  <span>Upgrade</span>
                </a>{" "}
                <span>your plan to invite more</span>
              </p>
              <div class="create-tag-name-btn popup-full-btn">
                <button type="submit" class="popup-button">
                  <span>Invite</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*<!-- jquery vendor -->*/}
        <script src="assets/js/jquery.min.js"></script>
        <script src="assets/js/jquery.nanoscroller.min.js"></script>
        {/*<!-- nano scroller -->*/}
        <script src="assets/js/sidebar.js"></script>
        <script src="assets/js/pace.min.js"></script>
        {/*<!-- sidebar -->*/}
        <script src="assets/js/jquery.popupoverlay.js"></script>
        <script src="assets/js/bootstrap.min.js"></script>
        <script src="assets/js/custom.js"></script>
        <script src="assets/js/scripts.js"></script>
        {/*<!-- scripit init-->*/}
      </div>
      
    </>
  );
}
