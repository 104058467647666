import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, useHistory } from "react-router-dom";
import axios from "axios";
import { loadUser } from "../../actions/auth";
import { LOGIN_SUCCESS,USER_PROPERTIES } from "../../actions/types";
import Logo from "../common/Logo";
import CheckToken from "./check_token";

const SideBar = () => {
  const history = useHistory();
  const auth = useSelector((state) => state.auth);
  const user_properties = useSelector((state) => state.user_properties);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [workspaceOpen, setWorkspace] = useState(false);
  const [showmore, setShowMore] = useState(false);
  const [profileInfo, setProfileInfo] = useState([]);
  const [workspaceInfo, setWorkspaceInfo] = useState([]);

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    document.addEventListener(
      "click",
      () => {
        setOpen(false);
        setWorkspace(false);
      },
      true
    );
    getProfile();
    userCode();
    getWorkspaceInfo();
  }, []);

  const getWorkspaceInfo = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let workspace_id = auth.workspace_id;
      const body = JSON.stringify({ workspace_id });
      let res = await axios.post(
        "/api/workspace/listbyworkspaceid",
        body,
        config
      );
      if (res.status === 200) {
        setWorkspaceInfo(res.data.results);
        let i = 0;
        res.data.results.map((item)=> {
          if(item.id != auth.workspace_id) {
            i++;
          }
        })

        if(i === res.data.results.length) {
          selectWorkspace(res.data.results[0]);
        }
      }
    } catch (err) {
      console.log(err.response);
      if(err.response.status === 401 || err.response.status === 403) {
        console.log("loging out")
        history.push('/logout');
      }
      return err.response;
    }
  };
  
  const getProfile = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let workspace_id = auth.workspace_id;
      const body = JSON.stringify({ workspace_id });
      let res = await axios.post("/api/profile/info", body, config);
      if (res.status === 200) {
        setProfileInfo(res.data.results[0][0]);
      }
    } catch (err) {
      if (err.response) {
        console.log(err.response);
        if(err.response.status === 401 || err.response.status === 403) {
          console.log("loging out")
          history.push('/logout');
        }
      }
    }
  };

  const userCode = () => {
    let firstLetter = "";
    let lastLeter = "";
    if (profileInfo.first_name !== null && profileInfo.last_name != null) {
      firstLetter = profileInfo.first_name.charAt(0);
      lastLeter = profileInfo.last_name.charAt(0);
    } else if (
      profileInfo.email !== null &&
      typeof profileInfo.email !== "undefined"
    ) {
      firstLetter = profileInfo.email.charAt(0);
      lastLeter = profileInfo.email.charAt(1);
    }
    return firstLetter.concat(lastLeter).toUpperCase();
  };

  const displayName = () => {
    if (profileInfo.first_name !== null && profileInfo.last_name != null) {
      let firstName = profileInfo.first_name;
      let lastName = profileInfo.last_name;
      return firstName.concat("  ").concat(lastName).toUpperCase();
    } else if (
      profileInfo.email !== null &&
      typeof profileInfo.email !== "undefined"
    ) {
      return profileInfo.email.split("@")[0].toUpperCase();
    }
  };

  const selectWorkspace = async (item) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let workspace_id = item.id;
      const body = JSON.stringify({ workspace_id });
      let res = await axios.post("/api/workspace/select", body, config);
      if (res.status === 200) {
        dispatch({ type: LOGIN_SUCCESS, payload: res.data });
        dispatch(loadUser(res.data));
        window.location.reload();
      }
    } catch (err) {
      if (err.response) {
        console.log(err.response);
        if(err.response.status === 401 || err.response.status === 403) {
          console.log("loging out")
          history.push('/logout');
      }
      }
    }
  };

  return (
    <div>
      <CheckToken />
      <div className='sidebar sidebar-hide-to-small sidebar-shrink sidebar-gestures'>
        <div className='nano'>
          <div className='nano-content'>
            <div className='logo' id="logotoimage">
              <a href='/'>
                <Logo />
              </a>
              <div className='notification-in-sidebar'>
                <svg width='14' height='16' viewBox='0 0 14 16'>
                  <path
                    fill='#ffffff'
                    fillRule='evenodd'
                    d='M6 2.1a5.001 5.001 0 00-4 4.894v1.003c0 .565-.198 1.4-.444 1.89L.444 12.113c-.245.49.007.888.563.888h11.986c.557 0 .809-.397.563-.888l-1.112-2.224C12.2 9.398 12 8.55 12 7.998V6.993A4.996 4.996 0 008 2.1V.99A1 1 0 007 0c-.556 0-1 .444-1 .99V2.1zM7 16a2 2 0 01-2-2h4a2 2 0 01-2 2z'
                  ></path>
                </svg>
              </div>
            </div>
            <ul className='main-sidebar-items'>
              <li>
                <NavLink to='/dashboard' activeClassName='active'>
                  <i>
                    <svg width='16' height='16' viewBox='0 0 18 18'>
                      <path
                        d='M8.001 8.948L8 9c0 .556.448 1 1 1h3c.556 0 1-.448 1-1 0-.556-.448-1-1-1h-2V4.003a1 1 0 10-2 0v4.894l.001.051zM9 18A9 9 0 109 0a9 9 0 000 18z'
                        fill='#ffffff'
                        fillRule='evenodd'
                      ></path>
                    </svg>
                  </i>{" "}
                  Timer
                </NavLink>
              </li>
              <li className='label'>ANALYZE</li>

              <li>
                <Link to='/reports'>
                  <i>
                    <svg width='14' height='16' viewBox='0 0 14 16'>
                      <path
                        fill='#ffffff'
                        fillRule='evenodd'
                        d='M0 1.994A2 2 0 012.006 0h9.988C13.102 0 14 .895 14 1.994v12.012A2 2 0 0111.994 16H2.006A2.001 2.001 0 010 14.006V1.994zM2 3c0 .556.446 1 .995 1h8.01c.54 0 .995-.448.995-1 0-.556-.446-1-.995-1h-8.01C2.455 2 2 2.448 2 3zm0 3c0 .556.446 1 .995 1h8.01c.54 0 .995-.448.995-1 0-.556-.446-1-.995-1h-8.01C2.455 5 2 5.448 2 6zm0 3c0 .556.446 1 .995 1h8.01c.54 0 .995-.448.995-1 0-.556-.446-1-.995-1h-8.01C2.455 8 2 8.448 2 9z'
                      ></path>
                    </svg>
                  </i>
                  Reports
                </Link>
              </li>
              <li>
                <Link to='/calendar'>
                  <i>
                  <svg xmlns="http://www.w3.org/2000/svg" 
                    fill="#000000" width='16' height='16' viewBox='0 0 24 24'
                  >
                      <path fill='#ffffff' fillRule='evenodd' d="M 5 0 L 5 4 L 7 4 L 7 0 Z M 17 0 L 17 4 L 19 4 L 19 0 Z M 1 3 C 0.449219 3 0 3.449219 0 4 L 0 7 C 0 7.550781 0.449219 8 1 8 L 1 24 L 23 24 L 23 8 C 23.550781 8 24 7.550781 24 7 L 24 4 C 24 3.449219 23.550781 3 23 3 L 20 3 L 20 5 L 16 5 L 16 3 L 8 3 L 8 5 L 4 5 L 4 3 Z M 3 8 L 21 8 L 21 22 L 3 22 Z M 5 10 L 5 12 L 7 12 L 7 10 Z M 9 10 L 9 12 L 11 12 L 11 10 Z M 13 10 L 13 12 L 15 12 L 15 10 Z M 17 10 L 17 12 L 19 12 L 19 10 Z M 5 14 L 5 16 L 7 16 L 7 14 Z M 9 14 L 9 16 L 11 16 L 11 14 Z M 13 14 L 13 16 L 15 16 L 15 14 Z M 17 14 L 17 16 L 19 16 L 19 14 Z M 5 18 L 5 20 L 7 20 L 7 18 Z M 9 18 L 9 20 L 11 20 L 11 18 Z M 13 18 L 13 20 L 15 20 L 15 18 Z M 17 18 L 17 20 L 19 20 L 19 18 Z"/>
                  </svg>
                  </i>
                  Calendar
                </Link>
              </li>
              <li>
                <Link to='/insights'>
                  <i>
                    <svg width='16' height='12' viewBox='0 0 16 12'>
                      <path
                        d='M0 2.009C0 .899.895 0 1.994 0h12.012C15.107 0 16 .902 16 2.009V9.99c0 1.11-.895 2.009-1.994 2.009H1.994A2.003 2.003 0 010 9.991V2.01zm6.106 8.438c.415.83 1.639.696 1.864-.204l1.25-5 .852 2.128a1 1 0 001.635.336l2-2a1 1 0 10-1.414-1.414l-.928.928-1.437-3.592c-.36-.901-1.663-.813-1.898.128L6.685 7.135l-.79-1.582a1 1 0 00-1.602-.26l-2 2a1 1 0 101.414 1.414l1.019-1.019 1.38 2.76z'
                        fill='#ffffff'
                        fillRule='evenodd'
                      ></path>
                    </svg>
                  </i>
                  Insights
                </Link>
              </li>

              <li className='label'>MANAGE</li>

              <li>
                <NavLink to='/projects' activeClassName='active'>
                  <i>
                    <svg width='16' height='13' viewBox='0 0 16 13'>
                      <path
                        fill='#ffffff'
                        fillRule='evenodd'
                        d='M0 6h16v4.994A2.001 2.001 0 0114.006 13H1.994A1.993 1.993 0 010 10.994V6zm0-4a2 2 0 012.004-2h3.05c1.107 0 2.004.895 2.004 2h6.935C15.102 2 16 2.895 16 4H0V2z'
                      ></path>
                    </svg>
                  </i>
                  Projects
                </NavLink>
              </li>

              <li>
                <NavLink to='/clients' activeClassName='active'>
                  <i>
                    <svg width='18' height='18' viewBox='0 0 16 18'>
                      <path
                        d='M0 2.993A2.994 2.994 0 013.01 0h9.98C14.654 0 16 1.353 16 2.993v12.014A2.994 2.994 0 0112.99 18H3.01C1.346 18 0 16.647 0 15.007V2.993zM6.5 9.5c0 1.5-3.5 1-3.5 4 0 1 0 0 0 0A1.5 1.5 0 004.497 15h7.006C12.33 15 13 14.323 13 13.5c0 0 0 1 0 0 0-3-3.5-2.5-3.5-4C9.5 8.5 11 8 11 6c0-1.5-1.343-3-3-3S5 4.5 5 6c0 2 1.5 2.5 1.5 3.5z'
                        fill='#ffffff'
                        fillRule='evenodd'
                      ></path>
                    </svg>
                  </i>{" "}
                  Clients
                </NavLink>
              </li>

              <li>
                <NavLink to='/team' activeClassName='active'>
                  <i>
                    <svg width='18' height='14' viewBox='0 0 18 14'>
                      <path
                        fill='#ffffff'
                        fillRule='evenodd'
                        d='M11.572 8.295C11.282 7.678 10.5 7 10.5 6c0-1.5.5-3 2.5-3s2.5 1.5 2.5 3-1 1.5-1 3c0 2 3.5.5 3.5 3v.5c0 .821-.67 1.5-1.499 1.5H12v-2.072c0-1.683 0-2.72-.428-3.633zM4 7c0-1-1.5-1.5-1.5-3.5C2.5 2 3 0 5.5 0s3 2 3 3.5C8.5 5.5 7 6 7 7c0 2.5 4 1 4 5v.5c0 .821-.674 1.5-1.506 1.5H1.506A1.502 1.502 0 010 12.5V12c0-4 4-2.5 4-5z'
                      ></path>
                    </svg>
                  </i>
                  Team
                </NavLink>
              </li>
              <li>
                <NavLink to='/tag' activeClassName='active'>
                  <i>
                    <svg width='15' height='15' viewBox='0 0 15 15'>
                      <path
                        fill='#ffffff'
                        fillRule='evenodd'
                        d='M0 4.994V2.006C0 .886.898 0 2.006 0h2.988c1.12 0 2.638.632 3.418 1.412l5.176 5.176a1.996 1.996 0 01-.005 2.829l-4.166 4.166a2 2 0 01-2.829.005L1.412 8.412C.628 7.628 0 6.102 0 4.994zM4 6a2 2 0 100-4 2 2 0 000 4z'
                      ></path>
                    </svg>
                  </i>{" "}
                  Tags
                </NavLink>
              </li>

              <li>
                <Link to='/settings'>
                  <i>
                    <svg width='16' height='16' viewBox='0 0 16 16'>
                      <path
                        d='M8 11a3 3 0 100-6 3 3 0 000 6zM5.182 2.702c.337-.18.693-.328 1.065-.442L6.778.666C6.9.299 7.313 0 7.7 0h.6c.387 0 .8.298.922.666l.531 1.594c.372.114.728.262 1.065.442l1.504-.752c.346-.173.85-.093 1.122.18l.425.426c.274.273.354.775.181 1.122l-.752 1.504c.18.337.328.693.442 1.065l1.594.531c.367.122.666.535.666.922v.6c0 .387-.298.8-.666.922l-1.594.531a5.963 5.963 0 01-.442 1.065l.752 1.504c.173.346.093.85-.18 1.122l-.426.425c-.273.274-.775.354-1.122.181l-1.504-.752c-.337.18-.693.328-1.065.442l-.531 1.594C9.1 15.701 8.687 16 8.3 16h-.6c-.388 0-.8-.298-.922-.666l-.531-1.594a5.963 5.963 0 01-1.065-.442l-1.504.752c-.346.173-.85.093-1.122-.18l-.425-.426c-.274-.273-.354-.775-.181-1.122l.752-1.504a5.963 5.963 0 01-.442-1.065L.666 9.222C.299 9.1 0 8.687 0 8.3v-.6c0-.388.298-.8.666-.922l1.594-.531c.114-.372.262-.728.442-1.065L1.95 3.678c-.173-.346-.093-.85.18-1.122l.426-.425c.273-.274.775-.354 1.122-.181l1.504.752z'
                        fill='#ffffff'
                        fillRule='evenodd'
                      ></path>
                    </svg>
                  </i>
                  Settings
                </Link>
              </li>

              <li>
                <Link to='/help'>
                  <i>
                    <svg width='16' height='16' viewBox='0 0 16 16'>
                      <path
                        fill='#ffffff'
                        fillRule='evenodd'
                        d='M8 16A8 8 0 118 0a8 8 0 010 16zm0-3a1 1 0 100-2 1 1 0 000 2zm0-8c.683 0 1.068.57.955 1.1-.077.362-.2.53-.623.908l-.052.046c-.83.741-1.202 1.316-1.278 2.416a1 1 0 101.996.138c.032-.476.135-.634.613-1.061l.053-.048c.711-.634 1.062-1.113 1.247-1.982A2.914 2.914 0 008 3c-1.572 0-2.501.84-3.057 2.145a1 1 0 001.84.784C7.057 5.285 7.373 5 8 5z'
                      ></path>
                    </svg>
                  </i>
                  Help
                </Link>
              </li>

              <li className='show-more-btn'>
                <a className='sidebar-sub-toggle' 
                  onClick={() => {
                      setShowMore(!showmore);
                      setWorkspace(false);
                      setOpen(false);
                    }}>
                  <i>
                    <svg width='14' height='8' viewBox='0 0 14 8'>
                      <path
                        fill='none'
                        stroke='#ffffff'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        d='M1 1l6 6 6-6'
                      ></path>
                    </svg>
                  </i>
                  <span
                    className='show-more-menus'
                  >
                    Show {showmore ? "Less" : "More"}
                  </span>
                </a>

                <ul
                  className='list-inner-item-dima'
                  style={{ display: showmore ? "block" : "none" }}
                >
                  <li>
                    <Link to='/import'>
                      <i>
                        <svg width='16' height='17' viewBox='0 0 16 17'>
                          <path
                            fill='#ffffff'
                            fillRule='evenodd'
                            d='M9 13v-2H7v2H5.99c-.539 0-.721.358-.39.8l1.8 2.4c.334.445.869.442 1.2 0l1.8-2.4c.334-.445.156-.8-.39-.8H9zM7 9v2h2V9h1.01c.546 0 .724-.355.39-.8L8.6 5.8c-.331-.442-.866-.445-1.2 0L5.6 8.2c-.331.442-.149.8.39.8H7zm5-4.965a3.5 3.5 0 110 6.93V11H3.5A3.5 3.5 0 114 4.035V4a4 4 0 118 .035z'
                          ></path>
                        </svg>
                      </i>
                      <span className='remove-text'>Import/Export</span>
                    </Link>
                  </li>

                  <li>
                    <Link to='/subscription'>
                      <i>
                        <svg width='16' height='12' viewBox='0 0 16 12'>
                          <path
                            fill='#ffffff'
                            fillRule='evenodd'
                            d='M0 2.009C0 .899.895 0 1.994 0h12.012C15.107 0 16 .902 16 2.009V9.99c0 1.11-.895 2.009-1.994 2.009H1.994A2.003 2.003 0 010 9.991V2.01zM0 3v3h16V3H0z'
                          ></path>
                        </svg>
                      </i>
                      <span className='remove-text'>Subscription</span>
                    </Link>
                  </li>

                  <li>
                    <Link to='/training'>
                      <i>
                        <svg width='10' height='10' viewBox='0 0 10 10'>
                          <g
                            fill='none'
                            fillRule='evenodd'
                            stroke='#ffffff'
                            strokeLinecap='round'
                            strokeWidth='2'
                          >
                            <path d='M.5 9.5L9.011.989'></path>
                            <path strokeLinejoin='round' d='M2 1h7v7'></path>
                          </g>
                        </svg>
                      </i>{" "}
                      <span className='remove-text'>Training</span>
                    </Link>
                  </li>

                  <li>
                    <Link to='/mobile-app'>
                      <i>
                        <svg width='12' height='18' viewBox='0 0 12 18'>
                          <path
                            fill='#ffffff'
                            d='M2 4v9a1 1 0 001 1h6a1 1 0 001-1V4a1 1 0 00-1-1H3a1 1 0 00-1 1zm0-4h8a2 2 0 012 2v14a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2z'
                          ></path>
                        </svg>
                      </i>{" "}
                      <span className='remove-text'>Mobile App</span>
                    </Link>
                  </li>

                  <li>
                    <Link to='/desktop-app'>
                      <i>
                        <svg width='15' height='14' viewBox='0 0 15 14'>
                          <path
                            fill='#ffffff'
                            d='M5 12h5a1 1 0 010 2H5a1 1 0 010-2zm7-10H3a1 1 0 00-1 1v6a1 1 0 001 1h9a1 1 0 001-1V3a1 1 0 00-1-1zm3 0v8c0 1.105-.746 2-1.667 2H1.667C.747 12 0 11.105 0 10V2C0 .895.746 0 1.667 0h11.666C14.253 0 15 .895 15 2z'
                          ></path>
                        </svg>
                      </i>
                      <span className='remove-text'>Desktop App</span>
                    </Link>
                  </li>

                  <li>
                    <Link to='/blog'>
                      <i>
                        <svg width='10' height='10' viewBox='0 0 10 10'>
                          <g
                            fill='none'
                            fillRule='evenodd'
                            stroke='#ffffff'
                            strokeLinecap='round'
                            strokeWidth='2'
                          >
                            <path d='M.5 9.5L9.011.989'></path>
                            <path strokeLinejoin='round' d='M2 1h7v7'></path>
                          </g>
                        </svg>
                      </i>
                      <span className='remove-text'>Blog</span>
                    </Link>
                  </li>

                  <li>
                    <Link to='/jobs'>
                      <i>
                        <svg width='10' height='10' viewBox='0 0 10 10'>
                          <g
                            fill='none'
                            fillRule='evenodd'
                            stroke='#ffffff'
                            strokeLinecap='round'
                            strokeWidth='2'
                          >
                            <path d='M.5 9.5L9.011.989'></path>
                            <path strokeLinejoin='round' d='M2 1h7v7'></path>
                          </g>
                        </svg>
                      </i>
                      <span className='remove-text'> Jobs</span>
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
            <ul className='user-workspace-container'>
              <li className='label user-workspace-folder'>WORKSPACE</li>

              <li className='mobile-notification-bel'>
                <a href='#'>
                  <i>
                    <svg width='14' height='16' viewBox='0 0 14 16'>
                      <path
                        fill='#ffffff'
                        fillRule='evenodd'
                        d='M6 2.1a5.001 5.001 0 00-4 4.894v1.003c0 .565-.198 1.4-.444 1.89L.444 12.113c-.245.49.007.888.563.888h11.986c.557 0 .809-.397.563-.888l-1.112-2.224C12.2 9.398 12 8.55 12 7.998V6.993A4.996 4.996 0 008 2.1V.99A1 1 0 007 0c-.556 0-1 .444-1 .99V2.1zM7 16a2 2 0 01-2-2h4a2 2 0 01-2 2z'
                      ></path>
                    </svg>
                  </i>
                </a>
              </li>
              <li
                className='user-folder popup1_open'
                id='popup1_open'
                onClick={() => {
                  setWorkspace(!workspaceOpen);
                  setOpen(false);
                }}
              >
                <a className='sidebar-sub-toggle'>
                  <i>
                    <svg width='16' height='16' viewBox='0 0 18 16'>
                      <path
                        d='M6.503 2h4.994c.098 0 .252.119.275.21l.758 3.033L13.5 4h-9l.97 1.243.758-3.032c.024-.094.173-.211.275-.211zm7.778 2l-.569-2.274C13.466.74 12.51 0 11.497 0H6.503c-1.02 0-1.97.743-2.215 1.726L3.719 4H1.995C.893 4 0 4.9 0 6.009v7.982C0 15.098.893 16 1.995 16h14.01C17.107 16 18 15.1 18 13.991V6.01A2.004 2.004 0 0016.005 4H14.28z'
                        fill='#cecece'
                        fillRule='evenodd'
                      ></path>
                    </svg>
                  </i>

                  <span className='user-name-fld'>{profileInfo.title}</span>
                  <i className='fa fa-angle-down' aria-hidden='true'></i>
                </a>
              </li>

              <div
                className='tooltip_open left-side-about-user-id'
                id='tooltip_open'
                onClick={() => {
                  setOpen(!open);
                  setWorkspace(false);
                }}
              >
                <div className='left-sidebar-username'>
                  <span>{displayName()} </span>
                  <span className='user-mail-add'>{profileInfo.email}</span>
                </div>
                <div className='left-sidebar-shortname'>
                  <span className='user-shortname-left-sidebar'>
                    {userCode()}
                  </span>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
      {/*<!-- =========USER WORKSPACE MODAL START FORM HERE========= -->*/}
      <div
        className={`user-folder-about-inner-content user-profile-modal`}
        id='popup1'
        style={{ display: workspaceOpen ? "" : "none" , zIndex:2}}
      >
        {workspaceInfo.map((item, index) => (
          <a
            className='sidebar-sub-toggle'
            key={index}
            onClick={() => {
              selectWorkspace(item);
            }}
          >
            <i>
              <svg width='16' height='16' viewBox='0 0 18 16'>
                <path
                  d='M6.503 2h4.994c.098 0 .252.119.275.21l.758 3.033L13.5 4h-9l.97 1.243.758-3.032c.024-.094.173-.211.275-.211zm7.778 2l-.569-2.274C13.466.74 12.51 0 11.497 0H6.503c-1.02 0-1.97.743-2.215 1.726L3.719 4H1.995C.893 4 0 4.9 0 6.009v7.982C0 15.098.893 16 1.995 16h14.01C17.107 16 18 15.1 18 13.991V6.01A2.004 2.004 0 0016.005 4H14.28z'
                  fill={auth.workspace_id == item.id?"#4bc800":"#cecece"}
                  fillRule='evenodd'
                ></path>
              </svg>
            </i>
            <span
              className={
                auth.workspace_id == item.id
                  ? "user-name-fld"
                  : "user-name-normal"
              }
              style={{color:auth.workspace_id == item.id?"#4bc800":"#000000"}}
            >
              {item.title}
            </span>
            {/*{auth.workspace_id === item.id?
            <span className={"activeF"}>Default</span>
            :""}*/}
            
          </a>
        ))}
        <div className='create-project-in-serchbox'>
          <Link to='/workspace'>
            <i className='fa fa-plus' aria-hidden='true'></i>New Workspace
          </Link>
        </div>
      </div>
      {/*<!-- =========USER WORKSPACE MODAL END HERE========= -->*/}

      {/*<!-- =========USER PROFILE INFO BOX========= -->*/}

      {/*<!-- =========USER PROFILE INFO BOX========= -->*/}
      <div
        className='user-folder-about-inner-content user-profile-modal'
        id='tooltip'
        style={{ display: open ? "" : "none",zIndex:2 }}
      >
        <div className='modal-top-user-id-info'>
          <div className='about-user-id-name'>
            <span> {displayName()}</span>
            <span className='user-mail-add'>{profileInfo.email}</span>
          </div>
          <div className='left-sidebar-shortname'>
            <span className='user-shortname-left-sidebar'>{userCode()}</span>
          </div>
        </div>
        <div className='modal-main-body-content'>
          <Link to='/profile'>Profile setting</Link>
          <Link to='/achievements'>Achievements</Link>
        </div>
        <div className='in-modal-logout-wrap'>
          <Link to='/logout'>Log Out</Link>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
