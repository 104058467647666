import React, { lazy, Suspense } from 'react';
import Sidebar from "../common/sidebar";
//import { TimerList } from "../Dashboard/TimerList";
import axios from "axios";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import CheckToken from "../common/check_token";
import ManualPlayButton from "../common/svgs/ManualPlayButton";
import ArrowButton from "../common/svgs/ArrowButton";
//import moment from "moment/moment.js";
import { Calendar } from 'react-date-range';
import moment from 'moment-timezone';
import DeleteButton from "../common/svgs/delete_button";
import Loader from "../common/loader";
import "../../assets/css/calendar_in_dashboard.css";  
import Spinner from 'react-bootstrap/Spinner';
import ProjectModal from "../Projects/projectModal";
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import {alterDisplayTime,alterTimeFormat} from "../common/displayTime.js";
import Footer from '../common/footer';

const TimerList = lazy(() => import("../Dashboard/TimerList"));
const tagStyleDisplay = {
  position: "absolute",
  transform: "translate3d(20px, -44px, 0px)",
  top: "90px",
  left: "0px",
  willChange: "transform",
  display: "block",
  minWidth: "18em", 
};

class Home extends React.Component {
  
  constructor(props) {    
    super();
    this.state = {
      timerStart: 0,
      timerTime: 0,
      timer_start  : 0,
      timer_end : 0,
      project: "",
      project_id: "",
      client: "",
      client_id: "",
      title: "",
      isStop: 1,
      is_billable: 0,
      tagterm : "",
      tagArray: [],
      tag_check_array: [],
      tagOpen: false,
      timer_id: "",
      projectArray: [],
      clientArray: [],
      timeList: [],
      timeList2 : [],
      projectOpen: false,
      searchTerm: "",
      workspaceArray: [],
      workspace_id: 0,
      workspace_title: "",
      isManual: false,
      manualDate  :new Date(),
      displayCalendar : false,
      manualStartTime : moment().format("hh:mm A"),
      manualEndTime : moment().format("hh:mm A"),
      isManuallyChanged : false,
      displayRunningTimerCalendar : false,
      runningTimerStart : "",
      runningTimerStop : moment().format("hh:mm A"),
      runningDuration : "",
      durationFieldError : false,
      offset : 0,
      loading : true,
      spinner : false,
      projectModalShow : false,
      requiredFields : false,
      timeFormat : "",
      timeDurationFormat : "",
      timezone : "",
      dbLockedDate : "",
      isTimeEntryLocked : false,
      is_group_similar : false
    };

    this.wrapperRef = React.createRef();
    this.calendarRef = React.createRef();
    this.runningCalendarRef = React.createRef();
    this.inputRef = React.createRef();
  }

  componentDidMount = () => {
    let timeZone = "";
    if(this.props.auth.user_properties !== null &&
      this.props.auth.user_properties !== undefined && 
      this.props.auth.user_properties !== "" ) {
        timeZone = this.props.auth.user_properties.timeZone;
        if(timeZone === "" || timeZone === null || timeZone === undefined) {
          moment.tz.setDefault();
          let m1 = moment();
          let dd = m1.date();
          let MM = m1.month();
          let yy = m1.year();
    
          let m = moment({ year :yy, month :MM, day :dd})._d;
          this.setState({
            timeFormat : alterTimeFormat(this.props.auth.user_properties.timeFormat),
            timeDurationFormat : this.props.auth.user_properties.timeDurationFormat.split(' ')[0],
            manualDate : m,
            timezone : moment.tz.guess()
          })
        }
        else {
          timeZone = timeZone.split(' ')[0];
          moment.tz.setDefault(timeZone)
          let m1 = moment();
          let dd = m1.date();
          let MM = m1.month();
          let yy = m1.year();
    
          let m = moment({ year :yy, month :MM, day :dd})._d;
          this.setState({
            timeFormat : alterTimeFormat(this.props.auth.user_properties.timeFormat),
            timeDurationFormat : this.props.auth.user_properties.timeDurationFormat.split(' ')[0],
            manualDate : m,
            timezone : this.props.auth.user_properties.timeZone.split(' ')[0]
          })
        }
      }
      else {
        moment.tz.setDefault();
        let m1 = moment();
        let dd = m1.date();
        let MM = m1.month();
        let yy = m1.year();
  
        let m = moment({ year :yy, month :MM, day :dd})._d;
        this.setState({
          timeFormat : "DD-MM-YYYY",
          timeDurationFormat : "Improved",
          manualDate : m,
          timezone : moment.tz.guess()
        })
      } 

    //console.log(moment().format("DD-MM-YYYY hh:mm:ss A"))
    this.runTimer();
    document.addEventListener(
      "click",
      () => {
        this.hideTagDropDown();
      },
      true
    );
    document.addEventListener("mousedown", this.handleClickOutside);
    this.startPage();
    this.clientList();
    //this.timerList();
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ projectOpen: false });
    }
    if(this.calendarRef && !this.calendarRef.current.contains(event.target)) {
      this.setState({displayCalendar : false})
    }
    if(this.runningCalendarRef && !this.runningCalendarRef.current.contains(event.target)
    && this.inputRef && !this.inputRef.current.contains(event.target)
    ) {
      this.setState({displayRunningTimerCalendar:false, durationFieldError : false})
    }
  };

  runTimer = () => {
    let format = this.props.auth.user_properties !== null &&
    this.props.auth.user_properties !== undefined? 
    alterTimeFormat(this.props.auth.user_properties.timeFormat):"hh:mm A";
    if(this.state.isManuallyChanged === false)  {
      this.backgroundTimer = setInterval(() => {
      this.setState({
        manualStartTime : moment().format(format),
        manualEndTime : moment().format(format),//"hh:mm A"),
        runningTimerStop : moment().format(format)
      });
      if(this.state.isStop === 1) {
        this.setState({runningTimerStart : moment().format(format)})
      }
    }, 10 * 1000);
  }
  else {
    clearInterval(this.backgroundTimer);
  }
  }

  groupByEntry = async (results,group_similar) => {
    if(group_similar === true) {
      let newArray = [];
      let totalLength = results.length;
      let uniq = [];
      await results.map((item, i) => {
          newArray = [...newArray,moment(item.timer_start,"X").format("ddd MMM DD YYYY")]
          if(totalLength === newArray.length) {
              uniq = [...new Set(newArray)];
          }
      });
      let data = results;
      let i = 0;
      let len = uniq.length;
      let wholeArray = [];
      let totalTime = [];
      for(i=0;i<len;i++) {
        let time = 0;
        data.map(item => {
            if(uniq[i] === moment(item.timer_start,"X").format("ddd MMM DD YYYY") && item.isStop === 1) {
                time = time + parseInt(item.timerTime);
            }
        })
        let seconds = 0;
        let minutes = 0;
        let hours = 0;
        const timerTime = time;
        seconds = (timerTime / 1000).toFixed();
        // 2- Extract hours:
        hours = parseInt( seconds / 3600); // 3,600 seconds in 1 hour
        if(parseInt(hours/10) === 0) {
          hours = ("0" + hours);
        }
        seconds = seconds % 3600; // seconds remaining after extracting hours
        // 3- Extract minutes:
        minutes = ("0" +parseInt( seconds / 60 )).slice(-2); // 60 seconds in 1 minute
        // 4- Keep only seconds not extracted to minutes:
        seconds = ("0" + (seconds % 60)).slice(-2);
        //return `${hours}:${minutes}:${seconds}`;
        totalTime = [...totalTime,`${hours}:${minutes}:${seconds}`]
    }
  
    for(i=0;i<len;i++) {
      data.map((item,index) => {
          if(uniq[i] === moment(item.timer_start,"X").format("ddd MMM DD YYYY")) {
              wholeArray.push({
                       date: uniq[i],
                       totalTimeOnDay : totalTime[i], 
                       timer_id : item.timer_id,
                       title: item.title,
                       project_id: item.project_id,
                       project:item.project_name,
                       project_color : item.project_color,
                       user_id: item.user_id,
                       client_id: item.client_id,
                       client: item.client_name,  
                       workspace_id: item.workspace_id,
                       tag_id:item.tag_id,
                       tag_title: item.tag_title,
                       tagcolor : item.tagcolor,
                       timerStart: item.timerStart,
                       timerTime: item.timerTime,
                       displayTime: item.displayTime,
                       isStop: item.isStop,
                       is_billable:item.is_billable,
                       crdate: item.crdate,
                       modified_date: item.modified_date,
                       timer_start : item.timer_start,
                       timer_end : item.timer_end
              })
          }
      })
  }
  
      let array = wholeArray;
      let seen = Object.create(null);
      let arrayOfUniqData = array.filter(o => {
          var key = ['date', 'title','project','is_billable','tag_id'].map(k => o[k]).join('|');
          if (!seen[key]) {
              seen[key] = true;
              return true;
          }
      });
  
      let q = [];
      for(i = 0;i<len;i++) {
        let dataArray =  [];
        arrayOfUniqData.map(el => {
          let p = [];
          wholeArray.map(item => {
            if(uniq[i] === moment(item.timer_start,"X").format("ddd MMM DD YYYY")) {
              if(el.date === item.date && el.title === item.title &&
                el.project === item.project && 
                el.is_billable === item.is_billable &&
                el.tag_id === item.tag_id && 
                item.isStop !== 0
                ) {
                p.push(item);
              }
            }
          })
          if(p.length > 0) {
            if(p.length > 1) {
              let arrayVar = 0;
              let time = 0;
              let project = "";
              let length = p.length;
              let tag = "";
              let title = p[0].title;
              let totalTimeOnDay = p[0].totalTimeOnDay;
              let date = p[0].date;
              let timer_id_array = [];
              let maxTime = Math.max.apply(Math, p.map(function(o) { return o.timer_end; }));
              let minTime = Math.min.apply(Math, p.map(function(o) { return o.timer_start; }));
              p.map(newEl => {
                time = time + parseInt(newEl.timerTime);
                //id = id + newEl.timer_id;
                let newArr = [];
                if (!timer_id_array.includes(newEl.timer_id)) {
                  newArr = [...timer_id_array, newEl.timer_id];
                } else {
                  newArr = timer_id_array.filter((a) => a !== newEl.timer_id);
                }
                timer_id_array = newArr;
              });
              let seconds = 0;
              let minutes = 0;
              let hours = 0;
              const timerTime = time;
              seconds = (timerTime / 1000).toFixed();
              // 2- Extract hours:
              hours = parseInt( seconds / 3600); // 3,600 seconds in 1 hour
              if(parseInt(hours/10) === 0) {
                hours = ("0" + hours);
              }
              seconds = seconds % 3600; // seconds remaining after extracting hours
              // 3- Extract minutes:
              minutes = ("0" +parseInt( seconds / 60 )).slice(-2); // 60 seconds in 1 minute
              // 4- Keep only seconds not extracted to minutes:
              seconds = ("0" + (seconds % 60)).slice(-2);
              let tt =  `${hours}:${minutes}:${seconds}`;
              let shiftEl = {
                date : date,
                timer_start : minTime,
                timer_end : maxTime,
                displayTime : tt,
                title : title,
                timer_id : p[0].timer_id,
                timer_id_array : timer_id_array,
                totalTimeOnDay : totalTimeOnDay
              }
              p.unshift(shiftEl);
              dataArray.push(p);
            }
            else {
              dataArray.push(p);
            }
          }
        });
        q.push(dataArray);
      }
          let inc = 0;
          let kk = {};
          for(inc = 0;inc<len;inc++) {
            if(q[inc].length > 0) {
              kk[uniq[inc]] = q[inc];
            }
          }
          return kk;
    }
    else {
      let data = results;
      let newArray = [];
      let len = results.length;
      let uniq = [];
      await results.map((item, i) => {
          newArray = [...newArray,moment(item.timer_start,"X").format("ddd MMM DD YYYY")]
          if(len === newArray.length) {
              uniq = [...new Set(newArray)];
          }
      });
      let i = 0;
      let l = uniq.length;
      let result = [];
      let totalTime = [];
      
      for(i=0;i<l;i++) {
          let time = 0;
          data.map(item => {
              if(uniq[i] === moment(item.timer_start,"X").format("ddd MMM DD YYYY") && item.isStop === 1) {
                  time = time + parseInt(item.timerTime);
              }
          })
          let seconds = 0;
          let minutes = 0;
          let hours = 0;
          const timerTime = time;
          seconds = (timerTime / 1000).toFixed();
          // 2- Extract hours:
          hours = parseInt( seconds / 3600); // 3,600 seconds in 1 hour
          if(parseInt(hours/10) === 0) {
            hours = ("0" + hours);
          }
          seconds = seconds % 3600; // seconds remaining after extracting hours
          // 3- Extract minutes:
          minutes = ("0" +parseInt( seconds / 60 )).slice(-2); // 60 seconds in 1 minute
          // 4- Keep only seconds not extracted to minutes:
          seconds = ("0" + (seconds % 60)).slice(-2);
          //return `${hours}:${minutes}:${seconds}`;
          totalTime = [...totalTime,`${hours}:${minutes}:${seconds}`]
      }
  
      let jsonArray1 =  [];
      for(i=0;i<l;i++) {
         data.map((item,index) => {
             if(uniq[i] === moment(item.timer_start,"X").format("ddd MMM DD YYYY") && 
             item.isStop === 1) {
                 jsonArray1.push({
                      
                          date: uniq[i],
                          totalTimeOnDay : totalTime[i], 
                          timer_id : item.timer_id,
                          title: item.title,
                          project_id: item.project_id,
                          project:item.project_name,
                          project_color : item.project_color,
                          user_id: item.user_id,
                          client_id: item.client_id,
                          client: item.client_name,  
                          workspace_id: item.workspace_id,
                          tag_id:item.tag_id,
                          tag_title: item.tag_title,
                          tagcolor : item.tagcolor,
                          timerStart: item.timerStart,
                          timerTime: item.timerTime,
                          displayTime: item.displayTime,
                          isStop: item.isStop,
                          is_billable:item.is_billable,
                          crdate: item.crdate,
                          modified_date: item.modified_date,
                          timer_start : item.timer_start,
                          timer_end : item.timer_end
                 })
             }
         })
     }
  
      result = jsonArray1.reduce((h, obj) => 
      Object.assign(h, { [obj.date]:( h[obj.date] || [] ).concat(obj) }), {});
      return result;
    }
  }

  /*listFunction = async (results) => {
    
  }*/

  startPage = async () => {
    try {
      let is_group_similar = await this.getProfile();
      const config = {
        headers: {
          Authorization: `Bearer ${this.props.auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let workspace_id = this.props.auth.workspace_id;
      let offset = 0;
      const body = JSON.stringify({ workspace_id, offset });
      let res = await axios.post("/api/usertime/list", body, config);
      if (res.status === 200) {
        let inter_array = [];
        //console.log(res.data)
        //let result = await this.listFunction(res.data.results);
        let final_result = await this.groupByEntry(res.data.results,is_group_similar);
        let offsetLength = res.data.results.length;
        let lastRow ;
        if(res.data.running.length > 0) {
          lastRow = res.data.running[0]
        }
        /*res.data.results.map(item => {
          if(item.isStop === 0) {
            lastRow = item;
          }
        })*/
        if(lastRow == "" || lastRow === undefined || lastRow === null) {
          lastRow = res.data.results[0];
        }
        if (lastRow !== undefined) {
          if (lastRow.isStop === 0) { 
            if (
              lastRow.tag_id !== null &&
              lastRow.tag_id !== undefined &&
              lastRow.tag_id !== ""
            ) {
              let arr = [];
              arr = lastRow.tag_id.split(",");
              let trueArr = [];
              arr.map((item) => {
                trueArr.push(Number(item));
              });
              if (arr.length === trueArr.length) {
                this.setState({ tag_check_array: trueArr });
              }
            }
            /*
            Object.entries(result).map(([make, type]) => {
              Object.values(type).map((data) => {
                inter_array.push(data);
              });
            });
            inter_array = inter_array.filter(
              (item) => item.timer_id != lastRow.timer_id
            );
            let final_array = inter_array.reduce(
              (h, obj) =>
                Object.assign(h, {
                  [obj.date]: (h[obj.date] || []).concat(obj),
                }),
              {}
            );
            */
            let m1 = moment(lastRow.timer_start,'X');
            let dd = m1.date();
            let MM = m1.month();
            let yy = m1.year();
            let hh = m1.hour();
            let mm = m1.minute();
            let ss = m1.second();
            let m = moment({ year :yy, month :MM, day :dd})._d;
            this.setState({
              runningTimerStart : moment.unix(lastRow.timer_start).format(this.state.timeFormat),
              manualDate : m,//new Date(lastRow.timer_start * 1000),//moment(lastRow.timer_start * 1000).format(),
              timeList: final_result,
              timeList2 : res.data.results,
              timerStart: parseInt(lastRow.timerStart),
              timer_start: parseInt(lastRow.timer_start),
              timer_id: lastRow.timer_id,
              isStop: lastRow.isStop,
              is_billable: lastRow.is_billable,
              title: lastRow.title,
              project_id: lastRow.project_id,
              project: lastRow.project_name,
              client_id: lastRow.client_id,
              client: lastRow.client_name,
              workspace_id: lastRow.workspace_id,
              offset : offsetLength,
              loading : false,
            });
            this.selectWorkspace(lastRow.workspace_id);
            this.getWorkspaceInfo(lastRow.workspace_id);
            this.startTimer();
          } else {
            this.setState({
              timeList: final_result,
              timeList2 : res.data.results,
              isStop: 1,
              timerStart: 0,
              timerTime: 0,
              offset : offsetLength,
              loading : false,
            });
            this.projectList();
            this.tagList();
            this.getWorkspaceInfo();
          }
        } else {
          this.setState({
            timeList: final_result,
            timeList2 : res.data.results,
            isStop: 1,
            timerStart: 0,
            timerTime: 0,
            offset : offsetLength,
            loading : false,
          });
          this.projectList();
          this.tagList();
          this.getWorkspaceInfo();
        }
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  getProfile = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${this.props.auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let workspace_id = this.props.auth.workspace_id;
      const body = JSON.stringify({ workspace_id });
      let res = await axios.post("/api/profile/info", body, config);
      if (res.status === 200) {
        if(res.data.results[1][0].is_group_similar_entries === 1) {
          this.setState({is_group_similar : true});
          return true;
        }
        else {
          this.setState({is_group_similar : false});
          return false;
        }
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  titleOnChange = (e) => {
    this.setState({ title: e.target.value });
  };

  searchTermOnChange = (e) => {
    this.setState({ searchTerm: e.target.value });
  };

  updateTitleTag(timerTime, title) {
    if (this.state.timerTime !== 0 && this.state.isStop !== 1) {
          let seconds = (timerTime / 1000).toFixed();
          // 2- Extract hours:
          let hours = parseInt( seconds / 3600); // 3,600 seconds in 1 hour
          if(parseInt(hours/10) === 0) {
            hours = ("0" + hours);
          }
          seconds = seconds % 3600; // seconds remaining after extracting hours
          // 3- Extract minutes:
          let minutes = ("0" +parseInt( seconds / 60 )).slice(-2); // 60 seconds in 1 minute
          // 4- Keep only seconds not extracted to minutes:
          seconds = ("0" + (seconds % 60)).slice(-2);
          //return `${hours}:${minutes}:${seconds}`;
      document.title = `${hours}:${minutes}:${seconds} - ${title}`;
    }
  }

  startTimer = async () => {
    // resume the timer
    if (this.state.isStop === 0 && this.state.timerStart !== 0) {
      try {
        this.timer = setInterval(() => {
          this.setState({
            timerTime: Date.now() - parseInt(this.state.timerStart),
          });
          this.updateTitleTag(this.state.timerTime, this.state.title);
        }, 10);
        const config = {
          headers: {
            Authorization: `Bearer ${this.props.auth.token}`,
            "Content-Type": "application/json",
          },
        };
        let currentDate = moment().format("YYYY-MM-DD HH:mm:ss");//.unix());  
        let timeInSeconds = moment(currentDate).unix();
        let title = this.state.title;
        let workspace_id =
          this.state.workspace_id !== 0 &&
          this.state.workspace_id !== null &&
          this.state.workspace_id !== undefined
            ? this.state.workspace_id
            : this.props.auth.workspace_id;

        let client_id = this.state.client_id;
        let project_id = this.state.project_id;
        let timerStart = this.state.timerStart;
        let timerTime = this.state.timerTime;
        let isStop = this.state.isStop;
        let tag_check_array = this.state.tag_check_array;
        let timer_id = this.state.timer_id;
        let is_billable = this.state.is_billable;
        let timer_start = this.state.timer_start;
        let timer_end = timeInSeconds;

        const body = JSON.stringify({
          timer_id,
          workspace_id,
          title,
          client_id,
          project_id,
          timerStart,
          timer_start,
          timer_end,
          timerTime,
          isStop,
          is_billable,
          tag_check_array,
        });
        let res = await axios.put("/api/usertime/update", body, config);
        /*if(res.status === 200) {
          console.log(moment.unix(this.state.timer_start).format("hh:mm a"))
          this.setState({
            runningTimerStart : moment.unix(this.state.timer_start).format(this.state.timeFormat)
          })
        }*/
      } catch (err) {
        console.log(err);
        if (err.response) {
          console.log(err.response);
        }
      }
    }
    ////////////// start new timer
    else {
      let currentDate = moment().format("YYYY-MM-DD HH:mm:ss");//.unix());  
      let timeInSeconds = moment(currentDate).unix();
      this.setState({ isStop: 0, timerStart: Date.now(),timer_start:timeInSeconds }, 
      async () => {
        this.timer = setInterval(() => {
          this.setState({
            timerTime: Date.now() - parseInt(this.state.timerStart),
          });
          this.updateTitleTag(this.state.timerTime, this.state.title);
        }, 10);
        const config = {
          headers: {
            Authorization: `Bearer ${this.props.auth.token}`,
            "Content-Type": "application/json",
          },
        };
        let title = this.state.title;
        let workspace_id =
          this.state.workspace_id !== 0 &&
          this.state.workspace_id !== null &&
          this.state.workspace_id !== undefined
            ? this.state.workspace_id
            : this.props.auth.workspace_id;
        let client_id = this.state.client_id;
        let project_id = this.state.project_id;
        let timerStart = this.state.timerStart;
        let timerTime = this.state.timerTime;
        let isStop = this.state.isStop;
        let tag_check_array = this.state.tag_check_array;
        let is_billable = this.state.is_billable;
        let timer_start = this.state.timer_start

        const body = JSON.stringify({
          workspace_id,
          title,
          client_id,
          project_id,
          timerStart,
          timer_start,
          timerTime,
          isStop,
          is_billable,
          tag_check_array,
        });
        let res = await axios.post("/api/usertime/create", body, config);
        if (res.status === 200) {
          this.setState({ timer_id: res.data.results.insertId,
            runningTimerStart : moment.unix(timer_start).format(this.state.timeFormat) });
        }
      });
    }
  };

  secondTimer = async (item) => {
    try {
      if (this.state.isStop == 0 && this.state.timerStart !== 0) {
        await this.stopTimer();
      }
      if(this.state.isStop == 0 && this.state.timerStart !== 0) {
        this.callPopOverFunctionEnter();
        setTimeout(() => {
          this.setState({
            showPopOver : false
          })
        }, 3000);
      }
      else {
        let trueArr = [];
        if (
          item.tag_id !== null &&
          item.tag_id !== undefined &&
          item.tag_id !== ""
        ) {
          
          let arr = [];
          arr = item.tag_id.split(",");
    
          arr.map((item) => {
            trueArr.push(Number(item));
          });
        }
        this.selectWorkspace(item.workspace_id);
        this.getWorkspaceInfo(item.workspace_id);
    
        let currentDate = moment().format("YYYY-MM-DD HH:mm:ss");//.unix());  
        let timeInSeconds = moment(currentDate).unix();
    
        this.setState(
          {
            isStop: 0,
            timerStart: Date.now(),
            timer_start : timeInSeconds,
            project_id: item.project_id,
            workspace_id: item.workspace_id,
            client_id: item.client_id,
            project: item.project,
            client: item.client,
            title: item.title,
            tag_check_array: trueArr,
            is_billable: item.is_billable,
          },
          async () => {
            this.timer2 = setInterval(() => {
              this.setState({
                timerTime: Date.now() - parseInt(this.state.timerStart),
              });
              this.updateTitleTag(this.state.timerTime, this.state.title);
            }, 10);
            const config = {
              headers: {
                Authorization: `Bearer ${this.props.auth.token}`,
                "Content-Type": "application/json",
              },
            };
            let title = this.state.title;
            let workspace_id = this.state.workspace_id;
            let client_id = this.state.client_id;
            let project_id = this.state.project_id;
            let timerStart = this.state.timerStart;
            let timerTime = this.state.timerTime;
            let isStop = this.state.isStop;
            let tag_check_array = this.state.tag_check_array;
            let is_billable = this.state.is_billable;
            let timer_start = this.state.timer_start;
    
            const body = JSON.stringify({
              workspace_id,
              title,
              client_id,
              project_id,
              timerStart,
              timer_start,
              timerTime,
              isStop,
              is_billable,
              tag_check_array,
            });
            let res = await axios.post("/api/usertime/create", body, config);
            if (res.status === 200) {
              this.setState({ timer_id: res.data.results.insertId });
            }
          }
        );
      }
    }
    catch(err) {

    }
  };

  callPopOverFunctionEnter = () => {
    let element = document.getElementById("stop_btn_id");
    if(this.state.requiredFields){
      if(this.state.title === "" || this.state.project_id === "" || this.state.project_id === 0) {
        this.setState({
          showPopOver : true,
          popOverTarget : element,
        })
      }
    }
  }

  callPopOverFunctionLeave = () => {
    this.setState({
      showPopOver : false
    })
  }

  checkRequiredFields = () => {
    let element = document.getElementById("stop_btn_id");
    if(this.state.requiredFields){
      if(this.state.title === "" || this.state.project_id === "" || this.state.project_id === 0) {
        return false;
      }
      else {
        return true;
      }
    }
    else {
      return true;
    }
  }

  stopTimer = async () => {
    try{
      if(this.checkRequiredFields()){
        this.setState({ timerTime: 0, timerStart: 0, isStop: 1 ,
          title: "",
          project: "",
          project_id: "",
          client: "",
          client_id: "",
          tag_check_array: [],
          is_billable: 0,
        });
        clearInterval(this.timer);
        clearInterval(this.timer2);
        document.title = `Timely`;
        let currentDate = moment().format("YYYY-MM-DD HH:mm:ss");//.unix());  
        let timeInSeconds = moment(currentDate).unix();
        //let timeInSeconds2 = 
        const config = {
          headers: {
            Authorization: `Bearer ${this.props.auth.token}`,
            "Content-Type": "application/json",
          },
        };
        let title = this.state.title;
        let workspace_id =
          this.state.workspace_id !== 0 &&
          this.state.workspace_id !== null &&
          this.state.workspace_id !== undefined
            ? this.state.workspace_id
            : this.props.auth.workspace_id;
        let client_id = this.state.client_id;
        let project_id = this.state.project_id;
        let timerStart = this.state.timerStart;
        let timerTime = this.state.timerTime;
        let isStop = 1;
        let timer_id = this.state.timer_id;
        let tag_check_array = this.state.tag_check_array;
        let is_billable = this.state.is_billable;
        let timer_start = this.state.timer_start;
        let timer_end = timeInSeconds;
        
        const body = JSON.stringify({
          timer_id,
          workspace_id,
          title,
          client_id,
          project_id,
          timerStart,
          timer_start,
          timer_end,
          timerTime,
          isStop,
          is_billable,
          tag_check_array,
        });
        let res = await axios.put("/api/usertime/update", body, config);
        if (res.status === 200) {
          let m1 = moment();
          let dd = m1.date();
          let MM = m1.month();
          let yy = m1.year();
    
          let m = moment({ year :yy, month :MM, day :dd})._d;
          this.setState({
            title: "",
            project: "",
            project_id: "",
            client: "",
            client_id: "",
            tag_check_array: [],
            is_billable: 0,
            manualDate : m
          });
          this.startPage();
          //this.timerList();
        }
      }
    }
    catch(err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  manualStartTimeChange = (e) => {
    clearInterval(this.backgroundTimer);
    this.setState({manualStartTime:e.target.value,isManuallyChanged:true})
  }

  checkStartTime = () => {
    if(!moment(this.state.manualStartTime,this.state.timeFormat,true).isValid()) {
      this.setState({ manualStartTime : moment().format(this.state.timeFormat)});
    }
  }

  manualEndTimeChange = (e) => {
    clearInterval(this.backgroundTimer);
    this.setState({manualEndTime:e.target.value,isManuallyChanged:true})
  }

  checkEndDate = () => {
    //console.log(moment(this.state.manualEndTime,"h:mm A",true).isValid());
    if(!moment(this.state.manualEndTime,this.state.timeFormat,true).isValid()) {
      this.setState({ manualEndTime : moment().format(this.state.timeFormat)});
    }
  }

  createManualEntry = async () => {
    try {
      if(this.checkRequiredFields()) {
        let dd = this.state.manualDate.getDate();
        let MM = this.state.manualDate.getMonth();
        let yy = this.state.manualDate.getFullYear();
        
        let startTime = `${moment({ year :yy, month :MM, day :dd}).format("YYYY-MM-DD")} ${moment(this.state.manualStartTime, "hh:mm:ss A").format("HH:mm:ss")}`;
        let endTime = `${moment({ year :yy, month :MM, day :dd}).format("YYYY-MM-DD")} ${moment(this.state.manualEndTime, "hh:mm:ss A").format("HH:mm:ss")}`;
        
        let actualStart = moment.utc(startTime).format("YYYY-MM-DD HH:mm:ss");
        let actualEnd = moment.utc(endTime).format("YYYY-MM-DD HH:mm:ss");  
        if(this.state.isTimeEntryLocked === true) {
          let checkDate = moment(actualStart).format("YYYY-MM-DD");
          if(moment(checkDate).isBefore(this.state.dbLockedDate)){
            return null;
          }
        }
        let timer_start = moment(actualStart).unix();
        let timer_end = moment(actualEnd).unix();
        let startUnix = moment.utc(actualStart).valueOf();
        let endUnix =  moment.utc(actualEnd).valueOf();
        let difference = Math.abs(startUnix-endUnix);
        const config = {
          headers: {
            Authorization: `Bearer ${this.props.auth.token}`,
            "Content-Type": "application/json",
          },
        };  
        let title = this.state.title;
        let workspace_id =
          this.state.workspace_id !== 0 &&
          this.state.workspace_id !== null &&
          this.state.workspace_id !== undefined
            ? this.state.workspace_id
            : this.props.auth.workspace_id;
  
        let client_id = this.state.client_id;
        let project_id = this.state.project_id;
        let timerStart = startUnix;
        let timerTime = difference;
        let isStop = 1;
        let tag_check_array = this.state.tag_check_array;
        let is_billable = this.state.is_billable;
        let crdate = actualStart;
        let modified_date = actualEnd;
  
        const body = JSON.stringify({
          workspace_id,
          title,
          client_id,
          project_id,
          timerStart,
          timer_start,
          timer_end,
          timerTime,
          isStop,
          is_billable,
          tag_check_array,
          crdate,
          modified_date
        });
      let res = await axios.post("/api/usertime/manual_entry", body, config);
        if(res.status === 200) {
          this.setState({
            title: "",
            project: "",
            project_id : "",
            client: "",
            tag_check_array: [],
            is_billable: 0,
            manualStartTime : moment().format(this.state.timeFormat),
            manualEndTime : moment().format(this.state.timeFormat),
            isManuallyChanged:false,
            isManual : false
          });
          this.runTimer();
          this.startPage();
          //this.timerList();
        }
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }
  } 

  checkRunningStartTime = () => {
    if(!moment(this.state.runningTimerStart,this.state.timeFormat,true).isValid()) {
      this.setState({runningTimerStart : moment.unix(this.state.timer_start).format(this.state.timeFormat)});
    }
    this.makeChangeInRunningTimer()
  }

  callRunningCalendarTImer = (hours,minutes,seconds) => {
    if(this.state.isStop === 0) {
      this.setState({displayRunningTimerCalendar : true,runningDuration : `${hours}:${minutes}:${seconds}`}) 
    }
  }

  changeRunningTimerStart = (e) => {
    this.setState({runningTimerStart : e.target.value})
  }

  makeChangeInRunningTimer = async (item) => {
    try {
      let startTime, endTime;
      if(item) {
        let dd = item.getDate();
        let MM = item.getMonth();
        let yy = item.getFullYear();
        startTime = `${moment({ year :yy, month :MM, day :dd}).format("YYYY-MM-DD")} ${moment(this.state.runningTimerStart, "hh:mm:ss A").format("HH:mm:ss")}`;
        endTime = `${moment({ year :yy, month :MM, day :dd}).format("YYYY-MM-DD")} ${moment(this.state.runningTimerStop, "hh:mm:ss A").format("HH:mm:ss")}`;
      }
      else {
        let dd = this.state.manualDate.getDate();
        let MM = this.state.manualDate.getMonth();
        let yy = this.state.manualDate.getFullYear();

        startTime = `${moment({ year :yy, month :MM, day :dd}).format("YYYY-MM-DD")} ${moment(this.state.runningTimerStart, "hh:mm:ss A").format("HH:mm:ss")}`;
        endTime = `${moment({ year :yy, month :MM, day :dd}).format("YYYY-MM-DD")} ${moment(this.state.runningTimerStop, "hh:mm:ss A").format("HH:mm:ss")}`;
      }

      let newStartTime ;

      let time = moment().valueOf();
      let time2 = moment(startTime).valueOf();
      if(time2 > time) {
        let comingdate = moment(endTime).date();
        let currentdate = moment().date();
        let d = comingdate-currentdate;
        d = d+ 1;
        newStartTime = moment(startTime).subtract(d,"days");

        //console.log(newStartTime,comingdate,currentdate,d)
      }
      else {
        //console.log(startTime)
        newStartTime = startTime;
      }
      //console.log(startTime,newStartTime,time2>time)
      if(this.state.isTimeEntryLocked === true) {
        let checkDate = moment(newStartTime).format("YYYY-MM-DD");
        if(moment(checkDate).isBefore(this.state.dbLockedDate)){
          return null;
        }
      }
      let timer_start = moment(newStartTime).unix();
      let timer_end = moment(endTime).unix();
      let startUnix = moment(newStartTime).valueOf();
      let endUnix =  moment(endTime).valueOf();
      let difference = Math.abs(startUnix-endUnix);
      const config = {
        headers: {
          Authorization: `Bearer ${this.props.auth.token}`,
          "Content-Type": "application/json",
        },
      };  
      let timer_id = this.state.timer_id;
      let title = this.state.title;
      let workspace_id =
        this.state.workspace_id !== 0 &&
        this.state.workspace_id !== null &&
        this.state.workspace_id !== undefined
          ? this.state.workspace_id
          : this.props.auth.workspace_id;

      let client_id = this.state.client_id;
      let project_id = this.state.project_id;
      let timerStart = startUnix;
      let timerTime = difference;
      let isStop = 0;
      let tag_check_array = this.state.tag_check_array;
      let is_billable = this.state.is_billable;
      let crdate = startTime;
      let modified_date = endTime;
      

      const body = JSON.stringify({
        timer_id,
        workspace_id,
        title,
        client_id,
        project_id,
        timerStart,
        timer_start,
        timer_end,
        timerTime,
        isStop,
        is_billable,
        tag_check_array,
        crdate,
        modified_date
      });
    let res = await axios.put("/api/usertime/edit_manually", body, config);
      if(res.status === 200) {
        this.setState({
          /*title: "",
          project: "",
          client: "",
          tag_check_array: [],
          is_billable: 0,*/
          manualStartTime : moment().format(this.state.timeFormat),
          manualEndTime : moment().format(this.state.timeFormat),
          isManuallyChanged:false
        });
        this.runTimer();
        this.startPage()
      }
    
    } catch (err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }  }
    
    handleDurationChange = (e) => {
      this.setState({runningDuration : e.target.value});
      //setSelectedDuration([item.timer_id])
      console.log("hellio") 
    }

    changeDateInCalendar = (item) => {
      this.setState({manualDate : item});
      this.makeChangeInRunningTimer(item);
    }

    submitDuration = async () => {
      try{
        let endTime = moment().format("YYYY-MM-DD HH:mm:ss");

        if(this.state.runningDuration.match(/([0-9][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]/g) 
          && this.state.runningDuration !== "00:00:00") {
          
            this.setState({durationFieldError : false})
          let arrayOfTime = this.state.runningDuration.split(':');
          let hours = arrayOfTime[0];
          let minutes = arrayOfTime[1];
          let seconds = arrayOfTime[2];

          let startTime = moment().subtract(hours,"hours").subtract(minutes,"minutes").subtract(seconds,"seconds").format("YYYY-MM-DD HH:mm:ss");
          //console.log(startTime,endTime)
          let actualStart = moment(startTime).format("YYYY-MM-DD HH:mm:ss");
          let actualEnd = moment(endTime).format("YYYY-MM-DD HH:mm:ss");
          //console.log(actualStart,actualEnd);
          if(this.state.isTimeEntryLocked === true) {
            let checkDate = moment(actualStart).format("YYYY-MM-DD");
            console.log(this.state.dbLockedDate,checkDate);
            if(moment(checkDate).isBefore(this.state.dbLockedDate)){
              console.log("out of here")
              return null;
            }
          }
          let timer_start = moment(actualStart).unix();
          let timer_end = moment(actualEnd).unix();
          let startUnix = moment(actualStart).valueOf();
          let endUnix =  moment(actualEnd).valueOf();
          let difference = Math.abs(startUnix-endUnix);
          const config = {
            headers: {
              Authorization: `Bearer ${this.props.auth.token}`,
              "Content-Type": "application/json",
            },
          };  
          let title = this.state.title;
          let workspace_id =
          this.state.workspace_id !== 0 &&
          this.state.workspace_id !== null &&
          this.state.workspace_id !== undefined
              ? this.state.workspace_id
              : this.props.auth.workspace_id;
    
          let client_id = this.state.client_id;
          let project_id = this.state.project_id;
          let timerStart = startUnix;
          let timerTime = difference;
          let isStop = 0;
          let tag_id = this.state.tag_id;
          let is_billable = this.state.is_billable;
          let timer_id = this.state.timer_id;
          
    
          const body = JSON.stringify({
            timer_id,
            workspace_id,
            title,
            client_id,
            project_id,
            timerStart,
            timer_start,
            timer_end,
            timerTime,
            isStop,
            is_billable,
            tag_id
          });
          //console.log(body);
        let res = await axios.put("/api/usertime/edit_manually", body, config);
          if(res.status === 200) {
            this.startPage();
          }
        }
        else {
          this.setState({durationFieldError : true})
          //this.startPage();
        }  
      } 
      catch(err) {
        console.log(err);
        if (err.response) {
          console.log(err.response);
        }
      }
    }

  timerList = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${this.props.auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let workspace_id = this.props.auth.workspace_id;
      let offset = 0;
      const body = JSON.stringify({ workspace_id, offset });
      let res = await axios.post("/api/usertime/list", body, config);
      if (res.status === 200) {
        //let result = await this.listFunction(res.data.results);
        let final_result = await this.groupByEntry(res.data.results,this.state.is_group_similar);
        this.setState({ timeList: final_result, timeList2 : res.data.results });
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }
  };
  projectList = async (wid, is_w_admin) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${this.props.auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let workspace_id;
      if (
        this.state.workspace_id !== 0 &&
        this.state.workspace_id !== null &&
        this.state.workspace_id !== undefined
      ) {
        workspace_id = this.state.workspace_id;
      } else {
        workspace_id = wid ? wid : this.props.auth.workspace_id;
      }
      let is_workspace_admin;
      if(is_w_admin === 0 || is_w_admin === 1) {
        is_workspace_admin = is_w_admin;
      }
      else {
        is_workspace_admin = this.props.auth.is_workspace_admin;
      }
      let searchTerm = this.state.searchTerm;
      let is_archived = 0;
      const body = JSON.stringify({
        workspace_id,
        is_workspace_admin,
        is_archived,
        searchTerm,
      });
      let res = await axios.post("/api/project/search", body, config);
      if(res.status === 200) {
        let tempProjectArray = [];
        tempProjectArray.push({
          client_id: 0,
          client_name: null,
          id: 0,
          project_color: "",
          project_name: "",
          workspace_id : this.state.workspace_id,
          isbillable : 0
        })

        res.data.results.map(item => {
          tempProjectArray.push({
              client_id: item.client_id,
              client_name: item.client_name,
              id: item.id,
              is_archived: item.is_archived,
              project_color: item.project_color,
              project_name: item.project_name,
              user_id: item.user_id,
              workspace_id: item.workspace_id,
              isbillable : item.isbillable
          })
        })

        let mixArray = tempProjectArray.reduce(
          (h, obj) =>
            Object.assign(h, {
              [obj.client_name]: (h[obj.client_name] || []).concat(obj),
            }),
          {}
        );
        this.setState({ projectArray: mixArray });
    }
    } catch (err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  clientList = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${this.props.auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let workspace_id = this.props.auth.workspace_id;
      const body = JSON.stringify({ workspace_id });
      let res = await axios.post("/api/client/list", body, config);
      this.setState({ clientArray: res.data.results });
    } catch (err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  handleTagterm = (e) => {
    this.setState({tagterm : e.target.value})
  }

  tagList = async (wid) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${this.props.auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let workspace_id;
      if (
        this.state.workspace_id !== 0 &&
        this.state.workspace_id !== null &&
        this.state.workspace_id !== undefined
      ) {
        workspace_id = this.state.workspace_id;
      } else {
        workspace_id = wid ? wid : this.props.auth.workspace_id;
      }
      let tagTerm = this.state.tagterm !== "" && this.state.tagterm !== undefined? this.state.tagterm : "";
      const body = JSON.stringify({ workspace_id,tagTerm });
      let res = await axios.post("/api/tag/list", body, config);
      this.setState({ tagArray: res.data.results });
    } catch (err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  getWorkspaceInfo = async (wid) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${this.props.auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let workspace_id;
      if (
        this.state.workspace_id !== 0 &&
        this.state.workspace_id !== null &&
        this.state.workspace_id !== undefined
      ) {
        workspace_id = this.state.workspace_id;
      } else {
        workspace_id = wid ? wid : this.props.auth.workspace_id;
      }
      const body = JSON.stringify({ workspace_id });
      let res = await axios.post(
        "/api/workspace/listbyworkspaceid",
        body,
        config
      );
      if (res.status === 200) {
        res.data.results.map((item) => {
          if (item.id === workspace_id) {
            let m;
            if(item.is_time_entry_locked  === 1) {
              let arr = item.locked_date.split('-');
              let dd = arr[0];
              let MM = arr[1];
              let yy = arr[2];
              m = moment({year : yy, month : MM, date : dd}).format("YYYY-MM-DD");
            }
            this.setState({
              workspace_id: item.id,
              workspace_title: item.title,
              requiredFields : item.is_required_fields === 1 ?true : false,
              isTimeEntryLocked : item.is_time_entry_locked  === 1?true:false,
              dbLockedDate : item.is_time_entry_locked  === 1?m:""
            });
          }
        });
        this.setState({ workspaceArray: res.data.results });
      }
    } catch (err) {
      console.log(err.response);
      return err.response;
    }
  };

  selectWorkspace = async (id) => {
    this.setState({ workspace_id: id });
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${this.props.auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let workspace_id = id;
      const body = JSON.stringify({ workspace_id });
      let res = await axios.post("/api/workspace/get", body, config);
      if (res.status === 200) {
        let is_w_admin = res.data.is_workspace_admin;
        let wid = res.data.workspace_id;
        this.getWorkspaceInfo(wid);
        this.projectList(wid, is_w_admin);
        this.tagList(wid);
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  hideTagDropDown = () => {
    this.setState({ tagOpen: false });
  };

  handleCheck = (item) => {
    if(this.state.isStop === 0) {
      this.changeTag(item,this.state.timer_id)
    }
    let newArr = [];
    //newArr = [item];
    if (!this.state.tag_check_array.includes(item)) {
      newArr = [item];
    } else {
      newArr = [];
    }
    this.setState({ tag_check_array: newArr });
  };

  projectClick = (item) => {
    if(this.state.isStop === 0) {
      this.changeProject(item,this.state.timer_id);
      let bb = item.isbillable === 1?0:1;
      this.changeBillable(this.state.timer_id,bb);
      this.setState({
        projectOpen: false,
        client: item.client_name,
        client_id: item.client_id,
        project: item.project_name,
        project_id: item.id,
        is_billable : item.isbillable === 1?1:0
      }); 
    }
    else {
      this.setState({
        projectOpen: false,
        client: item.client_name,
        client_id: item.client_id,
        project: item.project_name,
        project_id: item.id,
        is_billable : item.isbillable === 1?1:0
      });  
    }
  };

  changeProject = async (proj, id) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${this.props.auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let workspace_id = proj.workspace_id;
      let timer_id = id;
      let project_id = proj.id;
      let client_id = proj.client_id;
      let tag, tagcolor;

      const body = JSON.stringify({
        workspace_id,
        timer_id,
        project_id,
        client_id,
      });
      let res = await axios.put("/api/usertime/update_project", body, config);
      if (res.status === 200) {
        let bb = proj.isbillable === 1?0:1;
        this.changeBillable(timer_id,bb);
        this.startPage();
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  changeTag = async (tag_id, timer_id) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${this.props.auth.token}`,
          "Content-Type": "application/json",
        },
      };

      const body = JSON.stringify({ timer_id, tag_id });
      let res = await axios.put("/api/usertime/update_tag", body, config);
      if (res.status === 200) {
        this.startPage();
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  changeBillable = async (timer_id, billable) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${this.props.auth.token}`,
          "Content-Type": "application/json",
        },
      };

      let is_billable = billable === 1 ? 0 : 1;
      const body = JSON.stringify({ timer_id, is_billable });
      let res = await axios.put("/api/usertime/update_billable", body, config);
      if (res.status === 200) {
        this.startPage();
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  changeTitle = async (timer_id, title) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${this.props.auth.token}`,
          "Content-Type": "application/json",
        },
      };
      if (title === "" || title === undefined) {
        this.startPage();
      } else {
        const body = JSON.stringify({ timer_id, title });
        let res = await axios.put("/api/usertime/update_title", body, config);
        if (res.status === 200) {
          this.checkTitle(timer_id)
          this.startPage();
        }
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  checkTitle = async (timer_id) => {  /* function to set default project tag and  billable by 
    taking entered description */
    try{
    let filteredArray = [];
    if(timer_id === this.state.timer_id) {
      let arr;
      if(this.state.timeList2.length > 0) {
        arr = this.state.timeList2.slice(0,10);
      }
      else {
        arr = this.state.timeList2;
      }
      let i = 0;
      arr.map(item => {
        if(item.title == this.state.title) {
          if(timer_id == item.timer_id) {
            return false;
          }
          else {
            i++;
            filteredArray.push(item);
          }
        }
      })
      if(filteredArray.length > 0) {
        const config = {
          headers: {
            Authorization: `Bearer ${this.props.auth.token}`,
            "Content-Type": "application/json",
          },
        };
        let project_id = filteredArray[0].project_id !== null && 
          filteredArray[0].project_id !== undefined ? filteredArray[0].project_id:0;
        let client_id = filteredArray[0].client_id !== null && 
          filteredArray[0].client_id !== undefined ? filteredArray[0].client_id:0;
        let workspace_id = filteredArray[0].workspace_id;
        let tag_id = filteredArray[0].tag_id;
        let is_billable = filteredArray[0].is_billable === 1 ? 1 : 0;

        const body = JSON.stringify({ 
          timer_id, 
          project_id, 
          client_id, 
          workspace_id, 
          tag_id, 
          is_billable 
        });
        let res = await axios.put("/api/usertime/update_parts", body, config);
        if(res.status === 200) {
          this.startPage()
        }
      }
    }
    }
    catch (err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }
  }

  deleteEntry = async () => {
    try {
        let timer_id = this.state.timer_id;
        const config = {
            headers : {
                Authorization : `Bearer ${this.props.auth.token}`,
                "Content-type" : "application/json"
            },
        };

        const body = JSON.stringify({ timer_id });
        let res = await axios.post("/api/usertime/delete_start", body, config);
        if(res.status === 200) {
          let m1 = moment();
          let dd = m1.date();
          let MM = m1.month();
          let yy = m1.year();
          let m = moment({ year :yy, month :MM, day :dd})._d;
          this.setState({
            title: "",
            project: "",
            project_id: "",
            client: "",
            client_id: "",
            tag_check_array: [],
            is_billable: 0,
            timerTime: 0, timerStart: 0, isStop: 1,
            timer_start : 0, timer_end : 0,
            manualDate : m
          }, async () => {
            await this.startPage();
            document.title = `Timely`;
          });
            
        }
    }
    catch(err) {
      console.log(err)
    }
  }

  reload = () => {
    this.startPage();
  }

  hanldeKeydown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (this.state.isStop === 1) {
        let filteredArray = [];
          let arr;
          if(this.state.timeList2.length > 0) {
            arr = this.state.timeList2.slice(0,10);
          }
          else {
            arr = this.state.timeList2;
          }
          let i = 0;
          arr.map(item => {
            if(item.title == this.state.title) {
                i++;
                filteredArray.push(item);
            }
          })
          if(filteredArray.length > 0) {
            let project = filteredArray[0].project_name ?filteredArray[0].project_name:"";
            let client = filteredArray[0].client_name ?filteredArray[0].client_name:"";
            let project_color = filteredArray[0].project_color ?filteredArray[0].project_color:"";
            let project_id = filteredArray[0].project_id !== null && 
              filteredArray[0].project_id !== undefined ? filteredArray[0].project_id:0;
            let client_id = filteredArray[0].client_id !== null && 
              filteredArray[0].client_id !== undefined ? filteredArray[0].client_id:0;
            let workspace_id = filteredArray[0].workspace_id;
            let tag_id = filteredArray[0].tag_id === undefined || 
                        filteredArray[0].tag_id === null || 
                        filteredArray[0].tag_id === "" ?[]:[parseInt(filteredArray[0].tag_id)];
            let is_billable = filteredArray[0].is_billable === 1 ? 1 : 0;

            this.setState({
              project: project,
              project_id: project_id,
              client: client,
              client_id: client_id,
              tag_check_array: tag_id,
              is_billable: is_billable,
              workspace_id : workspace_id,
              project_color : project_color
            })
          }
        this.startTimer();
      }
    }
  };

  callTitleChangeFunction = (timer_id,title) => {
    if(this.state.isStop === 0) {
      this.changeTitle(timer_id,title)
    }
  }

  callBillableChange = () => {
    if(this.state.isStop === 0) {
      this.changeBillable(this.state.timer_id,this.state.is_billable)
    }
  }

  loadMoreItems = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${this.props.auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let workspace_id = this.props.auth.workspace_id;
      let offset = this.state.offset;
      const body = JSON.stringify({ workspace_id, offset });
      let res = await axios.post("/api/usertime/list", body, config);
      if (res.status === 200) {
        /*let inter_array = [];
        Object.entries(this.state.timeList).map(([make, type]) => {
          Object.values(type).map((data) => {
            inter_array.push(data);
          });
        });
        //console.log(inter_array);
        inter_array = inter_array.filter(
          (item) => item.timer_id != this.state.timer_id
        );
        let result = await this.listFunction(res.data.results);*/
        //console.log(this.state.timeList2,res.data.results);
        let conc = this.state.timeList2.concat(res.data.results);
        let final_result = await this.groupByEntry(conc,this.state.is_group_similar);
        /*Object.entries(result).map(([make, type]) => {
          Object.values(type).map((data) => {
            inter_array.push(data);
          });
        });
        let final_array = inter_array.reduce(
          (h, obj) =>
            Object.assign(h, {
              [obj.date]: (h[obj.date] || []).concat(obj),
            }),
          {}
        );*/
        let offset = this.state.offset + res.data.results.length;
        //let l = {...this.state.timeList,...final_result};
        //console.log(l)
        this.setState({ timeList: final_result, offset : offset, loading : false,spinner : false,timeList2 : conc });
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }
  }
  handleProjectModalShow = () => {
    this.clientList();
    this.setState({projectModalShow:true})
  };

  hideModal = () => {
    this.setState({projectModalShow:false})
  };

  reloadFunction = async (item) => {
    await this.projectList();
    await this.clientList();
    this.projectClick(item);
    //this.reload();
  };

  setProject = (item) => {
    this.setState({
      projectOpen: false,
      client: item.client_name,
      client_id: item.client_id,
      project: item.project_name,
      project_id: item.id,
    }); 
  }

  render() {
    let seconds = 0;
    let minutes = 0;
    let hours = 0;
    let duration;
    if (//this.state.timerTime === 0 ||
       this.state.isStop === 1) {
      seconds = "00";
      minutes = "00";
      hours = "00";
    } else {
      const timerTime = this.state.timerTime;
      seconds = (timerTime / 1000).toFixed();
      // 2- Extract hours:
      hours = parseInt( seconds / 3600); // 3,600 seconds in 1 hour
      if(parseInt(hours/10) === 0) {
        hours = ("0" + hours);
      }
      seconds = seconds % 3600; // seconds remaining after extracting hours
      // 3- Extract minutes:
      minutes = ("0" +parseInt( seconds / 60 )).slice(-2); // 60 seconds in 1 minute
      // 4- Keep only seconds not extracted to minutes:
      seconds = ("0" + (seconds % 60)).slice(-2);
      //return `${hours}:${minutes}:${seconds}`;
      if(this.state.displayRunningTimerCalendar === false) {
       duration = `${hours}:${minutes}:${seconds}`
      }
    }

    return (
      <div>
        <CheckToken />
        <Sidebar />
        <div class='header'>
          <div class='container-fluid'>
            <div class='row'>
              <div class='topbar-timer-header-contain'>
                <div class='what-are-you-woring'>
                  <form onSubmit={() => this.handleSubmit()}>
                    <input
                      type='text'
                      class='header-search'
                      placeholder={
                        this.state.isManual
                          ? "What have you done?"
                          : "What are you working on?"
                      }
                      name=''
                      value={this.state.title}
                      onChange={this.titleOnChange}
                      onBlur={()=>this.callTitleChangeFunction(this.state.timer_id,this.state.title)}
                      onKeyDown={(e) => this.hanldeKeydown(e)}
                    />
                  </form>
                </div>
                <div
                  class='nav nav-pills nav-stacked header-folder'
                  ref={this.wrapperRef}
                >
                  <div
                    class={
                      this.state.projectOpen ? "dropdown show" : "dropdown"
                    }
                  >
                    <div
                      class=' dropdown-toggle'
                      data-toggle='dropdown'
                      aria-expanded='true'
                      onClick={() => {
                        this.setState({ projectOpen: true });
                      }}
                    >
                      {this.state.project !== "" &&
                      this.state.project !== null &&
                      this.state.project !== undefined ? (
                        <span style={{ display: "flex" }}>
                          <div>
                            <span>
                              {" "}
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='16'
                                height='13'
                              >
                                <path
                                  fill='#7b7b7b'
                                  fillRule='evenodd'
                                  d='M0 6h16v4.994A2.001 2.001 0 0 1 14.006 13H1.994A1.993 1.993 0 0 1 0 10.994V6zm0-4a2 2 0 0 1 2.004-2h3.05c1.107 0 2.004.895 2.004 2h6.935C15.102 2 16 2.895 16 4H0V2z'
                                />
                              </svg>{" "}
                            </span>
                          </div>
                          <span>
                            &nbsp;&nbsp;{this.state.project} &nbsp;&nbsp;
                          </span>
                          {this.state.client !== null && 
                          this.state.client !== undefined &&  
                          this.state.client !== ""?
                          <span>
                          <i
                            class='fa fa-circle  circle-small'
                            aria-hidden='true'
                          ></i>
                          &nbsp;&nbsp;{this.state.client}
                        </span>
                          
                            :
                          ""
                          }

                        </span>
                      ) : (
                        <span class='folder-icon'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='16'
                            height='13'
                          >
                            <path
                              fill='#7b7b7b'
                              fillRule='evenodd'
                              d='M0 6h16v4.994A2.001 2.001 0 0 1 14.006 13H1.994A1.993 1.993 0 0 1 0 10.994V6zm0-4a2 2 0 0 1 2.004-2h3.05c1.107 0 2.004.895 2.004 2h6.935C15.102 2 16 2.895 16 4H0V2z'
                            />
                          </svg>
                        </span>
                      )}
                    </div>

                    <div
                      class={
                        this.state.projectOpen
                          ? "dropdown-menu filter-by-button-dropdown-wrap show"
                          : "dropdown-menu filter-by-button-dropdown-wrap"
                      }
                      x-placement='top-start'
                      style={{
                        position: "absolute",
                        transform: "translate3d(-50px, -13px, 0px)",
                        top: "0px",
                        left: "0px",
                        willChange: "transform",
                      }}
                    >
                      <div class='Teamdropdown_container'>
                        <div class='filter-search-field-wrap'>
                          <div class='team-search-contain'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='14'
                              height='14'
                              viewBox='0 0 14 14'
                            >
                              <path
                                fill='#cecece'
                                d='M13.9,12.8L10.2,9c0.7-0.9,1.2-2.2,1.2-3.4C11.3,2.5,8.8,0,5.7,0C2.6,0,0,2.6,0,5.7c0,3.2,2.5,5.7,5.7,5.7 c1.2,0,2.3-0.4,3.4-1.2l3.6,3.6C12.8,14,12.9,14,13,14s0.1,0,0.2-0.1l0.8-0.8C14,13.1,14,12.9,13.9,12.8z M9.8,5.7 c0,2.2-1.8,4-4.1,4s-4.1-1.8-4.1-4c0-2.2,1.9-4.1,4.1-4.1C8,1.7,9.8,3.5,9.8,5.7z'
                              ></path>
                            </svg>
                            <input
                              type='text'
                              tabIndex='4'
                              placeholder='Find Project...'
                              class='team-search-field'
                              value={this.state.searchTerm}
                              onChange={this.searchTermOnChange}
                              onKeyUp={this.projectList}
                            />
                          </div>
                        </div>
                        <div class='team-list-contain-wrap'>
                          <div class='team-list-inner-box'>
                            <div
                              class='virtualized__List'
                              style={{
                                position: "relative",
                                overflow: "hidden",
                              }}
                            >
                              <div class='select-project-inner-popup-wrkspace'>
                                <div class='wrkspce-content-field'>
                                  <span>Workspace</span>
                                </div>
                                <div class='choose-workspace-options popdropdown-inner-text dropdown'>
                                  <div
                                    class='popdropdown-button dropdown-toggle'
                                    data-toggle='dropdown'
                                  >
                                    <span>{this.state.workspace_title}</span>{" "}
                                    &nbsp;
                                    <i
                                      class='fa fa-angle-right'
                                      aria-hidden='true'
                                    ></i>
                                  </div>
                                  <div
                                    class='dropdown-menu'
                                    style={{ top: "14px" }}
                                  >
                                    <ul class='popdropdown-inner-drop-cont'>
                                      {this.state.workspaceArray.map(
                                        (item, index) => (
                                          <li
                                            onClick={() => {
                                              this.setState({
                                                workspace_title: item.title,
                                              });
                                              this.selectWorkspace(item.id);
                                            }}
                                            key={index}
                                          >
                                            <a href='#'>{item.title}</a>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div
                                class='inner-scrollar-container'
                                role='rowgroup'
                                style={{
                                  overflow: "hidden",
                                  position: "relative",
                                }}
                              >
                                {Object.entries(this.state.projectArray).map(
                                  ([client_name, data], index) => (
                                    <div key={index}>
                                      <div style={{ width: "100%" }}>
                                        <div class='top-lebel-wrap'>
                                          <span class='label-text'>
                                            {client_name === "null"
                                              ? "No Client"
                                              : client_name}
                                          </span>
                                        </div>
                                      </div>
                                      {Object.values(data).map((item, i) => (
                                        <div
                                          style={{ width: "100%" }}
                                          onClick={() => {
                                            this.projectClick(item);
                                          }}
                                          key={i}
                                        >
                                          <div class='filter-by-check-wrap'>
                                            <div class='workspace-active-status'>
                                              <span
                                                style={{
                                                  backgroundColor:
                                                    item.project_color,
                                                }}
                                              >
                                                .
                                              </span>
                                            </div>
                                            <div class='team-name-filter-by'>
                                              <div title='webteam'>
                                                {item.id === 0? "No Project":item.project_name}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class='create-project-in-serchbox'>
                          <a href='javascript:void(0)' onClick={()=>this.handleProjectModalShow()}>
                            <i class='fa fa-plus' aria-hidden='true'></i>New
                            Project
                          </a>
                        </div>
                      </div>
                    </div>
                    {/*<!-- dropdown end here -->*/}
                    {/*<!-- dropdown end here -->*/}
                  </div>
                </div>
                <div
                  class='nav nav-pills nav-stacked header-tag-icon'
                  onClick={() => {
                    this.setState({ tagOpen: !this.state.tagOpen });
                  }}
                >
                  <div
                    class={this.state.tagOpen ? "dropdown show" : "dropdown"}
                  >
                    <div
                      class='dropdown-toggle'
                      data-toggle='dropdown'
                      href='javascript:void(0)'
                      aria-expanded='false'
                    >
                      <span class='inner-tag-icon'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='17'
                          height='17'
                        >
                          <path
                            d='M0 6.002c0 1.103.633 2.63 1.416 3.414l6.168 6.168a1.996 1.996 0 0 0 2.828.004l5.176-5.176c.78-.78.78-2.045-.004-2.828L9.416 1.416C8.634.634 7.113 0 6.002 0H1.998A1.993 1.993 0 0 0 0 1.998v4.004zM4 6a2 2 0 1 0 0-4 2 2 0 0 0 0 4z'
                            fill='#CECECE'
                            fillRule='evenodd'
                          ></path>
                        </svg>
                      </span>
                    </div>
                    <div
                      class={
                        this.state.tagOpen
                          ? "dropdown-menu show"
                          : "dropdown-menu"
                      }
                      x-placement='top-start'
                      style={{
                        position: "absolute",
                        transform: "translate3d(0px, -13px, 0px)",
                        top: "0px",
                        left: "0px",
                        willChange: "transform",
                      }}
                    >
                      <div class='dropdown-content-heading folder-inner-serchbox'>
                        <form>
                          <input type='text' 
                          placeholder='Add/filter tags' 
                            value={this.state.tagterm} 
                            onChange={this.handleTagterm} 
                            onKeyUp={this.tagList}
                          />
                        </form>
                      </div>
                      <div class='dropdown-content-body'>
                        {this.state.tagArray.map((item, index) => (
                          <div class='find-tags-field-item' key={index}>
                            <div class='custom-checkbox-wrap'>
                              <label>
                                <span
                                  class={
                                    this.state.tag_check_array.includes(item.id)
                                      ? "custom-checkboxes selected"
                                      : "custom-checkboxes"
                                  }
                                >
                                  <input
                                    type='checkbox'
                                    name='filter-item[]'
                                    onClick={() => this.handleCheck(item.id)}
                                  />
                                </span>
                              </label>
                            </div>
                            <span style={{color:item.tagcolor}}>
                            {item.tag_title}
                            </span>
                            
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div class='nav nav-pills nav-stacked header-dollar-icon'>
                  <div class='dropdown'>
                    <div
                      class='dropdown-toggle'
                      data-toggle='dropdown'
                      href='#'
                    >
                      <span
                        class='dollar-inner'
                        onClick={() => {this.callBillableChange();
                          this.setState({
                            is_billable: this.state.is_billable === 1 ? 0 : 1,
                          });
                        }}
                      >
                        <svg width='12' height='18'>
                          <path
                            d='M2.5 12C3 13.5 4 14.5 6 14.5s3.5-1.2 3.5-2.7c0-4-7-1.6-7-5.6C2.5 4.7 4 3.5 6 3.5c1.5 0 3 1 3.5 2.5M6 2v14'
                            fill={
                              this.state.is_billable === 1 ? "#6c9c62 " : "none"
                            }
                            fillRule='evenodd'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='2'
                          ></path>
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  class='header-top-start-timer-box'
                  style={this.state.isManual ? { display: "none" } : {}}
                >
                  <div class='time-dur'>
                    <input
                      type='text'
                      class='time-duration-field-top'
                      id='enhanced-input-input'
                      onClick={()=>this.callRunningCalendarTImer(hours,minutes,seconds)}
                      onChange={(e) => this.handleDurationChange(e)}
                      onBlur={() => this.submitDuration()}
                      style={{ 
                        display:this.state.displayRunningTimerCalendar === false?"none":"block",
                        border:this.state.durationFieldError?"2px solid #F61C1C":"",
                      }}
                      value={this.state.displayRunningTimerCalendar === false?
                        `${hours}:${minutes}:${seconds}`
                        :duration}
                        ref={this.inputRef}
                    />
                    <span class='time-duration-field-top' 
                      style={{ fontSize:18,
                      display:this.state.displayRunningTimerCalendar === false?"block":"none"}}
                      onClick={()=>this.callRunningCalendarTImer(hours,minutes,seconds)}
                    >
                    {this.state.displayRunningTimerCalendar === false?
                        `${hours}:${minutes}:${seconds}`
                        :duration}
                    </span>
                  </div>
                  <div class='start-time-btn'>
                    {this.state.isStop === 1 ? (
                      <div class='play_btn' onClick={this.startTimer}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='36'
                          height='36'
                          viewBox='0 0 36 36'
                          class='start-play-btn'
                        >
                          <g fill='none' fillRule='evenodd'>
                            <rect
                              width='36'
                              height='36'
                              fill='#4bc800'
                              rx='18'
                            ></rect>
                            <path
                              fill='#ffffff'
                              d='M13 11.994c0-1.101.773-1.553 1.745-.997l10.51 6.005c.964.55.972 1.439 0 1.994l-10.51 6.007c-.964.55-1.745.102-1.745-.997V11.994z'
                            ></path>
                          </g>
                        </svg>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.isStop === 0 ? (
                      <div
                        class='stop_btn'
                        onClick={this.stopTimer}
                        //style={{ display: this.state.hidden ? "none" : "" }}
                        id="stop_btn_id"
                        onMouseEnter={()=>this.callPopOverFunctionEnter()}
                        onMouseLeave={()=>this.callPopOverFunctionLeave()}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='36'
                          height='36'
                          viewBox='0 0 36 36'
                          class='start-pause-btn'
                        >
                          <g fill='none' fillRule='evenodd'>
                            <rect
                              width='36'
                              height='36'
                              fill='#e20505'
                              rx='18'
                            ></rect>
                            <rect
                              width='14'
                              height='14'
                              x='11'
                              y='11'
                              fill='#ffffff'
                              rx='1.5'
                            ></rect>
                          </g>
                        </svg>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div
                  class='header-top-calander-timer-box stop-timer-btn-cont'
                  style={
                    this.state.isManual
                      ? { display: "flex" }
                      : { display: "none" }
                  }
                >
                  <div class='time-dur'>
                    <input
                      type='text'
                      class='time-duration-field-top'
                      id='calander-input'
                      style={{ height: "66px", width:"143px" }}
                      value={this.state.manualStartTime}//{`${this.state.manualStartTime} ${moment(this.state.manualDate).format("MM/DD")}`}
                      onClick={()=>this.setState({displayCalendar : true})}
                      onChange={(e)=>{this.setState({isManuallyChanged:true});this.manualStartTimeChange(e)}}
                      onBlur={this.checkStartTime}
                    />
                  </div>
                  <div class='timer-btween-arrow'>
                    <ArrowButton />
                  </div>
                  <div class='time-dur'>
                    <input
                      type='text'
                      class='time-duration-field-top'
                      id='timer-input'
                      style={{ height: "66px" }}
                      value={this.state.manualEndTime}
                      onChange={(e)=>{this.setState({isManuallyChanged:true});this.manualEndTimeChange(e)}}
                      onBlur={this.checkEndDate}
                    />
                  </div>
                  <div onClick={()=>this.createManualEntry()}
                    onMouseEnter={()=>this.callPopOverFunctionEnter()}
                    onMouseLeave={()=>this.callPopOverFunctionLeave()}
                    id="stop_btn_id"
                  >
                    <ManualPlayButton />
                  </div>
                </div>

                <div class='time-clock-box' 
                  style={{marginLeft:"1%",marginRight:"1%",display:this.state.isStop === 0?"":"none"}}
                  onClick={()=>this.deleteEntry()}
                >
                  <DeleteButton />
                </div>
                <div class='top-header-last-right' style={{display:this.state.isStop === 1?"":"none"}}>
                  <div
                    class='time-clock-box'
                    onClick={() => {
                      this.setState({ isManual: false });
                    }}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='25'
                      height='18'
                      viewBox='0 0 16 16'
                    >
                      <path
                        fill={this.state.isManual ? "#cecece" : "#4AC700"}
                        fillRule='evenodd'
                        d='M7.001 7.948L7 8c0 .556.448 1 1 1h3c.556 0 1-.448 1-1 0-.556-.448-1-1-1H9V3.003a1 1 0 1 0-2 0v4.894l.001.051zM8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16z'
                      ></path>
                    </svg>
                  </div>
                  <div
                    class='below-time-clock-opt'
                    onClick={() => {
                      this.setState({ isManual: true });
                    }}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='25'
                      height='15'
                      viewBox='0 0 14 12'
                    >
                      <path
                        fill={this.state.isManual ? "#4AC700" : "#cecece"}
                        fillRule='evenodd'
                        d='M0 1c0-.552.45-1 1.007-1h11.986C13.55 0 14 .444 14 1c0 .552-.45 1-1.007 1H1.007A1.001 1.001 0 0 1 0 1zm0 10c0-.552.45-1 1.007-1h11.986c.556 0 1.007.444 1.007 1 0 .552-.45 1-1.007 1H1.007A1.001 1.001 0 0 1 0 11zm0-5c0-.552.45-1 1.007-1h11.986C13.55 5 14 5.444 14 6c0 .552-.45 1-1.007 1H1.007A1.001 1.001 0 0 1 0 6z'
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div class="datepicker datepicker-dropdown dropdown-menu datepicker-orient-left datepicker-orient-bottom" 
          style={{display:this.state.displayCalendar?"block":"none",
          top: "3%", left: "66%", zIndex: "10",}} 
          ref={this.calendarRef}
        >
          <Calendar onChange={item => this.setState({manualDate : item})} 
            weekStartsOn={this.props.auth.user_properties !==null && 
              this.props.auth.user_properties !== undefined ? 
              this.props.auth.user_properties.firstDayOfWeek:0} 
            date={this.state.manualDate}  />
        </div>

        <div class="datepicker datepicker-dropdown dropdown-menu datepicker-orient-left datepicker-orient-bottom" 
          style={{display:this.state.displayRunningTimerCalendar?"block":"none",
                top: "3%", left: "66%", zIndex: "10"}} 
          ref={this.runningCalendarRef}
        >
          <div style={{display:"flex"}} >
            <div class="time-dur">
              <input type="text" class="time-duration-field-top" id="timer-input" 
              value={this.state.runningTimerStart} 
              onChange={this.changeRunningTimerStart}
              onBlur={()=>this.checkRunningStartTime()}
              style={{width:"91%",marginLeft:"6%",marginRight:"3%"}}/>
            </div>
            <div class="time-dur">
              <input type="text" class="time-duration-field-top" id="timer-input" 
                value={this.state.runningTimerStop} 
                //onChange={this.changeRunningTimerStop}
                readOnly={true}
              style={{width:"91%",marginRight:"6%",marginLeft:"3%"}}/>
            </div>
          </div>
          <Calendar maxDate={new Date()} onChange={item => this.changeDateInCalendar(item)} 
          //onChange={date => {
            //this.changeDateInCalendar(moment(`${moment(date).format('YYYY-MM-DDThh:mm:ss')}${moment().tz(this.state.timezone).utcOffset()/60}`));
            // This is to get the offset from a timezone: handleChange(moment(`${moment(date).format('YYYY-MM-DDThh:mm:ss')}${moment.tz('US/Pacific').format('Z')}`));
          //}}
            weekStartsOn={this.props.auth.user_properties !==null && 
              this.props.auth.user_properties !== undefined ? 
              this.props.auth.user_properties.firstDayOfWeek:0}   
            //item => this.setState({manualDate : item})}
            date={this.state.manualDate}  />
        </div>

        <div class='content-wrap' id='wrapper'>
          <div class='main'>
            <Suspense fallback={
              <Loader />
            }>
            <TimerList
              callBack={this.secondTimer}
              list={this.state.timeList}
              titleChange={this.changeTitle}
              billableChange={this.changeBillable}
              callTagChange={this.changeTag}
              callProjectChange={this.changeProject}
              reloadDashBoard={this.reload}
              group_similar={this.state.is_group_similar}
            />
            </Suspense>
            <Overlay
              show={this.state.showPopOver}
              target={this.state.popOverTarget}
              placement={"bottom"}
              //container={ref.current}
              containerPadding={20}
            >
              <Popover id="popover-contained">
                <Popover.Title><strong>Add Description and Project  </strong></Popover.Title>
                <Popover.Content>
                  Fill out the required fields to stop the timer.
                </Popover.Content>
              </Popover>
            </Overlay>
            <ProjectModal 
              show={this.state.projectModalShow}
              handleClose={this.hideModal}
              reloadList={this.reloadFunction}
              //setProjectInDashBoard={this.setProject}
            />

              <div class="in-timer_load-moreBtn-wra" style={{
                display:this.state.loading === true || this.state.spinner === true ?"none":"",
                marginBottom : "10%"
              }}>
              <button class="load-more" onClick={()=>{this.loadMoreItems();
                  this.setState({ spinner : true})}} 
              >Load more</button>
            </div>
            
            <div class="in-timer_load-moreBtn-wra" style={{marginBottom : "10%",
              display : this.state.spinner?"":"none"}}>
              <Spinner animation="border" role="status" size="lg" />
            </div>
            
            <section id='main-content'>
              <div class='row'></div>
            </section>
            
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  invite_ids : state.invite_ids
});

export default connect(mapStateToProps)(Home);
