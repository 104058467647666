import React from "react";

export default function Footer() {
  /**/
  const APP_NAME = "Timeily";
  var style = {
    //backgroundColor: "#F8F8F8",
    borderTop: "1px solid #E7E7E7",
    textAlign: "center",
    padding: "20px",
    //position: "fixed",
    left: "0",
    bottom: "0",
    height: "60px",
    width: "80%",
  };

  var phantom = {
    //display: "block",
    padding: "10px",
    height: "50px",
    width: "100%",
    marginTop: "-10px",
    marginLeft: "220px",
    //display : 'flex'
  };
  const fontSemiWeight = { fontWeight: "bold" };
  const textGray = {color: '#455560'}
  const textMutedMx2 = {color: 'rgba(#72849a, 0.7)',marginLeft: '0.5rem', marginRight: '0.5rem'}
 
  return (
    <footer style={phantom}>
      <div
        style={style}
        className="d-flex justify-content-between align-items-center"
      >
        <span>
          Copyright &copy; {`${new Date().getFullYear()}`}{" "}
          <span style={fontSemiWeight}>{`${APP_NAME}`}</span> All rights
          reserved.
        </span>
        <div>
          <a
            style={
                textGray
            }
            href="/#"
            onClick={(e) => e.preventDefault()}
          >
            Term & Conditions
          </a>
          <span style={textMutedMx2} > | </span>
          <a
            style={
                textGray
            }
            href="/#"
            onClick={(e) => e.preventDefault()}
          >
            Privacy & Policy
          </a>
        </div>
      </div>
    </footer>
  );
}

/**
 *     return(
        <div className="fixed-bottom">  
            <Navbar color="dark" dark>
                <Container>
                    <NavbarBrand>Footer</NavbarBrand>
                </Container>
            </Navbar>
        </div>
 */