import React, { useEffect, useState } from "react";
import SideBar from "../common/sidebar";
import NewWorkspace from "../../assets/images//newworkspace.png";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { loadUser } from "../../actions/auth";
import { LOGIN_SUCCESS } from "../../actions/types";
import CheckToken from "../common/check_token";
import Footer from "../common/footer";

export default function Workspace() {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [title, setWorkspace] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {}, []);

  const handleWorkspaceChange = (e) => {
    setWorkspace(e.target.value);
  };

  const validateForm = () => {
    let errors = {};
    let formIsValid = true;
    if (title === "") {
      formIsValid = false;
      errors["title"] = "Please enter the Workspace Title";
    }
    setErrors(errors);
    return formIsValid;
  };

  const hanldeKeydown = () => {
    document.onkeypress = (e) => {
      e = e || window.event;
      if (e.keyCode === 13) {
        e.preventDefault();
        if (validateForm()) {
          createWorkspace();
        }
      }
    };
  };

  const createWorkspace = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${auth.token}`,
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ title });
    try {
      if (validateForm()) {
        let res = await axios.post("/api/workspace/create", body, config);
        if (res.status === 200) {
          //dispatch({type:LOGIN_SUCCESS,payload:res.data})
          dispatch(loadUser(res.data));
          window.location.reload();
        }
      }
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  return (
    <div>
      <CheckToken />
      <SideBar />
      <div class="content-wrap" id="wrapper">
        <div class="main">
          <div class="create-new_workspace-container">
            <div class="new-workspace-top-page-steps">
              <div class="steps-contain">
                {/*<div class="step-item active-step"></div>
                        <div class="step-item"></div>
                        <div class="step-item"></div>*/}
              </div>
            </div>
            <div class="create-new-img">
              <img
                src={NewWorkspace}
                class="insight-image"
                alt="Create new workspace"
              />
            </div>
            <div class="In_wrk_create_title">
              <span>Create a workspace</span>
            </div>
            <div class="create_new_Info_contain">
              <div class="sub_content">
                <span>
                  Give your workspace a name. Don't worry, you can change it
                  later.
                </span>
              </div>
            </div>
            <div class="create_new-wrokspace-form-wrap">
              <form id="newworkspace_name">
                <div class="field-seprator">
                  <label class="create_field_label">
                    <span>Workspace Name</span>
                  </label>
                  <div class="new_workspace_field">
                    <input
                      type="text"
                      name="workspace"
                      value={title}
                      onChange={handleWorkspaceChange}
                      onKeyDown={hanldeKeydown()}
                    />
                    <span>{errors.title}</span>
                  </div>
                </div>
                <button
                  type="button"
                  class="create_workspace_btn"
                  onClick={(e) => createWorkspace(e)}
                >
                  <span>Continue</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
