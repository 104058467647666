import React from "react";
import under_construction from "../../assets/images/under_construction.png";
import Sidebar from "../common/sidebar";
import { BlackLogo } from "../common/BlackLogo";
import Footer from "./footer";

export default function App() {
  return (
    <>
      <Sidebar />
      <div class='content-wrap' id='wrapper'>
        <div class='main'>
          <div class='Insights-content-wrapper'>
            <div>
              <BlackLogo />
            </div>
            <h1 class='main-title'>
              <span>Timeily Upcoming feature</span>
            </h1>
            <div class='insight-subtitle'>
              <span>Transform your business intelligence</span>
            </div>

            <div class='insight-upgrade-info'>
              <span>
                Learn which projects and employees are bringing in the most
                revenue.
                <br />
                Available to Premium and Enterprise plans.
              </span>
              <a href='#' target='_blank' class='insight-links'>
                <span>Learn more</span>
              </a>
            </div>

            <button type='button' class='insights-upgrade-btn-contain'>
              <div class='upgrade-btn-content'>
                <span>Upgrade</span>
              </div>
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
