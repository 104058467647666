import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

//import "../src/assets/css/dataTables.bootstrap.min.css";
import "../src/assets/css/font-awesome.min.css";
import "../src/assets/css/fullcalendar.css";
import "../src/assets/css/helper.css";
//import "../src/assets/css/jquery-ui.min.css";
//import "../src/assets/css/materialize.min.css";
import "../src/assets/css/menu.css";
import "../src/assets/css/pignose.calendar.min.css";
import "../src/assets/css/register.css";
import "../src/assets/css/select2.min.css";
import "../src/assets/css/sidebar.css";
import "../src/assets/css/style.css";
import "../src/assets/css/sweetalert.css";
import "../src/assets/css/custom.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
require("dotenv").config();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
