import React from "react";
import {useDispatch} from "react-redux";
import {logoutUser} from "../../actions/auth";

function Logout () {

  const dispatch = useDispatch();
    const onLogout = () => {
        dispatch(logoutUser());
        window.location.href = '/login';
      }
         return( onLogout() );
      
}
export default Logout;