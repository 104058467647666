import { SET_INVITE, REMOVE_INVITE } from "../actions/types";

export const setInvite = (invite_id,wid) => (dispatch) => {
  dispatch({
    type: SET_INVITE,
    payload: {invite_id : invite_id,invite_wid : wid},
  });
};

export const removeInvite = () => async(dispatch) => {
    dispatch({type:REMOVE_INVITE});
}
