import React from "react";

const PassWordCheck = (props) => {
  return (
    <div>
      <table>
        <tr>
          <td style={{ width: "10%", padding: "2%" }}>
            <span className={props.length ? "active-condition-number" : ""}>
              <i class='fa fa-check-circle' aria-hidden='true'></i>&nbsp;8 or
              more characters
            </span>
          </td>
          <td style={{ width: "10%", padding: "2%" }}>
            <span className={props.number ? "active-condition-digits" : ""}>
              <i class='fa fa-check-circle' aria-hidden='true'></i>&nbsp;Digits
              0...9
            </span>
          </td>
        </tr>
        <tr>
          <td style={{ width: "10%", padding: "2%" }}>
            <span className={props.letter ? "active-condition-letters" : ""}>
              <i class='fa fa-check-circle' aria-hidden='true'></i>&nbsp;Letters
              a...z and A...Z
            </span>
          </td>
          <td style={{ width: "10%", padding: "2%" }}>
            <span className={props.special ? "active-condition-special" : ""}>
              <i class='fa fa-check-circle' aria-hidden='true'></i>
              &nbsp;Characters !@#$
            </span>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default PassWordCheck;
