import React, { useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import {useSelector, useDispatch} from "react-redux";
import {LOGIN_SUCCESS} from "../../actions/types";
import {loadUser} from "../../actions/auth";

export default function ConfirmationPage () {
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        checkUser();
    },[]);

    const  checkUser = async () => {
        try {
        let url_string = window.location.href;
        var url = new URL(url_string);
        var c = url.searchParams.get("user");
        if(c) {
            let buff = Buffer.from(c, 'base64');
            let text = buff.toString('ascii');
            let data = text.split(' ');
            let email = data[1];
            let user_id = data[0]; 
            
            const config = {
                headers: {
                  "Content-Type": "application/json",
                },
            };
            
            const body = JSON.stringify({ email, user_id });
            let res = await axios.post("/api/register/confirm", body, config );    
            if(res.status === 200) {
                if(res.data.confirmation === true) {
                    //dispatch({ type: LOGIN_SUCCESS, payload: res.data });
                    dispatch(loadUser(res.data));
                    history.push("/dashboard");
                    //loadUser(res.data);
                    //history.push("/login")
                }
                else {
                    history.push("/login")
                }
            }
            else {  
                history.push("/");
            }        
        }
    }
      catch (err) {
          console.log(err)
          if(err) {
              console.log(err.response)
          }
      }
    }

    return (
        <div>
            <h2>
                The Confirmation Page .... !
            </h2>
        </div>
    )
}