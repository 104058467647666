import React from "react";
import "../../assets/css/register.css";
import robot from "../../assets/images/robot.png";
import axios from "axios";
import { Navbar } from "../common/Navbar";
import PropTypes from "prop-types";
import { login,loadUser } from "../../actions/auth";
import { bindActionCreators } from "redux";
import Alert from "../common/Alert";
import { connect } from "react-redux";
import { NavLink, Redirect } from "react-router-dom";
import PassWordCheck from "../common/PassWordCheck";
import GoogleLogin from 'react-google-login';
import scriptjs from "scriptjs";
import { Modal, Button } from "react-bootstrap";
import { setInvite, removeInvite } from "../../actions/invite_ids";
import { setAlert } from "../../actions/alert";

var generator = require("generate-password");

class Login extends React.Component {
  constructor(props) {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
      hidden: true,
      isAuthenticated: false,
      workspace_id: 0,
      invite_id: 0,
      modalshow: false,
      password_check: {
        length: false,
        number: false,
        letter: false,
        special: false,
      },
    };
  }
  componentDidMount() {
    this.checkUser();
    this.checkApple();
    const { isAuthenticated } = this.props;
    if (isAuthenticated) {
      this.props.history.push("/dashboard");
    }
    scriptjs.get('https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js', 
    () => {
      const params = {
        clientId: 'timeily.com',
        redirectURI: 'https://timeily.com/api/apple/verify',
        scope: 'name email',
        };
      window.AppleID.auth.init(params);
    });
  }

  signin = async (res) => {
    try {
      const googleresponse = {
        Name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.googleId,
        Image: res.profileObj.imageUrl,
        ProviderId: 'Google'
      };
      const config = {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      };
      let workspace_id = this.state.workspace_id;
      let invite_id = this.state.invite_id;
      const body = JSON.stringify({
        email : googleresponse.email,
        workspace_id,
        invite_id
      });
      //console.log(body)
      let res1 = await axios.post("/api/google/signin",body,config);
      //console.log(res1)
      if(res1.status === 200) {
        await this.props.loadUser(res1);
        await this.props.removeInvite();
        if (this.props.isAuthenticated === true) {
          this.setState({ isAuthenticated: true });
        }
      }
    }
    catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response)
        const errors = error.response.data.errors;
        if(errors) {
          errors.forEach((error) => this.props.setAlert(error.msg, "danger"));
        }
      }

    
      //console.log(error);
      /*if (error.response) {
        let errors = {};
        console.log(error.response)
        if(error.response.status !== 500) {
          errors["email"] = error.response.data.errors[0].msg;
          this.setState({
            errors: errors,
          });
        }
      }*/
    }
  }

  checkUser = async () => {
    let url_string = window.location.href;
    var url = new URL(url_string);
    var c = url.searchParams.get("data");
    if (c) {
      let buff = Buffer.from(c, "base64");
      let text = buff.toString("ascii");
      let data = text.split(" ");
      let email = data[1];
      let workspace_id = data[0];
      let invite_id = data[2];
      await this.props.setInvite(invite_id,workspace_id);
      this.setState({
        email: email,
        workspace_id: workspace_id,
        invite_id: invite_id,
      });
    }
  }

  checkApple = async () => {
    try {
      let url_string = window.location.href;
      let url = new URL(url_string);
      let apple = url.searchParams.get("apple");
      if(apple) {
        let buff = Buffer.from(apple, "base64");
        let text = buff.toString("ascii");
        let data = text.split(" ");
        
        let app_id = data[0];    
        let email = data[1];

        const config = {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        };
        let password ;
        if(email !== null && email !== undefined) {
          password = generator.generate({
          length: 10,
          numbers: true
         });
        } 
        let workspace_id,invite_id;
        if(this.props.invite_ids.payload !== undefined && 
          this.props.invite_ids.payload !== null && 
          this.props.invite_ids.payload !== "") {
          workspace_id = this.props.invite_ids.payload.invite_wid;
          invite_id = this.props.invite_ids.payload.invite_id;
        }
        else {
          workspace_id = this.props.invite_ids.invite_wid;
          invite_id = this.props.invite_ids.invite_id;
        }

        const body = JSON.stringify({
          email : email,  
          apple_user_id : app_id,
          password : password,
          workspace_id,
          invite_id
        });
        let res1 = await axios.post("/api/apple/user",body,config);
        if(res1.status === 200) {
          if(res1.data.confirmation === true) {
            await this.props.loadUser(res1);
            await this.props.removeInvite();
            if (this.props.isAuthenticated === true) {
              this.setState({ isAuthenticated: true });
            }
          }
        }
      }
    }
    catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response)
        const errors = error.response.data.errors;
        if(errors) {
          errors.forEach((error) => this.props.setAlert(error.msg, "danger"));
        }
      }

      /*if (error.response) {
        let errors = {};
        console.log(error.response)
        if(error.response.status !== 500 && error.response.status !== 404) {
          errors["email"] = error.response.data.errors[0].msg;
          this.setState({
            errors: errors,
          });
        }
      }*/
    }
  };

  handleClose = () => {
    this.setState({
      modalshow: false,
    });
  };

  toggleShow = () => {
    this.setState({ hidden: !this.state.hidden });
  };

  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      errors: {},
    });
  };

  validateForm = () => {
    let email = this.state.email || "";
    let password = this.state.password || "";
    let errors = {};
    let formIsValid = true;

    if (email === "") {
      formIsValid = false;
      errors["email"] = "Please enter your email address.";
    }

    if (email !== "") {
      let pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) { 
        formIsValid = false;
        errors["email"] = "Please enter valid email address.";
      }
    }
    if (password === "") {
      formIsValid = false;
      errors["password"] = "Please enter secure and strong password.";
    }
    if (password !== "") {
      if (
        !password.match(
          /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/
        )
      ) {
        formIsValid = false;
        errors["password"] = "*Please enter secure and strong password";
      }
    }
    let state = this.state;
    state.errors = errors;

    this.setState({ errors: errors });
    return formIsValid;
  };

  loginUser = async (event) => {
    event.preventDefault();
    try {
      if (this.validateForm()) {
        let email = this.state.email;
        let password = this.state.password;
        let workspace_id = this.state.workspace_id;
        let invite_id = this.state.invite_id;
        await this.props.login({ email, password, workspace_id, invite_id });
        if (this.props.isAuthenticated === true) {
          this.setState({ isAuthenticated: true });
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response)
        let errors = {};
        if(error.response.status !== 500) {
          errors["email"] = error.response.data.errors[0].msg;
          this.setState({
            errors: errors,
          });
        }
      }
    }
  };

  checkPassword = () => {
    let password = this.state.password;
    let letter = false,
      length = false,
      number = false,
      special = false;
    if (password.length > 8) {
      length = true;
    }
    if (/[a-z]/.test(password) === true && /[A-Z]/.test(password) === true) {
      letter = true;
    }
    if (/[0-9]/.test(password)) {
      number = true;
    }
    if (/[!@#$]/.test(password)) {
      special = true;
    }

    this.setState({
      password_check: {
        length: length,
        letter: letter,
        special: special,
        number: number,
      },
    });
  };

  hanldeKeydown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.loginUser(e);
    }
  };

  render() {
    const responseGoogle = (response) => {
      console.log(response)
      let res = response.profileObj;
      if(res !== undefined && res !== null) {
        this.signin(response);
      }
    }
    const { isAuthenticated } = this.state;

    if (isAuthenticated) {
      return <Redirect to='/dashboard' />;
    }
    
    return (
      <div>
        <div>
          <Navbar />
          <main>
            {/*<!-- top hero section -->*/}
            <div className='main-wrapper-container'>
              <section className='hero'>
                <div className='page-header-content-wrap animate__animated animate__fadeInUp'>
                  <h1>Get tracking</h1>
                  <h2>Log in to your Timely account.</h2>
                </div>
              </section>
              <section className='form-login-wrap animate__animated animate__fadeInUp'>
                <div className='container'>
                  <div className='d-flex justify-content-center'>
                    <div className='col-md-6 '>
                      <form
                        action='/'
                        className='form-login'
                        id='login-form'
                        method='post'
                        name='loginform'
                      >
                        <div className='form-field form-btn-submit animate__animated animate__fadeInUp'>
                          <div>
                          <GoogleLogin
                          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                          buttonText="Sign in with Google"
                          render={renderProps => (
                            <button className='button google-signup-button' 
                            onClick={renderProps.onClick} disabled={renderProps.disabled}>
                              <span className='google-logo'>
                              Sign in with Google
                            </span></button>
                          )}
                          onSuccess={responseGoogle}
                          onFailure={responseGoogle}
                          cookiePolicy={'single_host_origin'}
                        />
                        
                            {/**
                             *                           <a href="http://localhost:5000/api/google" >
                            <button
                              className='button google-signup-button'
                              type='button'
                            >
                              <span className='google-logo'>
                                Log in with Google
                              </span>
                            </button>
                            </a>
                             */}

                          </div>
                          <div>
                            <button
                              className='button apple-signup-button animate__animated animate__fadeInUp'
                              type='button'
                              onClick={() => window.AppleID.auth.signIn()}
                            >
                              <span className='apple-logo'>
                                Log in with Apple
                              </span>
                            </button>
                          </div>
                        </div>
                        <div className='form-field--divider'>
                          <span>or</span>
                        </div>
                        <Alert alerts='' />
                        <div
                          className='form-field form-input-field'
                          data-valid-entry={
                            this.state.errors.email ? "false" : "true"
                          }
                        >
                          <label htmlFor='login-email'>Email Address</label>
                          <input
                            type='email'
                            name='email'
                            id='login-email'
                            onChange={this.onChange}
                            value={this.state.email}
                            autoCorrect='off'
                            autoCapitalize='off'
                            autoComplete='username'
                            spellCheck='false'
                            required=''
                            pattern='.+@.+\..+$'
                            className={this.state.errors.email ? "error" : ""}
                            onKeyDown={(e) => this.hanldeKeydown(e)}
                          />
                          <label
                            id='login-email-error'
                            className='error'
                            htmlFor='login-email'
                          >
                            {this.state.errors.email}
                          </label>
                        </div>
                        <div
                          className='form-field form-input-field'
                          data-valid-entry={
                            this.state.errors.password ? "false" : "true"
                          }
                        >
                          <label htmlFor='login-password'>Password</label>
                          <input
                            type={this.state.hidden ? "password" : "text"}
                            name='password'
                            id='login-password'
                            onChange={this.onChange}
                            value={this.state.password}
                            required=''
                            autoComplete='current-password'
                            className={
                              this.state.errors.password ? "error" : ""
                            }
                            onKeyUp={() => this.checkPassword()}
                            onKeyDown={(e) => this.hanldeKeydown(e)}
                          />
                          <span className='form-icon' onClick={this.toggleShow}>
                            {this.state.hidden ? (
                              <svg
                                className='form-icon--eyeout'
                                style={{ enableBackground: "new 0 0 56 41" }}
                                version='1.1'
                                viewBox='0 0 56 41'
                                x='0px'
                                xmlns='http://www.w3.org/2000/svg'
                                y='0px'
                              >
                                <path
                                  d='M55.3,21l-0.6,1c-1.3,2.3-2.8,4.4-4.2,6.3l-3.3-2.2c1.2-1.5,2.4-3.2,3.5-5.1
                                      C41.8,6.3,29.7,1.7,18.8,7.3l-3.7-2.5c13.4-8.2,29-3.2,39.6,15.2L55.3,21z'
                                ></path>
                                <path
                                  d='M39.5,21l-4.7-3.1c-1.1-2.5-3.7-4.3-6.6-4.4l-4.7-3.1c1.4-0.6,2.9-0.9,4.5-0.9
                                      C34.3,9.5,39.5,14.7,39.5,21z'
                                ></path>
                                <path
                                  d='M55.5,37.1c-0.1-0.5-0.4-1-0.9-1.3l-6.7-4.5l-3.4-2.2l-5.8-3.9L35.2,23l-11.8-7.9l-3.5-2.3
                                      l-4.8-3.2l-3.5-2.3l-7.5-5C3.2,1.7,1.9,2,1.3,2.9S1,5.1,1.9,5.7l6.7,4.5c-0.9,0.9-1.8,2-2.7,3c-1.6,2-3.2,4.3-4.7,6.8l-0.6,1
                                      l0.6,1C12,40.7,28,45.6,41.5,36.8c1.1-0.7,2.3-1.6,3.4-2.5l7.5,5c0.5,0.3,1,0.4,1.5,0.3c0.5-0.1,1-0.4,1.3-0.9
                                      S55.6,37.6,55.5,37.1z M33.2,26.4c-1.1,1.1-2.6,1.8-4.2,2c-0.3,0-0.6,0.1-1,0.1c-3.5,0-6.3-2.3-7.2-5.5c-0.2-0.6-0.3-1.3-0.3-2
                                      c0-0.9,0.2-1.8,0.5-2.7L33.2,26.4z M37.8,34.3C26.8,40.5,14.4,36,5.3,21c1.3-2.1,2.6-4,4-5.6c0.9-1.1,1.8-2.1,2.7-3l5.6,3.7
                                      c-0.6,1.3-1,2.6-1.1,4.1c0,0.3,0,0.5,0,0.8c0,6.3,5.2,11.5,11.5,11.5c1.9,0,3.7-0.5,5.2-1.2c1.3-0.6,2.4-1.5,3.3-2.6l4.9,3.2
                                      C40.3,32.8,39.1,33.7,37.8,34.3z'
                                ></path>
                              </svg>
                            ) : (
                              <svg
                                className='form-icon--eye'
                                height='40'
                                viewBox='0 0 54 40'
                                width='54'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M125,100c-13.8-24-36.2-24-50,0h0c13.8,24,36.2,24,50,0h0Z'
                                  fill='none'
                                  stroke-linecap='round'
                                  stroke-linejoin='round'
                                  stroke-width='4'
                                  transform='translate(-73 -80)'
                                ></path>
                                <circle
                                  cx='27'
                                  cy='20'
                                  fill='none'
                                  r='9.5'
                                  stroke-linecap='round'
                                  stroke-linejoin='round'
                                  stroke-width='4'
                                ></circle>
                              </svg>
                            )}
                          </span>
                          <label
                            id='login-email-error'
                            className='error'
                            htmlFor='login-email'
                          >
                            {this.state.errors.password}
                          </label>
                          <PassWordCheck
                            length={this.state.password_check.length}
                            number={this.state.password_check.number}
                            letter={this.state.password_check.letter}
                            special={this.state.password_check.special}
                          />
                        </div>
                        <p className='form-field '>
                          <NavLink to="/password/forgot">Forgot password?</NavLink>
                        </p>
                        <div className='form-field form-btn-submit'>
                          <div>
                            <button
                              className='button'
                              id='login-button'
                              type='button'
                              onClick={this.loginUser}
                            >
                              <span>Log in with email</span>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            {/*<!-- ==============SIGN UP htmlFor FREE SECTION START FORM HERE============== -->*/}
            <section className='signup-free__link animate__animated animate__fadeInUp'>
              <div className='container'>
                <div className='link-banner link-banner--medium'>
                  <div className='container'>
                    <p>Don't have an account?</p>
                    <a href='/' className='button'>
                      <span>Sign Up For Free</span>
                    </a>
                  </div>
                </div>
              </div>
            </section>
            {/*<!-- ==============SIGN UP htmlFor FREE SECTION END HERE============== -->*/}
          </main>
          <Modal show={this.state.modalshow} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Email Sent</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>We have sent an email to you.</p>
            <p>Confirm your registration for start Tracking</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
          <section id='main-content'>
            <div className='row'>{/*<!-- /# column -->*/}</div>
            {/*<!-- /# row -->*/}
          </section>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  invite_ids : state.invite_ids
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      login,
      loadUser,
      setInvite,
      removeInvite,
      setAlert
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Login);
