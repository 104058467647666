import React from "react";
import SideBar from "../../components/common/sidebar";
import TeamModal from "../team/TeamModal";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import TeamList from "../team/TeamList";
import CheckToken from "../common/check_token";
import Footer from "../common/footer";

class Team extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
      email: "",
      reload: 0,
      showOptions : false,
      status : 1,
    };
  }
  componentDidMount() {
    document.addEventListener(
      "click",
      () => {
        this.setState({showOptions : false})
      },
      true
    );
    const { isAuthenticated } = this.props.auth;
    if (!isAuthenticated) {
      this.props.history.push("/login");
    }
  }

  showModal = () => {
    this.setState({ open: true });
  };

  hideModal = () => {
    this.setState({ open: false });
  };

  reload = () => {
    this.setState({ reload: this.state.reload + 1 });
  };

  selectOption = (event, id) => {
    this.setState({status : id, showOptions : false});
    //this.reload();
  };

  render() {
    return (
      <>
      <div>
        <CheckToken />
        <SideBar />
        <div class='header'>
          <div class='container-fluid'>
            <div class='row' style={{ flexDirection: "column" }}>
              <div class='reports-header-contain'>
                <h3>Team</h3>
                {/*<a
                  href='team.html'
                  class='reports-header-items'
                  style={{ color: "rgb(0,0,0)", fontWeight: "500" }}
                >
                  Member
                </a>
                <a href='group.html' class='reports-header-items'>
                  Group
                </a>
                <a href='activity.html' class='reports-header-items'>
                  Activity
                </a>*/}

                <div class='reports-right-item-cont tags-right-header-item'>
                  <div class='create-new-tag-btn'>
                    <button
                      onClick={() => this.showModal()}
                      id='add-new-button'
                      data-toggle='modal'
                      data-target='#clientModal'
                      disabled={this.props.auth.is_workspace_admin === 1?false:true}
                      style={{backgroundColor :this.props.auth.is_workspace_admin === 1?"":"#D1D1D1"}}
                    >
                      <svg width='20px' height='20px' viewBox='0 0 20 20'>
                        <g fill='none' fill-rule='evenodd'>
                          <path d='M0 0h20v20H0z'></path>
                          <path
                            fill='#ffffff'
                            d='M16.253 14.977a1.575 1.575 0 01-2.146 1.267l-3.713-1.486-1.942 2.427c-.481.603-1.452.262-1.452-.51v-1.38c0-.19.066-.376.19-.521l.072-.088 5.337-6.405c.468-.563-.288-1.314-.847-.84l-6.308 5.337-1.467-.588c-1.213-.484-1.34-2.152-.213-2.816l11.48-6.754c1.126-.662 2.522.256 2.36 1.551l-1.351 10.806z'
                          ></path>
                        </g>
                      </svg>
                      <span>Invite member</span>
                    </button>
                  </div>
                </div>
              </div>
              <div class='reports-filter-row filter-by-team-btn'>
              <div class='dropdown'>
                <button
                  tabindex='0'
                  type='button'
                  class='group-by-btn dropdown-toggle '
                  data-toggle='dropdown'
                  aria-expanded='false'
                  onClick={() => {
                    this.setState({showOptions : true})
                  }}
                >
                  <span class='btn-first-text'>Show</span>
                  <span class='tab-heading'>
                    {this.state.status == "1" ? "active" : ""}
                    {this.state.status == "2" ? "inactive" : ""}
                    {this.state.status == "3" ? "invited" : ""}
                  </span>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='7'
                    height='4'
                    viewBox='0 0 7 4'
                  >
                    <path
                      fill='#7b7b7b'
                      fill-rule='nonzero'
                      d='M3.5 2.793L6.146.146a.5.5 0 1 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3A.5.5 0 0 1 .854.146L3.5 2.793z'
                    ></path>
                  </svg>
                </button>
                <ul
                  class='dropdown-menu'
                  x-placement='bottom-start'
                  style={
                    this.state.showOptions
                      ? {
                          display: "block",
                        }
                      : {
                          display: "none",
                        }
                  }
                >
                  <li
                    className={this.state.status == "1" ? "filter active" : "filter"}
                    id='tab-active'
                    onClick={(event) => {
                      this.selectOption(event, "1");
                    }}
                  >
                    <a href='javascript:void(0);' data-id='1'>
                      Active
                    </a>
                  </li>
                  <li
                    className={this.state.status == "2" ? "filter active" : "filter"}
                    id='tab-archived'
                    onClick={(event) => {
                      this.selectOption(event, "2");
                    }}
                  >
                    <a href='javascript:void(0);'>Inactive</a>
                  </li>
                  <li
                    className={this.state.status == "3" ? "filter active" : "filter"}
                    id='tab-both'
                    onClick={(event) => {
                      this.selectOption(event, "3");
                    }}
                  >
                    <a href='javascript:void(0);'>Invited</a>
                  </li>
                </ul>
              </div>

                {/*<div class='filter-label'>
                  <span>Filter by:</span>
                </div>*/}

                {/*<!--==== =======FILTER BY TEAM BUTTON START FROM HERE   ============== -->*/}

                {/*<div class='report-filter-item-box dropdown'>
                  <div
                    class='report-filter-inner-item dropdown-toggle'
                    id='teammenudrop'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 18 18'
                    >
                      <g fill='none' fill-rule='evenodd'>
                        <rect
                          width='18'
                          height='18'
                          fill='#cecece'
                          rx='4'
                        ></rect>
                        <path
                          fill='#ffffff'
                          d='M4 5.5C6.5 5.5 9 3 9 3s2.5 2.5 5 2.5V9c0 3.5-5 6-5 6s-5-2.5-5-6V5.5z'
                        ></path>
                      </g>
                    </svg>
                    <span>Access</span>
                  </div>

                  <ul
                    class='dropdown-menu filter-by-button-dropdown-wrap'
                    x-placement='bottom-start'
                  >
                    <li class='filter-active' id='tab-a'>
                      <a href='#'>All</a>
                    </li>
                    <li class='' id='tab-b'>
                      <a href='#'>Admin</a>
                    </li>
                  </ul>
                </div>*/}
                {/*<!-- =====================filter by Access end here=========================== -->*/}

                {/*<div class='report-filter-item-box dropdown'>
                  <div
                    class='report-filter-inner-item  fill-remove dropdown-toggle'
                    data-toggle='dropdown'
                  >
                    <svg
                      width='18px'
                      height='18px'
                      viewBox='0 0 18 18'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g
                        id='📊-Phase-II-(Reports)'
                        stroke='none'
                        stroke-width='1'
                        fill='none'
                        fill-rule='evenodd'
                      >
                        <g
                          id='Reports-Desktop-1'
                          transform='translate(-840.000000, -8.000000)'
                        >
                          <g
                            id='Filter-bar'
                            transform='translate(180.000000, 0.000000)'
                          >
                            <g
                              id='filter-audit'
                              transform='translate(660.000000, 8.000000)'
                            >
                              <rect
                                id='Rectangle-21'
                                fill='#cecece'
                                x='0'
                                y='0'
                                width='18'
                                height='18'
                                rx='4'
                              ></rect>
                              <g
                                id='Group-15'
                                transform='translate(3.000000, 3.000000)'
                              >
                                <path
                                  d='M5,10 C7.76142375,10 10,7.76142375 10,5 C10,2.23857625 7.76142375,0 5,0 C2.23857625,0 0,2.23857625 0,5 C0,7.76142375 2.23857625,10 5,10 Z M5,8 C6.65685425,8 8,6.65685425 8,5 C8,3.34314575 6.65685425,2 5,2 C3.34314575,2 2,3.34314575 2,5 C2,6.65685425 3.34314575,8 5,8 Z'
                                  id='Combined-Shape'
                                  fill='#fafbfc'
                                ></path>
                                <path
                                  d='M8,8 L10.5355,10.5355'
                                  id='Line'
                                  stroke='#fafbfc'
                                  stroke-width='2'
                                  stroke-linecap='round'
                                  stroke-linejoin='round'
                                ></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                    <span>Groups</span>
                  </div>

                  <div class='dropdown-menu filter-by-button-dropdown-wrap'>
                    <div class='Teamdropdown_container'>
                      <div class='filter-search-field-wrap'>
                        <div class='team-search-contain'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='14'
                            height='14'
                            viewBox='0 0 14 14'
                          >
                            <path
                              fill='#cecece'
                              d='M13.9,12.8L10.2,9c0.7-0.9,1.2-2.2,1.2-3.4C11.3,2.5,8.8,0,5.7,0C2.6,0,0,2.6,0,5.7c0,3.2,2.5,5.7,5.7,5.7 c1.2,0,2.3-0.4,3.4-1.2l3.6,3.6C12.8,14,12.9,14,13,14s0.1,0,0.2-0.1l0.8-0.8C14,13.1,14,12.9,13.9,12.8z M9.8,5.7 c0,2.2-1.8,4-4.1,4s-4.1-1.8-4.1-4c0-2.2,1.9-4.1,4.1-4.1C8,1.7,9.8,3.5,9.8,5.7z'
                            ></path>
                          </svg>
                          <input
                            type='text'
                            tabindex='4'
                            placeholder='Search groups...'
                            class='team-search-field'
                            value=''
                          />
                        </div>
                      </div>
                      <div class='team-list-contain-wrap'>
                        <div class='team-list-inner-box'>
                          <div
                            class='virtualized__List'
                            style={{ position: "relative", overflow: "hidden" }}
                          >
                            <div
                              class='inner-scrollar-container'
                              role='rowgroup'
                              style={{ position: "relative" }}
                            >
                              <div style={{ width: "100%" }}>
                                <div class='top-lebel-wrap'>
                                  <span class='label-text'>GROUPS</span>
                                  <div>
                                    <a tabindex='-1'>
                                      <span>All</span>
                                    </a>
                                    <a tabindex='-1'>
                                      <span>None</span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div style={{ width: "100%" }}>
                                <div class='filter-by-check-wrap'>
                                  <div class='custom-checkbox-wrap'>
                                    <label>
                                      <input
                                        type='checkbox'
                                        name='filter-item[]'
                                      />
                                    </label>
                                  </div>
                                  <div class='team-name-filter-by'>
                                    <div title='webteam'>webteam</div>
                                  </div>
                                </div>
                              </div>

                              <div style={{ width: "100%" }}>
                                <div class='filter-by-check-wrap'>
                                  <div class='custom-checkbox-wrap'>
                                    <label>
                                      <input
                                        type='checkbox'
                                        name='filter-item[]'
                                      />
                                    </label>
                                  </div>
                                  <div class='team-name-filter-by'>
                                    <div title='webteam'>webteam</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>*/}
                {/*<!--======================= FILTER BY GROUPS END HERE =======================-->*/}

                {/*<div class='report-filter-item-box dropdown'>
                  <div
                    class='report-filter-inner-item dropdown-toggle'
                    data-toggle='dropdown'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 18 18'
                    >
                      <g fill='none' fill-rule='evenodd'>
                        <rect
                          width='18'
                          height='18'
                          fill='#cecece'
                          rx='4'
                        ></rect>
                        <path
                          fill='#fafbfc'
                          d='M7.524 13l-.348-1.548H5.268L4.92 13H3l2.064-8.496h2.412L9.528 13H7.524zM6.252 6.352h-.048l-.72 3.612h1.452l-.684-3.612zM13.376 10.264h-.336c-.136 0-.268.012-.396.036a.841.841 0 0 0-.336.138.73.73 0 0 0-.228.264.897.897 0 0 0-.084.414c0 .248.054.43.162.546a.514.514 0 0 0 .39.174c.144 0 .27-.036.378-.108a.795.795 0 0 0 .258-.282 1.45 1.45 0 0 0 .144-.396c.032-.148.048-.294.048-.438v-.348zm.06 1.956c-.08.28-.238.506-.474.678-.236.172-.542.258-.918.258-.184 0-.372-.032-.564-.096a1.448 1.448 0 0 1-.528-.318 1.688 1.688 0 0 1-.396-.588c-.104-.244-.156-.546-.156-.906 0-.432.078-.788.234-1.068.156-.28.36-.504.612-.672.252-.168.538-.286.858-.354.32-.068.644-.102.972-.102h.24v-.096c0-.304-.07-.524-.21-.66-.14-.136-.326-.204-.558-.204a1.28 1.28 0 0 0-.63.156c-.188.104-.35.236-.486.396l-.828-1.152c.256-.304.574-.54.954-.708.38-.168.758-.252 1.134-.252.424 0 .784.064 1.08.192.296.128.536.316.72.564.184.248.316.55.396.906.08.356.12.766.12 1.23V13H13.46v-.78h-.024z'
                        ></path>
                      </g>
                    </svg>
                    <span>Name</span>
                  </div>
                  <div class='dropdown-menu filter-by-button-dropdown-wrap'>
                    <div class='Teamdropdown_container'>
                      <div class='filter-search-field-wrap'>
                        <div class='team-search-contain'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='14'
                            height='14'
                            viewBox='0 0 14 14'
                          >
                            <path
                              fill='#cecece'
                              d='M13.9,12.8L10.2,9c0.7-0.9,1.2-2.2,1.2-3.4C11.3,2.5,8.8,0,5.7,0C2.6,0,0,2.6,0,5.7c0,3.2,2.5,5.7,5.7,5.7 c1.2,0,2.3-0.4,3.4-1.2l3.6,3.6C12.8,14,12.9,14,13,14s0.1,0,0.2-0.1l0.8-0.8C14,13.1,14,12.9,13.9,12.8z M9.8,5.7 c0,2.2-1.8,4-4.1,4s-4.1-1.8-4.1-4c0-2.2,1.9-4.1,4.1-4.1C8,1.7,9.8,3.5,9.8,5.7z'
                            ></path>
                          </svg>
                          <input
                            type='text'
                            tabindex='4'
                            placeholder='Search members...'
                            class='team-search-field'
                            value=''
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>*/}

                {/*<!-- ================FILTER BY MEMBER NAME END HERE================ -->*/}
              </div>
            </div>
          </div>
        </div>
        {/*<!-- ==================================HEADER END HERE================================== -->*/}

        <TeamModal
          show={this.state.open}
          handleClose={this.hideModal}
          reloadList={this.reload}
        />
        <TeamList 
          mode={this.state.status}
          reload={this.state.reload}
        />
        
      </div>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Team);
