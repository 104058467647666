import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { register, loadUser } from "../../actions/auth";
import "../../assets/css/register.css";
import { Modal, Button } from "react-bootstrap";
import robot from "../../assets/images/robot.png";
import { Navbar } from "../common/Navbar";
import { bindActionCreators } from "redux";
import Alert from "../common/Alert";
import PassWordCheck from "../common/PassWordCheck";
import { Redirect } from "react-router-dom";
import GoogleLogin from 'react-google-login';
import scriptjs from "scriptjs";
import { setInvite, removeInvite } from "../../actions/invite_ids";
import { setAlert } from "../../actions/alert";

var generator = require("generate-password");

class Register extends React.Component {
  constructor(props) {
    super();
    this.state = {
      email: "",
      password: "",
      passwordConfirmation: "",
      first_name: "",
      last_name: "",
      country: "",
      errors: {},
      hidden: true,
      isAuthenticated: false,
      workspace_id: 0,
      invite_id: 0,
      modalshow: false,
      password_check: {
        length: false,
        number: false,
        letter: false,
        special: false,
      },
    };

    this.toggleShow = this.toggleShow.bind(this);
  }
  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  componentDidMount() {
    //this.googleSDK();
    this.checkUser();
    this.checkApple();
    const { isAuthenticated } = this.props.auth;
    if (isAuthenticated) {
      this.props.history.push("/dashboard");
    }
    scriptjs.get('https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js', 
    () => {
      const params = {
        clientId: 'timeily.com',
        redirectURI: 'https://timeily.com/api/apple/verify',
        scope: 'name email',
        };
      window.AppleID.auth.init(params);
    });
  }

  signup = async (res) => {
    try {
      const googleresponse = {
        Name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.googleId,
        Image: res.profileObj.imageUrl,
        ProviderId: 'Google'
      };
      const config = {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      };
      let arr = googleresponse.Name.split(" ");
			let first_name,last_name;
			if(arr.length>2){
				first_name = arr[0];
				last_name = googleresponse.Name.substr(googleresponse.Name.indexOf(' ')+1);
			}
			else {
				first_name = arr[0];
				last_name = arr[1];
      }
      let password = generator.generate({
        length: 10,
        numbers: true
      });
      let workspace_id = this.state.workspace_id;
      let invite_id = this.state.invite_id;
      const body = JSON.stringify({
        email : googleresponse.email,
        first_name,
        last_name,
        password,
        workspace_id,
        invite_id
      });

      let res1 = await axios.post("/api/google/signup",body,config);
      if(res1.status === 200) {
        if(res1.data.confirmation === true) {
          await this.props.loadUser(res1);
          await this.props.removeInvite();
          if (this.props.auth.isAuthenticated === true) {
            this.setState({ isAuthenticated: true });
          }
        }
      }
    }
    catch(error) {
      console.log(error);
      if (error.response) {
        console.log(error.response)
        const errors = error.response.data.errors;
        if(errors) {
          errors.forEach((error) => this.props.setAlert(error.msg, "danger"));
        }
      }
      /*if (error.response) {
        let errors = {};
        console.log(error.response)
        if(error.response.status !== 500) {
          errors["email"] = error.response.data.errors[0].msg;
          this.setState({
            errors: errors,
          });
        }
      }*/
    }

  }

  checkUser = async () => {
    let url_string = window.location.href;
    var url = new URL(url_string);
    var c = url.searchParams.get("data");
    if (c) {
      let buff = Buffer.from(c, "base64");
      let text = buff.toString("ascii");
      let data = text.split(" ");
      let email = data[1];
      let workspace_id = data[0];
      let invite_id = data[2];
      await this.props.setInvite(invite_id,workspace_id);
      this.setState({ email: email, workspace_id: workspace_id, invite_id });
    }
  };

  checkApple = async () => {
    try {
      let url_string = window.location.href;
      let url = new URL(url_string);
      let apple = url.searchParams.get("apple");
      if(apple) {
        let buff = Buffer.from(apple, "base64");
        let text = buff.toString("ascii");
        let data = text.split(" ");
        
        let app_id = data[0];    
        let email = data[1];

        const config = {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        };
        let password ;
        if(email !== null && email !== undefined) {
          password = generator.generate({
          length: 10,
          numbers: true
         });
        }
        let workspace_id = this.props.invite_ids.payload.invite_wid;
        let invite_id = this.props.invite_ids.payload.invite_id;
        const body = JSON.stringify({
        email : email,  
        apple_user_id : app_id,
        password : password,
        workspace_id,
        invite_id
        });
        let res1 = await axios.post("/api/apple/user",body,config);
        if(res1.status === 200) {
          if(res1.data.confirmation === true) {
            await this.props.loadUser(res1);
            await this.props.removeInvite();
            if (this.props.auth.isAuthenticated === true) {
              this.setState({ isAuthenticated: true });
            }
          }
        }
      }
    }
    catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response)
        const errors = error.response.data.errors;
        if(errors) {
          errors.forEach((error) => this.props.setAlert(error.msg, "danger"));
        }
      }
      /*if (error.response) {
        let errors = {};
        console.log(error.response)
        if(error.response.status !== 500 && error.response.status !== 404) {
          errors["email"] = error.response.data.errors[0].msg;
          this.setState({
            errors: errors,
          });
        }
      }*/
    }
  };

  componentWillUnmount() {}

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      errors: {},
    });
  };
  validateForm() {
    let email = this.state.email || "";
    let password = this.state.password || "";
    let first_name = this.state.first_name || "";
    let last_name = this.state.last_name || "";
    let errors = {};
    let formIsValid = true;

    if (first_name === "") {
      formIsValid = false;
      errors["first_name"] = "Please enter your First Name .";
    }

    if (last_name === "") {
      formIsValid = false;
      errors["last_name"] = "Please enter your Last Name.";
    }

    if (email === "") {
      formIsValid = false;
      errors["email"] = "Please enter your email address.";
    }

    if (email !== "") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        formIsValid = false;
        errors["email"] = "*Please enter valid email address.";
      }
    }
    if (password === "") {
      formIsValid = false;
      errors["password"] = "*Please enter secure and strong password.";
    }
    if (password !== "") {
      if (
        !password.match(
          /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/
        )
      ) {
        formIsValid = false;
        errors["password"] = "*Please enter secure and strong password.";
      }
    }

    let state = this.state;
    state.errors = errors;

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  registerUser = async (event) => {
    event.preventDefault();
    try {
      if (this.validateForm()) {
        let email = this.state.email;
        let password = this.state.password;
        let first_name = this.state.first_name;
        let last_name = this.state.last_name;
        let workspace_id = this.state.workspace_id;
        let invite_id = this.state.invite_id;
        let is_mail_sent = await this.props.register({
          first_name,
          last_name,
          email,
          password,
          workspace_id,
          invite_id,
        });
        if (is_mail_sent === 1) {
          this.setState({
            email: "",
            password: "",
            passwordConfirmation: "",
            first_name: "",
            last_name: "",
            country: "",
            errors: {},
            hidden: true,
            isAuthenticated: false,
            workspace_id: 0,
            invite_id: 0,
            password_check: {
              length: false,
              number: false,
              letter: false,
              special: false,
            },
            modalshow: true,
          });
        } else if (this.props.auth.isAuthenticated === true) {
          this.setState({ isAuthenticated: true });
        }
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        if (err.response.data.errors) {
          let errors = {};
          console.log(err.response)
          if(err.response.status !== 500) {
            errors["email"] = err.response.data.errors[0].msg;
            this.setState({
              errors: errors,
            });
          }
        }
      }
    }
  };

  handleShow = () => {
    this.setState({
      modalshow: true,
    });
  };

  handleClose = () => {
    this.setState({
      modalshow: false,
    });
  };

  checkPassword = () => {
    let password = this.state.password;
    let letter = false,
      length = false,
      number = false,
      special = false;
    if (password.length > 8) {
      length = true;
    }
    if (/[a-z]/.test(password) === true && /[A-Z]/.test(password) === true) {
      letter = true;
    }
    if (/[0-9]/.test(password)) {
      number = true;
    }
    if (/[!@#$]/.test(password)) {
      special = true;
    }

    this.setState({
      password_check: {
        length: length,
        letter: letter,
        special: special,
        number: number,
      },
    });
  };

  hanldeKeydown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.registerUser(e);
    }
  };

  render() {
    const responseGoogle = (response) => {
      let res = response.profileObj;
      if(res !== undefined && res !== null) {
        this.signup(response);
      }
    }
    const { isAuthenticated } = this.state;
    if (isAuthenticated) {
      return <Redirect to='/dashboard' />;
    }

    return (
      <>
        <Navbar />
        <main>
          <div className='main-wrapper-container wrapper-in-signup'>
            <section className='hero'>
              <div className='page-header-content-wrap text-dark'>
                <h1>Let's get started</h1>
                <h2>Kickstart your productivity with Toggl.</h2>
              </div>
            </section>
            <section className='form-login-wrap signup-wrap'>
              <div className='container'>
                <div className='d-flex justify-content-center'>
                  <div className='col-6-sign '>
                    <div className='signup-left-animation'>
                      <img src={robot} />
                    </div>
                  </div>
                  <div className='col-6-sign '>
                    <form
                      action='/'
                      className='form-login'
                      name='signup_form'
                      method='post'
                    >
                      <div className='form-field form-btn-submit'>
                        <div>
                        <GoogleLogin
                          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                          buttonText="Sign up with Google"
                          render={renderProps => (
                            <button className='button google-signup-button' 
                            onClick={renderProps.onClick} disabled={renderProps.disabled}>
                              <span className='google-logo'>
                              Sign up with Google
                            </span></button>
                          )}
                          onSuccess={responseGoogle}
                          onFailure={responseGoogle}
                          cookiePolicy={'single_host_origin'}
                        />
                          {/** <a href="http://localhost:5000/api/google_signup" >
                          <button
                            type='button'
                            className='button google-signup-button'
                            id='google-button'
                            autoFocus=''
                          >
                            <span className='google-logo'>
                              Log in with Google
                            </span>
                          </button>
                          </a>*/}                      
                      </div>
                        <div>
                          <button
                            type='button'
                            className='button apple-signup-button'
                            id='apple-button-signup'
                            onClick={() => window.AppleID.auth.signIn()}
                          >
                            <span className='apple-logo'>
                              Sign up with Apple
                            </span>
                          </button>
                        </div>
                      </div>

                      <div className='form-field--divider'>
                        <span>or</span>
                      </div>
                      <Alert alerts='' />
                      <div className='form-field form-input-field'>
                        <label htmlFor='login-email'>First Name</label>
                        <input
                          type='text'
                          name='first_name'
                          onChange={this.handleChange}
                          value={this.state.first_name}
                          id='first_name'
                          autoCorrect='off'
                          autoCapitalize='off'
                          spellCheck='false'
                          required=''
                          className={
                            this.state.errors.first_name ? "error" : ""
                          }
                          onKeyDown={(e) => this.hanldeKeydown(e)}
                        />
                        {this.state.errors.first_name ? (
                          <label
                            id='login-email-error'
                            className='error'
                            htmlFor='login-email'
                          >
                            {this.state.errors.first_name}
                          </label>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className='form-field form-input-field'>
                        <label htmlFor='login-email'>Last Name</label>
                        <input
                          type='text'
                          name='last_name'
                          onChange={this.handleChange}
                          value={this.state.last_name}
                          id='last_name'
                          autoCorrect='off'
                          autoCapitalize='off'
                          spellCheck='false'
                          required=''
                          className={this.state.errors.last_name ? "error" : ""}
                          onKeyDown={(e) => this.hanldeKeydown(e)}
                        />
                        {this.state.errors.last_name ? (
                          <label
                            id='login-email-error'
                            className='error'
                            htmlFor='login-email'
                          >
                            {this.state.errors.last_name}
                          </label>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className='form-field form-input-field'>
                        <label htmlFor='login-email'>Email Address</label>
                        <input
                          type='email'
                          name='email'
                          onChange={this.handleChange}
                          value={this.state.email}
                          id='login-email'
                          autoCorrect='off'
                          autoCapitalize='off'
                          spellCheck='false'
                          required=''
                          pattern='.+@.+\..+$'
                          className={this.state.errors.email ? "error" : ""}
                          onKeyDown={(e) => this.hanldeKeydown(e)}
                        />
                        {this.state.errors.email ? (
                          <label
                            id='login-email-error'
                            className='error'
                            htmlFor='login-email'
                          >
                            {this.state.errors.email}
                          </label>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className='form-field form-input-field'>
                        <label htmlFor='login-password'>SET A PASSWORD</label>
                        <input
                          type={this.state.hidden ? "password" : "text"}
                          name='password'
                          id='login-password'
                          onChange={this.handleChange}
                          required=''
                          className={this.state.errors.password ? "error" : ""}
                          onKeyUp={() => this.checkPassword()}
                          onKeyDown={(e) => this.hanldeKeydown(e)}
                        />
                        <span className='form-icon' onClick={this.toggleShow}>
                          {this.state.hidden ? (
                            <svg
                              className='form-icon--eyeout'
                              style={{ enableBackground: "new 0 0 56 41" }}
                              version='1.1'
                              viewBox='0 0 56 41'
                              x='0px'
                              xmlns='http://www.w3.org/2000/svg'
                              y='0px'
                            >
                              <path
                                d='M55.3,21l-0.6,1c-1.3,2.3-2.8,4.4-4.2,6.3l-3.3-2.2c1.2-1.5,2.4-3.2,3.5-5.1
                                      C41.8,6.3,29.7,1.7,18.8,7.3l-3.7-2.5c13.4-8.2,29-3.2,39.6,15.2L55.3,21z'
                              ></path>
                              <path
                                d='M39.5,21l-4.7-3.1c-1.1-2.5-3.7-4.3-6.6-4.4l-4.7-3.1c1.4-0.6,2.9-0.9,4.5-0.9
                                      C34.3,9.5,39.5,14.7,39.5,21z'
                              ></path>
                              <path
                                d='M55.5,37.1c-0.1-0.5-0.4-1-0.9-1.3l-6.7-4.5l-3.4-2.2l-5.8-3.9L35.2,23l-11.8-7.9l-3.5-2.3
                                      l-4.8-3.2l-3.5-2.3l-7.5-5C3.2,1.7,1.9,2,1.3,2.9S1,5.1,1.9,5.7l6.7,4.5c-0.9,0.9-1.8,2-2.7,3c-1.6,2-3.2,4.3-4.7,6.8l-0.6,1
                                      l0.6,1C12,40.7,28,45.6,41.5,36.8c1.1-0.7,2.3-1.6,3.4-2.5l7.5,5c0.5,0.3,1,0.4,1.5,0.3c0.5-0.1,1-0.4,1.3-0.9
                                      S55.6,37.6,55.5,37.1z M33.2,26.4c-1.1,1.1-2.6,1.8-4.2,2c-0.3,0-0.6,0.1-1,0.1c-3.5,0-6.3-2.3-7.2-5.5c-0.2-0.6-0.3-1.3-0.3-2
                                      c0-0.9,0.2-1.8,0.5-2.7L33.2,26.4z M37.8,34.3C26.8,40.5,14.4,36,5.3,21c1.3-2.1,2.6-4,4-5.6c0.9-1.1,1.8-2.1,2.7-3l5.6,3.7
                                      c-0.6,1.3-1,2.6-1.1,4.1c0,0.3,0,0.5,0,0.8c0,6.3,5.2,11.5,11.5,11.5c1.9,0,3.7-0.5,5.2-1.2c1.3-0.6,2.4-1.5,3.3-2.6l4.9,3.2
                                      C40.3,32.8,39.1,33.7,37.8,34.3z'
                              ></path>
                            </svg>
                          ) : (
                            <svg
                              className='form-icon--eye'
                              height='40'
                              viewBox='0 0 54 40'
                              width='54'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M125,100c-13.8-24-36.2-24-50,0h0c13.8,24,36.2,24,50,0h0Z'
                                fill='none'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                stroke-width='4'
                                transform='translate(-73 -80)'
                              ></path>
                              <circle
                                cx='27'
                                cy='20'
                                fill='none'
                                r='9.5'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                stroke-width='4'
                              ></circle>
                            </svg>
                          )}
                        </span>
                        {this.state.errors.password ? (
                          <label
                            id='login-password-error'
                            className='error'
                            htmlFor='login-password'
                          >
                            {this.state.errors.password}
                          </label>
                        ) : (
                          ""
                        )}
                        <PassWordCheck
                          length={this.state.password_check.length}
                          number={this.state.password_check.number}
                          letter={this.state.password_check.letter}
                          special={this.state.password_check.special}
                        />
                      </div>

                      <div className='form-field form-btn-submit'>
                        <div>
                          <button
                            type='button'
                            className='button'
                            id='login-button'
                            onClick={this.registerUser}
                            id='login-button'
                          >
                            <span>Sign Up with email</span>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
        <Modal show={this.state.modalshow} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Email Sent</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>We have sent an email to you.</p>
            <p>Confirm your registration for start Tracking</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <section id='main-content'>
          <div className='row'></div>
        </section>
      </>
    );
  }
}

Register.propTypes = {
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  invite_ids : state.invite_ids
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      register,
      loadUser,
      setInvite,
      removeInvite,
      setAlert
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Register);
