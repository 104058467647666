import {SET_INVITE, REMOVE_INVITE } from "../actions/types";

const initialState = {
    invite_id : 0,
    invite_wid : 0
  };

export default function (state = initialState, action) {
  
  const { type, payload } = action;
  switch (type) {
    case SET_INVITE:
      return {...state, payload};
    case REMOVE_INVITE:
      return {
        ...state,
        invite_id : 0,
        invite_wid : 0
      };
    default:
      return state;
  }
}
