import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import {useSelector, useDispatch} from "react-redux";
import Loader from "../common/loader";

export default function InviteReject () {
    const history = useHistory();
    const auth = useSelector(state=>state.auth);
    const dispatch = useDispatch();
    const [state, setState] = useState(false);
    
    useEffect(() => {
        console.log(auth);
        if(auth.isAuthenticated === true && auth.token !== null && auth.token !== undefined) {
            history.push("/dashboard");
        }
        checkUser();
    },[]);

    const  checkUser = async () => {
        try {
        let url_string = window.location.href;
        var url = new URL(url_string);
        var c = url.searchParams.get("id");
        if(c) {
            let buff = Buffer.from(c, 'base64');
            let text = buff.toString('ascii');
            let data = text.split(' ');
            let email = data[1];
            let invite_id = data[0]; 
            
            const config = {
                headers: {
                  "Content-Type": "application/json",
                },
            };
            
            const body = JSON.stringify({ invite_id, email });
            let res = await axios.put("/api/invitation/reject", body, config );    
            if(res.status === 200) {
                if(res.data.msg === 'success') {
                    setState(true);
                }
            }
        }
        else {  
            history.push("/");
        }        
      }
      catch (err) {
          console.log(err)
      }
    }

    return (
        <div style={{position: "fixed",
        top: "50%",
        left: "50%",
        /* bring your own prefixes */
        transform: "translate(-50%, -50%)",display:"flex"}}>
            {
            state===true?
                <div>
                    <h2>
                        You have Rejected the Invitation...!
                    </h2>
                    <a href="https://timeily.com/" >
                        click here to Start Tracking Time.
                    </a>
                </div>
                :
                <Loader />
            }
        </div>
    )
}