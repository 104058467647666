import React, { useEffect, useState } from "react";
import { useHistory, Redirect } from "react-router-dom";
import {useSelector} from 'react-redux';
import axios from "axios";

function CheckToken (props) {
    
    const history = useHistory();
    const auth = useSelector(state => state.auth)
    useEffect(() => {
        let token = auth.token;
        if(token === null || token === undefined || token === "") {
            history.push('/logout');
        }
        else {
            const config = {
                headers: {
                    Authorization: `Bearer ${auth.token}`,
                    "Content-Type": "application/json",
                },
            };
    
            const body = JSON.stringify({
                token : auth.token
            });
            
            axios.post("/api/token/verify", body, config)
            .then(res => {
                if(res.status === 200 || res.data.verifiedJwt !== undefined) {      
                    if (Date.now() >= (res.data.verifiedJwt.exp * 1000)) {
                        history.push('/logout');
                    }
                }
                else {
                    history.push('/logout');
                }
            })
            .catch(err => {
                console.log(err.response)
                if(err.response.status === 401 || err.response.status === 403) {
                    history.push('/logout');
                }
            })
        }
    },[]);

    return (
        null
    )
}
export default CheckToken;