import React from "react";
export default function Logo() {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 88 32'
      style={{ enableBackground: "new 0 0 88 32" }}
      xmlSpace='preserve'
    >
      <path
        className='sto-whitelogo'
        d='M25.58,27.44c2.68-2.62,4.34-6.28,4.34-10.33c0-7.99-6.47-14.46-14.46-14.46C7.47,2.66,1,9.13,1,17.12
	c0,4.04,1.66,7.7,4.34,10.33l-1.01,1.01c-0.64,0.64-0.64,1.69,0,2.34c0.64,0.64,1.69,0.64,2.34,0l1.3-1.3
	c2.19,1.33,4.75,2.09,7.5,2.09c2.74,0,5.31-0.76,7.5-2.09l1.3,1.3c0.64,0.64,1.69,0.64,2.34,0c0.64-0.64,0.64-1.69,0-2.34
	L25.58,27.44z M15.46,29.33c-6.51,0-11.79-5.28-11.79-11.79S8.95,5.75,15.46,5.75s11.79,5.28,11.79,11.79
	C27.26,24.05,21.97,29.33,15.46,29.33z'
      />
      <path
        className='sto-whitelogo'
        d='M16.25,16.44l-0.41-8.48h-0.37l-0.41,8.48c-0.29,0.19-0.48,0.52-0.48,0.9c0,0.6,0.48,1.08,1.08,1.08
	c0.53,0,0.97-0.38,1.06-0.89h8.59v-0.18v-0.2h-8.59C16.66,16.85,16.49,16.6,16.25,16.44L16.25,16.44z'
      />
      <path
        className='sto-whitelogo'
        d='M15.46,0.34c-2.41,0-4.56,1.13-5.93,2.89c1.83-0.76,3.83-1.18,5.93-1.18s4.11,0.42,5.93,1.18
	C20.02,1.47,17.87,0.34,15.46,0.34L15.46,0.34z'
      />
      <g>
        <path
          className='st1-whitelogo'
          d='M40.06,13.9h-2.39v12.6h-3.14V13.9h-2.34v-3.16h7.87V13.9z'
        />
        <path
          className='st1-whitelogo'
          d='M43.54,26.51h-3.16V10.73h3.16V26.51z'
        />
        <path
          className='st1-whitelogo'
          d='M57.35,26.49h-3.16V14.35c0-0.22-0.08-0.41-0.23-0.56c-0.16-0.15-0.34-0.23-0.55-0.23
		c-0.22,0-0.41,0.08-0.56,0.23c-0.16,0.15-0.23,0.34-0.23,0.56v12.14h-3.15V14.35c0-0.22-0.08-0.41-0.23-0.56
		c-0.16-0.15-0.34-0.23-0.56-0.23c-0.19,0-0.36,0.06-0.5,0.19c-0.01,0.02-0.03,0.03-0.06,0.04v0.01c-0.01,0.01-0.03,0.03-0.04,0.04
		v0.01h-0.01c-0.01,0.02-0.03,0.04-0.03,0.06c0.01-0.02,0.01-0.02-0.01,0.01C48.02,13.94,48,13.96,48,13.98c0-0.01,0,0,0,0.01
		c-0.01,0.02-0.03,0.04-0.03,0.05l-0.01,0.03l-0.01,0.04c-0.01,0-0.01,0.02-0.01,0.04l-0.01,0.03c0,0.01,0,0.02-0.01,0.04v0.03
		c-0.01,0.02-0.01,0.05-0.01,0.08v12.14h-3.15V14.34c0.01-1.09,0.39-2.01,1.15-2.77c0.76-0.76,1.69-1.15,2.77-1.15h0.15
		c0.82,0.03,1.56,0.29,2.22,0.79c0.69-0.53,1.48-0.79,2.36-0.79c1.09,0,2.01,0.39,2.78,1.15c0.77,0.77,1.16,1.7,1.16,2.78
		L57.35,26.49L57.35,26.49z'
        />
        <path
          className='st1-whitelogo'
          d='M61.63,13.89v4.73h3.16v3.16h-3.16v1.58h3.78v3.16h-6.94V10.73h6.94v3.16C65.41,13.89,61.63,13.89,61.63,13.89
		z'
        />
        <path
          className='st2-whitelogo'
          d='M69.52,26.51h-3.16V10.73h3.16V26.51z'
        />
        <path
          className='st2-whitelogo'
          d='M77.68,23.36v3.16h-6.94V10.73h3.16v12.63H77.68z'
        />
        <path
          className='st2-whitelogo'
          d='M85.56,10.74v7.09c0,0.79-0.22,1.51-0.65,2.17c-0.42,0.64-0.98,1.12-1.68,1.43v5.07h-3.15v-5.05
		c-0.72-0.3-1.29-0.77-1.73-1.42c-0.45-0.67-0.67-1.4-0.67-2.2v-7.09h3.16v7.09c0,0.22,0.08,0.41,0.23,0.56s0.34,0.23,0.55,0.23
		c0.22,0,0.41-0.08,0.56-0.23c0.15-0.15,0.23-0.34,0.23-0.56v-7.09H85.56z'
        />
      </g>
    </svg>
  );
}
