import React, { useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import {useSelector, useDispatch} from "react-redux";
import {LOGIN_SUCCESS} from "../../actions/types";
import {loadUser} from "../../actions/auth";

export default function InvitePage () {
    const history = useHistory();
    const auth = useSelector(state=>state.auth);
    const dispatch = useDispatch();
    useEffect(() => {
        checkUser();
    },[]);

    const  checkUser = async () => {
        try {
        let url_string = window.location.href;
        var url = new URL(url_string);
        var c = url.searchParams.get("id");
        if(c) {
            let buff = Buffer.from(c, 'base64');
            let text = buff.toString('ascii');
            let data = text.split(' ');
            let email = data[1];
            let invite_id = data[0]; 
            let token = auth.token !== "" && auth.token !== undefined && auth.token !== null?auth.token:"";
            let workspace_id ;
            
            const config = {
                headers: {
                  "Content-Type": "application/json",
                },
            };
            
            const body = JSON.stringify({ invite_id, email, token });
            let res = await axios.post("/api/invitation/getuser", body, config );    
            if(res.status === 200) {
                const invite_detail = res.data.invite_detail;
                const user_detail = res.data.user_detail;
                const user_id = res.data.user_id;
                const admin_email = res.data.admin_detail[0].email;
                const admin_name = res.data.admin_detail[0].first_name + ' ' +  res.data.admin_detail[0].last_name;
                const workspace_title = res.data.admin_detail[0].workspace_title;
                if(invite_detail.length > 0 && 
                    invite_detail[0].is_accepted === 0 && 
                    invite_detail[0].is_joining === 1) {
                    
                    workspace_id = invite_detail[0].workspace_id;
                    let data = `${workspace_id} ${email} ${invite_id}`;
                    let buff = Buffer.from(data);
                    let enData = buff.toString('base64');
                    if(user_detail.length > 0) {
                        if(user_detail[0].id === user_id) {
                            let team_name = `${user_detail[0].first_name} ${user_detail[0].last_name}`
                            const config = {
                                headers: {
                                  Authorization: `Bearer ${token}`,
                                  "Content-Type": "application/json",
                                },
                            };
                            const body = JSON.stringify({
                                user_id, 
                                workspace_id, 
                                team_name, 
                                invite_id, 
                                admin_email,
                                admin_name,
                                workspace_title
                            })
                            let res1 = await axios.post("/api/invitation/accept", body , config)
                            if (res1.status === 200) {
                                dispatch({ type: LOGIN_SUCCESS, payload: res1.data });
                                dispatch(loadUser(res1.data));
                                history.push("/dashboard");
                              } 
                            
                        }
                        else {
                            history.push("/login?data="+enData)
                        }
                    }
                    else {
                        history.push("/?data="+enData)
                    }
                }
                else {
                    history.push("/login");
                }
            }
        }
        else {  
            history.push("/");
        }        
      }
      catch (err) {
          console.log(err)
      }
    }

    return (
        <div>
            <h2>
                The Invitation Page .... !
            </h2>
        </div>
    )
}