import React, { Component, useState, useEffect,lazy, Suspense } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import Sidebar from "../common/sidebar";
//import ProjectList from "../Projects/ProjectList";
import axios from "axios";
import ColorPicker from "../common/ColorPicker";
import CheckToken from "../common/check_token";
import Loader from "../common/loader";
import ProjectModal from "../Projects/projectModal";
import moment from "moment-timezone";
import Footer from "../common/footer";

const ProjectList = lazy(()=> import("../Projects/ProjectList"));

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];



export const Projects = () => {
  const auth = useSelector((state) => state.auth);
  const invite = useSelector(state => state.invite_ids);
  const [show, setShow] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [reload, setReload] = useState(0);
  const [showoptions, setShowoption] = useState(false);
  const [status, setStatus] = useState(0);
  const [clientDD, setClientDD] = useState(false);
  const [projectNameDD, setProjectNameDD] = useState(false);
  const [clientArray, setClientArray] = useState([]);
  const [projectTerm, setProjectTerm] = useState("");
  const [is_sole_project_creator, setProjectCreator] = useState(false);

  useEffect(() => {
    document.addEventListener(
      "click",
      () => {
        setClientDD(false);
        setProjectNameDD(false);
        setShowoption(false);
        setSearchTerm("");
        getClientList();
      },
      true
    );

    if(auth.user_properties !== null && 
      auth.user_properties !== undefined) {
        let timeZone = auth.user_properties.timeZone;
        if(timeZone === "" || timeZone === null || timeZone === undefined) {
          moment.tz.setDefault();
        }
        else {
          timeZone = timeZone.split(' ')[0];
          moment.tz.setDefault(timeZone)
        }
      }
      else {
        moment.tz.setDefault();
      }

    
    getClientList();
    setStatus(1);
    getWorkspaceInfo();
  }, []);

  const handleProjectTerm = (e) => {
    setProjectTerm(e.target.value);
  };

  const handleSearchTerm = (e) => {
    setSearchTerm(e.target.value);
  };

  const getWorkspaceInfo = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let workspace_id = auth.workspace_id;
      const body = JSON.stringify({ workspace_id });
      let res = await axios.post(
        "/api/workspace/listbyworkspaceid",
        body,
        config
      );
      if (res.status === 200) {
        res.data.results.map((item)=> {
          if(item.id === workspace_id) {
            if(item.is_sole_project_creator === 1) {
              if(auth.is_workspace_admin === 1) {
                setProjectCreator(true);
              }
              else {
                setProjectCreator(false);
              }
            }
            else {
              setProjectCreator(true);
            }
          }
      })
      }
    } catch (err) {
      console.log(err.response);
      return err.response;
    }
  };

  const getClientList = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let workspace_id = auth.workspace_id;
      const body = JSON.stringify({ workspace_id, searchTerm });
      let res = await axios.post("/api/client/search", body, config);
      if (res.status === 200) {
        setClientList(res.data.results);
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  const handleShow = () => {
    getClientList();
    setShow(true)
  };

  const selectAllClient = () => {
    setClientArray([]);
    let rowState = [];
    clientList.map((item, index) => {
      rowState.push(item.id);
      setClientArray(rowState);
    });
    setReload(reload + 1);
  };

  const selectNone = () => {
    setClientArray([]);
    setReload(reload + 1)
  };

  const handleClientCheck = (item) => {
    let newArr = [];
    if (!clientArray.includes(item)) {
      newArr = [...clientArray, item];
    } else {
      newArr = clientArray.filter((a) => a !== item);
    }
    setClientArray(newArr);
    setReload(reload + 1);
  };

  const showOptions = (showoptions) => {
    setShowoption(showoptions);
  };
  const selectOption = (event, id) => {
    setStatus(id);
    setShowoption(false);
    setReload(reload + 1);
  };

  const hideModal = () => {
    setShow(false)
  };

  const reloadFunction = () => {
    setReload(reload + 1);
  };

  return (
    <>
      <CheckToken />
      <Sidebar />
      <div className='header'>
        <div className='container-fluid'>
          <div className='row' style={{ flexDirection: "column" }}>
            <div className='reports-header-contain'>
              <h3>Projects</h3>
              <div className='reports-right-item-cont tags-right-header-item'>
                <div className='create-new-tag-btn' >
                  <button id='add-new-button' onClick={handleShow} 
                    disabled={is_sole_project_creator === true ?false : true} 
                    style={{backgroundColor :is_sole_project_creator === true?"":"#D1D1D1"}}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='20'
                      height='20'
                      viewBox='0 0 20 20'
                    >
                      <path
                        fill='#ffffff'
                        fillRule='evenodd'
                        d='M9 9V5.002a.999.999 0 1 1 2 0V9h3.998a.999.999 0 1 1 0 2H11v3.998a.999.999 0 1 1-2 0V11H5.002a.999.999 0 1 1 0-2H9z'
                      ></path>
                    </svg>
                    <span>New project</span>
                  </button>
                </div>
              </div>
            </div>
            <div class='reports-filter-row filter-by-team-btn'>
              <div class='dropdown'>
                <button
                  tabindex='0'
                  type='button'
                  class='group-by-btn dropdown-toggle '
                  data-toggle='dropdown'
                  aria-expanded='false'
                  onClick={() => {
                    showOptions(!showoptions);
                  }}
                >
                  <span class='btn-first-text'>Show</span>
                  <span class='tab-heading'>
                    {status == "1" ? "active" : ""}
                    {status == "2" ? "archived" : ""}
                    {status == "3" ? "both" : ""}
                  </span>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='7'
                    height='4'
                    viewBox='0 0 7 4'
                  >
                    <path
                      fill='#7b7b7b'
                      fill-rule='nonzero'
                      d='M3.5 2.793L6.146.146a.5.5 0 1 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3A.5.5 0 0 1 .854.146L3.5 2.793z'
                    ></path>
                  </svg>
                </button>
                <ul
                  class='dropdown-menu'
                  x-placement='bottom-start'
                  style={
                    showoptions
                      ? {
                          display: "block",
                        }
                      : {
                          display: "none",
                        }
                  }
                >
                  <li
                    className={status == "1" ? "filter active" : "filter"}
                    id='tab-active'
                    onClick={(event) => {
                      selectOption(event, "1");
                    }}
                  >
                    <a href='javascript:void(0);' data-id='1'>
                      Active
                    </a>
                  </li>
                  <li
                    className={status == "2" ? "filter active" : "filter"}
                    id='tab-archived'
                    onClick={(event) => {
                      selectOption(event, "2");
                    }}
                  >
                    <a href='javascript:void(0);'>Archived</a>
                  </li>
                  <li
                    className={status == "3" ? "filter active" : "filter"}
                    id='tab-both'
                    onClick={(event) => {
                      selectOption(event, "3");
                    }}
                  >
                    <a href='javascript:void(0);'>Both</a>
                  </li>
                </ul>
              </div>
              <div className='filter-label'>
                <span>Filter by:</span>
              </div>

              <div
                className={
                  clientDD
                    ? "report-filter-item-box dropdown show"
                    : "report-filter-item-box dropdown "
                }
                onClick={() => setClientDD(true)}
              >
                <div
                  className={clientArray.length > 0?'report-filter-inner-item  fill-remove dropdown-toggle filteritem-active'
                    :"report-filter-inner-item  fill-remove dropdown-toggle"}
                  data-toggle='dropdown'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='18'
                    height='18'
                    viewBox='0 0 18 18'
                  >
                    <g>
                      <rect width='18' height='18' rx='4'></rect>
                      <path d='M7.5 9.5C7.5 11 4 10.5 4 13.5 4 14.5 4 13.5 4 13.5 4 14.3 4.7 15 5.5 15L12.5 15C13.3 15 14 14.3 14 13.5 14 13.5 14 14.5 14 13.5 14 10.5 10.5 11 10.5 9.5 10.5 8.5 12 8 12 6 12 4.5 10.7 3 9 3 7.3 3 6 4.5 6 6 6 8 7.5 8.5 7.5 9.5Z'></path>
                    </g>
                  </svg>
                  <span>Client</span>
                </div>

                <div
                  className={
                    clientDD
                      ? "dropdown-menu filter-by-button-dropdown-wrap show"
                      : "dropdown-menu filter-by-button-dropdown-wrap"
                  }
                  x-placement='top-start'
                  style={{
                    position: "absolute",
                    transform: "translate3d(6px, -71px, 0px)",
                    top: "100px",
                    left: "0px",
                    willChange: "transform",
                  }}
                >
                  <div class='Teamdropdown_container'>
                    <div class='filter-search-field-wrap'>
                      <div class='team-search-contain'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='14'
                          height='14'
                          viewBox='0 0 14 14'
                        >
                          <path
                            fill='#cecece'
                            d='M13.9,12.8L10.2,9c0.7-0.9,1.2-2.2,1.2-3.4C11.3,2.5,8.8,0,5.7,0C2.6,0,0,2.6,0,5.7c0,3.2,2.5,5.7,5.7,5.7 c1.2,0,2.3-0.4,3.4-1.2l3.6,3.6C12.8,14,12.9,14,13,14s0.1,0,0.2-0.1l0.8-0.8C14,13.1,14,12.9,13.9,12.8z M9.8,5.7 c0,2.2-1.8,4-4.1,4s-4.1-1.8-4.1-4c0-2.2,1.9-4.1,4.1-4.1C8,1.7,9.8,3.5,9.8,5.7z'
                          ></path>
                        </svg>
                        <input
                          type='text'
                          tabindex='4'
                          placeholder='Find client...'
                          class='team-search-field'
                          value={searchTerm}
                          onChange={handleSearchTerm}
                          onKeyUp={()=>getClientList()}
                        />
                      </div>
                    </div>
                    <div class='team-list-contain-wrap'>
                      <div class='team-list-inner-box'>
                        <div class='virtualized__List'>
                          <div
                            class='filter-by-check-wrap'
                            onClick={() => handleClientCheck(0)}
                          >
                            <div class='custom-checkbox-wrap'>
                              <label>
                                <span
                                  class={
                                    clientArray.includes(0)
                                      ? "custom-checkboxes selected"
                                      : "custom-checkboxes"
                                  }
                                >
                                  <input type='checkbox' name='filter-item[]' />
                                </span>
                              </label>
                            </div>
                            <div class='team-name-filter-by'>
                              <div title='webteam'>Project without Client</div>
                            </div>
                          </div>
                          <div
                            class='inner-scrollar-container'
                            role='rowgroup'
                            style={{ overflow: "hidden", position: "relative" }}
                          >
                            <div style={{ width: "100%" }}>
                              <div class='top-lebel-wrap'>
                                <span class='label-text'>Client</span>
                                <div>
                                  <a
                                    tabindex='-1'
                                    onClick={() => selectAllClient()}
                                  >
                                    <span>All</span>
                                  </a>
                                  <a tabindex='-1' onClick={() => selectNone()}>
                                    <span>None</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                            {clientList.map((item, index) => (
                              <div style={{ width: "100%" }} key={index}>
                                <div class='filter-by-check-wrap'>
                                  <div class='custom-checkbox-wrap'>
                                    <label>
                                      <span
                                        class={
                                          clientArray.includes(item.id)
                                            ? "custom-checkboxes selected"
                                            : "custom-checkboxes"
                                        }
                                      >
                                        <input
                                          type='checkbox'
                                          name='filter-item[]'
                                          onClick={() =>
                                            handleClientCheck(item.id)
                                          }
                                        />
                                      </span>
                                    </label>
                                  </div>
                                  <div class='team-name-filter-by'>
                                    <div title='webteam'>
                                      {item.client_name}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  projectNameDD
                    ? "report-filter-item-box dropdown"
                    : "report-filter-item-box dropdown"
                }
                onClick={() => setProjectNameDD(true)}
              >
                <div
                  className={projectTerm.length>0?'report-filter-inner-item dropdown-toggle filteritem-active'
                    :"report-filter-inner-item dropdown-toggle"}
                  data-toggle='dropdown'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='18'
                    height='18'
                    viewBox='0 0 18 18'
                  >
                    <g fill='none' fill-rule='evenodd'>
                      <rect width='18' height='18' fill='#cecece' rx='4'></rect>
                      <path
                        fill='#fafbfc'
                        d='M7.524 13l-.348-1.548H5.268L4.92 13H3l2.064-8.496h2.412L9.528 13H7.524zM6.252 6.352h-.048l-.72 3.612h1.452l-.684-3.612zM13.376 10.264h-.336c-.136 0-.268.012-.396.036a.841.841 0 0 0-.336.138.73.73 0 0 0-.228.264.897.897 0 0 0-.084.414c0 .248.054.43.162.546a.514.514 0 0 0 .39.174c.144 0 .27-.036.378-.108a.795.795 0 0 0 .258-.282 1.45 1.45 0 0 0 .144-.396c.032-.148.048-.294.048-.438v-.348zm.06 1.956c-.08.28-.238.506-.474.678-.236.172-.542.258-.918.258-.184 0-.372-.032-.564-.096a1.448 1.448 0 0 1-.528-.318 1.688 1.688 0 0 1-.396-.588c-.104-.244-.156-.546-.156-.906 0-.432.078-.788.234-1.068.156-.28.36-.504.612-.672.252-.168.538-.286.858-.354.32-.068.644-.102.972-.102h.24v-.096c0-.304-.07-.524-.21-.66-.14-.136-.326-.204-.558-.204a1.28 1.28 0 0 0-.63.156c-.188.104-.35.236-.486.396l-.828-1.152c.256-.304.574-.54.954-.708.38-.168.758-.252 1.134-.252.424 0 .784.064 1.08.192.296.128.536.316.72.564.184.248.316.55.396.906.08.356.12.766.12 1.23V13H13.46v-.78h-.024z'
                      ></path>
                    </g>
                  </svg>
                  <span>Project name</span>
                </div>
                <div
                  className={
                    projectNameDD
                      ? "dropdown-menu filter-by-button-dropdown-wrap show"
                      : "dropdown-menu filter-by-button-dropdown-wrap"
                  }
                >
                  <div className='Teamdropdown_container'>
                    <div className='filter-search-field-wrap'>
                      <div className='team-search-contain'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='14'
                          height='14'
                          viewBox='0 0 14 14'
                        >
                          <path
                            fill='#cecece'
                            d='M13.9,12.8L10.2,9c0.7-0.9,1.2-2.2,1.2-3.4C11.3,2.5,8.8,0,5.7,0C2.6,0,0,2.6,0,5.7c0,3.2,2.5,5.7,5.7,5.7 c1.2,0,2.3-0.4,3.4-1.2l3.6,3.6C12.8,14,12.9,14,13,14s0.1,0,0.2-0.1l0.8-0.8C14,13.1,14,12.9,13.9,12.8z M9.8,5.7 c0,2.2-1.8,4-4.1,4s-4.1-1.8-4.1-4c0-2.2,1.9-4.1,4.1-4.1C8,1.7,9.8,3.5,9.8,5.7z'
                          ></path>
                        </svg>
                        <input
                          type='text'
                          tabindex='4'
                          placeholder='Find project'
                          className='team-search-field'
                          onChange={handleProjectTerm}
                          onKeyUp={() => {
                            setReload(reload + 1);
                          }}
                          value={projectTerm}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Suspense fallback={
        <Loader />
      }>
      <ProjectList
        reload={reload}
        mode={status}
        projectTerm={projectTerm}
        clientArray={clientArray}
      />
      </Suspense>
      <ProjectModal 
        show={show}
        handleClose={hideModal}
        reloadList={reloadFunction}
      />
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
