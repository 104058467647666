import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../common/sidebar";
import CheckToken from "../common/check_token";
import Loader from "../common/loader";
import { useSelector } from "react-redux";
import axios from "axios";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import moment from "moment-timezone";
import timeGridPlugin from "@fullcalendar/timegrid";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import { alterTimeFormat, alterDisplayTime } from "../common/displayTime";
import Footer from "../common/footer";

export default function ReportCalendar() {
  const auth = useSelector((state) => state.auth);
  const [events, setEvents] = useState([]);
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const [title, setTitle] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [placement, setPlaceMent] = useState("");
  const [timeDurationFormat, setTimeDurationFormat] = useState("");
  const [timeFormat, setTimeFormat] = useState("");
  const [eventTimeFormat, setEventTimeFormat] = useState("");
  const [dateFormat, setDateFormat] = useState("");
  const [firstDayOfWeek, setFirstday] = useState(0);

  let todayStr = new Date().toISOString().replace(/T.*$/, ""); // YYYY-MM-DD of today
  let eventGuid = 0;
  const calendarRef = useRef();

  useEffect(() => {
    document.addEventListener(
      "click",
      () => {
        setShow(false);
      },
      true
    );
    if (auth.user_properties !== null && auth.user_properties !== undefined) {
      let timeZone = auth.user_properties.timeZone;
      if (timeZone === "" || timeZone === null || timeZone === undefined) {
        moment.tz.setDefault();
      } else {
        timeZone = timeZone.split(" ")[0];
        moment.tz.setDefault(timeZone);
      }
      setTimeDurationFormat(
        auth.user_properties.timeDurationFormat.split(" ")[0]
      );
      setTimeFormat(alterTimeFormat(auth.user_properties.timeFormat));
      let timeformat = auth.user_properties.timeFormat;
      if (timeformat === "12-hour") {
        setEventTimeFormat({
          hour: "numeric",
          minute: "2-digit",
          meridiem: "short",
        });
      } else {
        setEventTimeFormat({
          // like '14:30:00'
          hour: "2-digit",
          minute: "2-digit",
          meridiem: false,
        });
      }
      setDateFormat(auth.user_properties.dateFormat);
      setFirstday(auth.user_properties.firstDayOfWeek);
    } else {
      moment.tz.setDefault();
      setTimeDurationFormat("Improved");
      setEventTimeFormat({
        hour: "numeric",
        minute: "2-digit",
        meridiem: "short",
      });
      setDateFormat("DD-MM-YYYY");
      setFirstday(0);
    }

    getMonthEvents();
    someMethod();
  }, [calendarRef]);

  const getMonthEvents = async () => {};

  const createEventId = () => {
    return String(eventGuid++);
  };

  const someMethod = async () => {
    try {
      let calendarApi = calendarRef.current.getApi();
      let format = "";
      let start = new Date(
        calendarApi.view.currentStart.getTime() -
          calendarApi.view.currentStart.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0];
      let actualMonthEnd = moment(calendarApi.view.currentEnd).subtract(
        1,
        "days"
      );
      let end = new Date(
        actualMonthEnd._d.getTime() -
          calendarApi.view.currentEnd.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0];

      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      };
      let workspace_id = auth.workspace_id;

      const body = JSON.stringify({
        start,
        end,
        workspace_id,
      });
      let res = await axios.post("/api/report/calendar_data", body, config);
      if (res.status === 200) {
        let events = [];
        res.data.results.map((item) => {
          events.push({
            id: item.timer_id,
            title: item.title,
            start: moment.unix(item.timer_start).format(),
            end: moment.unix(item.timer_end).format(),
            backgroundColor: item.color,
          });
        });
        setEvents(events);
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }
  };

  function renderEventContent(eventInfo) {
    return (
      <>
        <b>{eventInfo.timeText} </b>
        <i>{eventInfo.event.title}</i>
      </>
    );
  }

  return (
    <div>
      <CheckToken />
      <Sidebar />
      <div class="header">
        <div class="container-fluid">
          <div class="row" style={{ flexDirection: "column" }}>
            <div class="reports-header-contain">
              <h3>Calendar</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="content-wrap" id="wrapper">
        <div class="main">
          <div
            style={{
              marginLeft: "3%",
              marginRight: "3%",
              height: "90%",
              marginBottom: "3%",
              zIndex: 2,
            }}
          >
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin]}
              initialView="dayGridMonth"
              dayMaxEvents={1}
              events={events}
              eventContent={renderEventContent}
              ref={calendarRef}
              headerToolbar={{
                left: "month week day",
                center: "title",
                right: "prev,next",
              }}
              displayEventTime={true}
              firstDay={firstDayOfWeek}
              eventTimeFormat={eventTimeFormat}
              timeZone={
                auth.user_properties.timeZone === null ||
                auth.user_properties.timeZone === "" ||
                auth.user_properties.timeZone === undefined
                  ? moment.tz.guess()
                  : auth.user_properties.timeZone.split(" ")[0]
              }
              customButtons={{
                prev: {
                  text: "Prev",
                  click: async function () {
                    // so something before

                    // do the original command
                    let calendarApi = calendarRef.current.getApi();
                    await calendarApi.prev();
                    someMethod();
                    // do something after
                  },
                },
                next: {
                  text: "Next",
                  click: async function () {
                    // so something before

                    // do the original command
                    let calendarApi = calendarRef.current.getApi();
                    await calendarApi.next();
                    someMethod();
                    // do something after
                  },
                },
                today: {
                  text: "Today",
                  click: async function () {
                    // so something before

                    // do the original command
                    let calendarApi = calendarRef.current.getApi();
                    await calendarApi.today();
                    someMethod();
                    // do something after
                  },
                },
                month: {
                  text: "month",
                  click: async function () {
                    // so something before

                    // do the original command
                    let calendarApi = calendarRef.current.getApi();
                    await calendarApi.changeView("dayGridMonth");
                    someMethod();
                    // do something after
                  },
                },
                week: {
                  text: "week",
                  click: async function () {
                    // so something before

                    // do the original command
                    let calendarApi = calendarRef.current.getApi();
                    await calendarApi.changeView("timeGridWeek");
                    someMethod();
                    // do something after
                  },
                },
                day: {
                  text: "day",
                  click: async function () {
                    // so something before

                    // do the original command
                    let calendarApi = calendarRef.current.getApi();
                    await calendarApi.changeView("timeGridDay");
                    someMethod();
                    // do something after
                  },
                },
              }}
              eventClick={function (info) {
                if (info.view.type === "timeGridDay") {
                  setShow(true);
                  setTitle(info.event.title);
                  setStart(moment(info.event.startStr).format(timeFormat));
                  setEnd(moment(info.event.endStr).format(timeFormat));
                  setTarget(info.el);
                  setPlaceMent("top");
                } else if (info.view.type === "timeGridWeek") {
                  setShow(true);
                  setTitle(info.event.title);
                  setStart(moment(info.event.startStr).format(timeFormat));
                  setEnd(moment(info.event.endStr).format(timeFormat));
                  setTarget(info.el);
                  setPlaceMent("left");
                }
              }}
            />
            <Overlay
              show={show}
              target={target}
              placement={placement}
              container={ref.current}
              containerPadding={20}
            >
              <Popover id="popover-contained">
                <Popover.Title as="h3">Task Title</Popover.Title>
                <Popover.Content>
                  <strong>{`${start} - ${end}`}</strong> {title}
                </Popover.Content>
              </Popover>
            </Overlay>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
