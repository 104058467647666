import React, { useState, useEffect } from "react";
import "../../assets/css/modal.css";
import { useSelector } from "react-redux";
import ColorPicker from "../common/ColorPicker";
import axios from "axios";
import {Modal} from "react-bootstrap";

function TagModal({ handleClose, show, reloadList, data }) {
  const showHideClassName = show ? "modal display-block" : "modal display-none";
  const auth = useSelector((state) => state.auth);
  const [tag_title, setTag] = useState("");
  const [tag_id, setTagid] = useState("");
  const [errors, setErrors] = useState({});
  const [color, setColor] = useState("#000000");
  useEffect(() => {
    if (data !== null && data !== "" && data !== undefined) {
      setColor(data.tagcolor);
      setTag(data.tag_title);
      setTagid(data.id);
    }
  }, []);
  const tagChange = (e) => {
    setTag(e.target.value);
  };
  const validateForm = () => {
    let errors = {};
    let formIsValid = true;
    if (tag_title === "") {
      formIsValid = false;
      errors["tag_title"] = "Please enter the Tag Title";
    }
    setErrors(errors);
    return formIsValid;
  };
  const handleSubmit = async () => {
    try {
      let workspace_id = auth.workspace_id;
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      };
      const body =
        tag_id !== ""
          ? JSON.stringify({
              tag_id,
              tag_title,
              color,
              workspace_id,
            })
          : JSON.stringify({
              tag_title,
              color,
              workspace_id,
            });
      if (tag_id !== "") {
        if (validateForm()) {
          let res = await axios.put("/api/tag/edit", body, config);
          if (res.status === 200) {
            handleClose();
            setTag("");
            setColor("");
            reloadList();
          }
        }
      } else {
        if (validateForm()) {
          let res = await axios.post("/api/tag/add", body, config);
          if (res.status === 200) {
            handleClose();
            setTag("");
            setColor("");
            reloadList();
          }
        }
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        console.log(err.response);
      }
    }
  };
  const handleColor = (color) => {
    setColor(color);
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName='add-New-tag-popup-box-container'
        bsPrefix=''
      >
        <div class='popup-title'>
              <h3>{tag_id !== "" ? "Update" : "New"} Tag</h3>
            </div>
            <div class='popup-inner-input-field'>
              <div style={{ display: "flex" }}>
                <input
                  type='text'
                  placeholder='Tag Title'
                  name='tag'
                  onChange={tagChange}
                  value={tag_title}
                />
                <ColorPicker onSelectColor={(color) => handleColor(color)} dbColor={data?data.tagcolor:""}/>
              </div>

              <span>{errors.tag_title}</span>
            </div>
            <div class='create-tag-name-btn popup-full-btn'>
              <button class='popup-button' type='submit' onClick={handleSubmit}>
                <span>{tag_id !== "" ? "Update" : "Create"}</span>
              </button>
            </div>
      </Modal>
    </>
  );
}
export default TagModal;
